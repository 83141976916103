// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");
interface ProjectData {
    id: string;
    projectName: string,
    fileCount: string,
    name: string,
}

interface Meta {
    message: string;
}

interface DrawingDataList {
    data: ProjectData[];
    meta: Meta;
}

interface FileMetaData {
    name: string;
    size: string;
}



export interface Props {
    navigation: any;
    id: string;
}

interface S {
    DrawingDataList: DrawingDataList;
    toggle: string;
    visibleCount: number,
    token: string,
    loading: boolean,
    openUploadDialog: boolean,
    searchQuery: string;
    file: File | null; // File object or null
    fileUrl: string | null;
    fileMetadata: FileMetaData | null; // File metadata or null
    errorMessage: string | null;
    fileSizeError: boolean;
    sortTypeValue: string;
    sortType: any;
    tableBody: any;
    fileTypeValue: any;
    fileType: any;
    isDropdownOpen: boolean;
    anchorEl: any;
    selectedItems: any
    isDropdownOpen2: boolean;
    anchorEl2: any;
    selectedItems2: any;
    selectedFileType: string;
    anchorElpop:any;
    selectedFile:any
}

interface SS {
    id: any;
}

export default class FileDashboardController extends BlockComponent<Props, S, SS> {
    apiGetProjectsListCallId: string | null = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            DrawingDataList: {
                data: [],
                meta: {
                    message: ""
                }
            },
            toggle: 'Draft',
            visibleCount: 8,
            token: '',
            loading: false,
            openUploadDialog: false,
            searchQuery: '',
            file: null,
            fileUrl: null,
            fileMetadata: null,
            errorMessage: null,
            fileSizeError: false,
            sortTypeValue: 'Date Edited',
            sortType: ['Date Edited', 'Edited', 'Date'],
            fileTypeValue: 'All',
            fileType: ['All', 'Electrical', 'Heating', 'Plumbing'],
            tableBody: [
                { filename: 'File nane', date: '5th Nov 2024', proposal: true, fileType: 'Electrical' },
                { filename: 'File nane', date: '5th Nov 2024', proposal: false, fileType: 'heating' },
                { filename: 'File nane', date: '5th Nov 2024', proposal: true, fileType: 'Plumbing' },
                { filename: 'File nane', date: '5th Nov 2024', proposal: false, fileType: 'Electrical' },
                { filename: 'File nane', date: '5th Nov 2024', proposal: false, fileType: 'heating' },
                { filename: 'File nane', date: '5th Nov 2024', proposal: true, fileType: 'Plumbing' },
            ],
            isDropdownOpen: false,
            anchorEl: null,
            selectedItems: new Set(["All"]),
            isDropdownOpen2: false,
            anchorEl2: null,
            selectedItems2: new Set(),
            selectedFileType: '',
            anchorElpop:null,
            selectedFile:{}
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.receive = this.receive.bind(this);
    }

    async componentDidMount() {
        const token = await getStorageData("token");
        this.setState({ token });
    }


    handleCloseUploadDialog = () => {
        this.setState({ openUploadDialog: false });

        setTimeout(() => {
            this.setState({ file: null });
        }, 300);
    };
    handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
    };

    handleRemoveUploadedFile = () => {
        this.setState({ file: null })
    }
    handleUploadFile = () => {
        this.setState({ openUploadDialog: false })
    }

    toggleDropdown = (event: any) => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen,
            anchorEl: event.currentTarget,
        });
    };
    toggleDropdown2 = (event: any) => {
        this.setState({
            isDropdownOpen2: !this.state.isDropdownOpen2,
            anchorEl2: event.currentTarget,
        });
    };

    handleClose = (fileType: any) => {
        this.setState({
            isDropdownOpen: false,
            anchorEl: null,
            fileTypeValue: fileType,
        });
    };

    handleCheckboxChange = (option: any) => {
        this.setState((prevState) => {
            const updatedItems = new Set(prevState.selectedItems);
            if (updatedItems.has(option)) {
                updatedItems.delete(option);
            } else {
                updatedItems.add(option);
            }
            return { selectedItems: updatedItems };
        });
    };
    handleCheckboxChange2 = (option: any) => {
        this.setState({ sortTypeValue: option })
    };
    uploadFile = () => {
        this.setState({
            openUploadDialog: true
        })
    }
    handleBack = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "DrawingDashboard");
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.file);
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msg)
    }
     handleMenuOpen = (event: { currentTarget: any; }, item: any) => {
        this.setState({anchorElpop:event.currentTarget});
        this.setState({selectedFile:item});
    };

     handleMenuClose = () => {
        this.setState({anchorElpop:null});
    };

     handleDownload = () => {
        console.log('Downloading:', this.state.selectedFile.filename);
        this.handleMenuClose();
    };

     handleAttachToProposal = () => {
        console.log('Attaching to proposal:', this.state.selectedFile.filename);
        this.handleMenuClose();
    };
    navigateMarkupTool=()=>{
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "MarkupTool");
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.file);
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msg)
    }
}
// Customizable Area End
