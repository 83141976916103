//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Input,
  Select,
  Grid,
  MenuItem,
  styled,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  Collapse,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  Popper,
  PopperProps,
  Checkbox,
  ListItemText,
  Dialog,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  OutlinedInput,
  IconButton,
  Accordion,
  AccordionSummary,
  Popover,
  AccordionDetails,
  Tooltip,
  Select as data
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TodayIcon from '@mui/icons-material/Today';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import 'react-circular-progressbar/dist/styles.css';
import { KeyboardArrowDown } from "@mui/icons-material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "react-calendar/dist/Calendar.css";
import { Formik } from "formik";
import {
  Add,
  ArrowBackIos,
  Check,
  ControlPointDuplicateSharp,
  DeleteOutline,
  ExpandMore,
  Remove,
  Search,
} from "@material-ui/icons";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import Calendar from 'react-calendar';
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";
import {
  unCheckIcon,
  checkedIcon,
  threeDotIcon,
  paidIcon,
  unPaidIcon,
  upComingIcon,
  filterIcon,
  cross,
  dowloadImg
} from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
  DueDate
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInvoiceSummary = () => {
    let invoiceSummary = this.state.invoiceSummary.data
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          borderRadius: "12px",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          margin: "20px 30px",
          padding: "20px 27px",
          alignItems: "center",
          gap: { xs: "20px", md: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: "30px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              fontFamily: "Poppins",
              lineHeight: "26px",
            }}
          >
            Invoice Summary
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ height: "62px", width: "62px" }}>
              <CircularProgressbarWithChildren
                data-testid="progressBar"
                styles={buildStyles({
                  pathColor: "#10B981",
                  trailColor: "#D1FAE5",
                })}
                strokeWidth={"8"}
                value={this.getPaidProgress(invoiceSummary)}
              >
                <img
                  style={{ width: 26, height: 26, marginTop: -5 }}
                  src={paidIcon}
                />
              </CircularProgressbarWithChildren>
            </Box>
            <Box>
              <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
                Paid Invoices:{" "}
                <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                  {invoiceSummary?.paid_invoices_count}
                </span>
              </Typography>
              <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
                Total Amount:{" "}
                <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                  £{Number(invoiceSummary?.total_paid_amount).toLocaleString("en-IN")}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#FCD34D",
                trailColor: "#FEF3C7",
              })}
              strokeWidth={"8"}
              value={this.getUpcomingProgress(invoiceSummary)}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={upComingIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Upcoming Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
              {invoiceSummary?.upcoming_invoices_count}
              </span>
            </Typography>
            <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
              Total Amount:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                £{Number(invoiceSummary?.total_upcoming_amount).toLocaleString("en-IN")}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#EF4444",
                trailColor: "#FEE2E2",
              })}
              strokeWidth={"8"}
              value={this.getUnpaidProgress(invoiceSummary)}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={unPaidIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Unpaid Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                {invoiceSummary?.unpaid_invoices_count}
              </span>
            </Typography>
            <Typography sx={{ ...webStyle.progressText, fontSize: "14px" }}>
              Total Amount:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "16px" }}>
                £{Number(invoiceSummary?.total_unpaid_amount).toLocaleString("en-IN")}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  renderTableUperDiv = () => {
    const overAllProjectInvoice = this.state.invoiceData.invoices.data
  return (
    <Box sx={webStyle.flex}>
      <Box sx={webStyle.searchBox}>
        <TextField
          variant="outlined"
          placeholder="Search here..."
          data-test-id="searchInput"
          value={this.state.searchValue}
          onChange={this.handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ height: "20px", color: "#94A3B8", width: "20px" }}
                />
              </InputAdornment>
            ),
            endAdornment: this.state.searchValue && (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.clearSearch}
                  data-test-id="clear_search_id"
                  disableRipple
                  style={{ padding: 0, cursor: "pointer" }}
                >
                  <ClearIcon
                    style={{
                      color: "#94A3B8",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #CBD5E1",
              width: { xs: "100%", sm: "220px" },
              "& ::placeholder": {
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#94A3B8",
              },
            },
          }}
          style={{
            borderRadius: "8px",
            backgroundColor: "white",
          }}
        />
        <Box
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
            <Button
              testID="bulkButton"
            sx={{
              backgroundColor: "#F1F5F9",
              height: "32px",
              border: "1px solid #CBD5E1",
              borderRadius: "8px",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "#94A3B8",
              textTransform: "none",
            }}
            variant="outlined"
            endIcon={<KeyboardArrowDown />}
            onClick={this.handleMenuOpen}
          >
            Bulk Action
          </Button>
          <Menu
            data-test-id="handleMenuCloseId"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
              },
              "& .MuiMenu-list": {
                padding: "0px 0px",
              },
            }}
          >
            <MenuItem
              testId="openDownloadPopup"
              onClick={() => this.openDownloadModal()}
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 400,
                fontFamily: "Poppins",
                color: "#0F172A",
                width: "144px",
                height: "46px",
                borderRadius: "8px",
              }}
            >
              Download
            </MenuItem>
          </Menu>

          <FilterButton data-test-id="handleButtonScaling" onClick={this.handleOpenFilter}>
            <img width={20} height={20} src={filterIcon} alt="Filter" />
            Filter
            {this.state.filterCount.length > 0 && (
              <span
                data-test-id = "filterCountTest"
                style={{
                  backgroundColor: "#237182",
                  color: "#FFFFFF",
                  borderRadius: "50%",
                  width: "25px",
                  height: "23px",
                  paddingTop: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: 600,
                  marginLeft: "8px",
                }}
              >
                {this.state.filterCount}
              </span>
            )}
          </FilterButton>
        </Box>
        <Typography sx={{ fontWeight: 400, fontSize: "12px", marginLeft: "15px",

         }}>
          *Open the invoice to change the status
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          '@media (max-width: 1127px)': {
            marginTop: "10px"
          },
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
          Total Invoices: {this.state.totalInvoiceCount}
        </Typography>
        <Button
            testID="createInvoiceButton"
          onClick={this.openCreateInvoiceModal}
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#237182",
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 600,
            height: "44px",
            fontSize: "16px",
            lineHeight: "24px",
            borderRadius: "8px",
            padding: "10px 12px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#237182",
            },
          }}
        >
          <span style={webStyle.createIcon}>
            <AddCircleOutlineOutlinedIcon />
          </span>
          Create Invoice
        </Button>
      </Box>
    </Box>
  );
  };
  
  renderTeamSection = (roomsData: { [key: string]: string | [] }) => {
    return (
      <TableCell style={{ ...webStyle.SubDivC }}>
        {Array.isArray(roomsData.rooms) && roomsData?.rooms.length > 0 ? (
          <Tooltip
            title={
              roomsData?.rooms.length > 1
                ? (roomsData.rooms.map(room => room.room_name) as string[]).slice(1).join(", ")
                : ""
            }
            placement="top"
          >
            <span>
              {roomsData?.rooms[0].room_name}
              {roomsData?.rooms.length > 1 && ","}
              {roomsData?.rooms.length > 1 ? (
                <span
                  style={{
                    borderRadius: "50%",
                    padding: "6px 8px",
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "poppins",
                    lineHeight: "16px",
                    backgroundColor: "#237182",
                    color: "#FFFFFF",
                  }}
                >
                  {roomsData?.rooms.length > 1 && `+${roomsData?.rooms.length- 1}`}
                </span>
              ) : (
                <span></span>
              )}
            </span>
          </Tooltip>
        ) : (
          <span>N/A</span>
        )}
      </TableCell>
    );
  };
  
  renderMembersList = () => {
    const overAllProjectInvoice = this.state.filteredInvoices.invoices?.data
    const isChecked = this.state.isClientChecked
    return overAllProjectInvoice.map((invoiceList, index) => (
        <TableRow key={index}>
          <TableCell style={webStyle.SubDivC}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Checkbox
                data-test-id="checkbox-change-data-id"
                icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
                checkedIcon={
                  <img src={checkedIcon} style={{ width: 16, height: 16 }} />
                }
                checked = {isChecked}
                style={{ color: "#64748B" }}
              />
              <Typography style={webStyle.SubDivC}>
                {invoiceList.attributes.client_name}
              </Typography>
            </Box>
          </TableCell>
          <TableCell style={{ ...webStyle.SubDivC }}>{invoiceList.attributes.project_name}</TableCell>
          {this.renderTeamSection(invoiceList.attributes)}
          <TableCell
            style={{
              ...webStyle.SubDivC
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
              }}
            >
              £{Number(invoiceList.attributes.total_amount).toLocaleString("en-IN")}
            </Typography>
          </TableCell>
          <TableCell style={{ ...webStyle.SubDivC }}>
            {this.getFormatedDueDate(invoiceList?.attributes?.due_date)}
          </TableCell>
          <TableCell style={{ ...webStyle.SubDivC, }}>
            <Typography sx={{
              width: "fit-content",
              paddingBlock: "2px",
              paddingInline: "8px",
              fontWeight: 700,
              backgroundColor: invoiceList.attributes.payment_status === "PAID" ? "#D1FAE5" : invoiceList.attributes.payment_status === "UNPAID" ? "#FEE2E2" : "#FEF3C7",
              borderRadius: "20px", display: "flex", alignItems: "center",
              color: invoiceList.attributes.payment_status === "PAID" ? "#059669" : invoiceList.attributes.payment_status === "UNPAID" ? "#DC2626" : "#D97706"
            }}>
              {invoiceList.attributes.payment_status}
            </Typography>
          </TableCell>
          <TableCell
            style={{
              ...webStyle.SubDivC,
            }}
          >
            <IconButton
              data-test-id="downloadIconTest"
              className="hoverEffect"
            >
              <img src={dowloadImg} style={{width : "35px", height: "30px", color : "#0F172A"}}/>
            </IconButton>
          </TableCell>
        </TableRow>
    ));
  };

  renderTable = () => {
    return (
     <Box sx={{marginRight: "30px", paddingRight: "30px" }}>
       <TableContainer style={webStyle.SubTableBox}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Checkbox
                    data-test-id="clickcheckBoxTest"
                    icon={
                      <img
                        src={unCheckIcon} style={{ width: 16, height: 16 }} />
                    }
                    checkedIcon={<img
                      src={checkedIcon}
                      style={{ width: 16, height: 16 }}
                    />}
                    onChange={this.handleCheckboxClick}
                    style={
                      { color: "#64748B" }
                    }
                  />
                  <Typography style={webStyle.TableTitleText}> Client Name</Typography>
                </Box>
              </TableCell>
              <TableCell style={webStyle.TableTitleText}>Project Name</TableCell>
              <TableCell style={webStyle.TableTitleText}>Room</TableCell>
              <TableCell style={webStyle.TableTitleText}>Amount</TableCell>
              <TableCell style={webStyle.TableTitleText}>Due Date</TableCell>
              <TableCell style={webStyle.TableTitleText}>Status</TableCell>
              <TableCell style={webStyle.TableTitleText}>{""}</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {this.state.filterMessage !== "" ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center", height: "667px" }}>
                    <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#000" }}>
                      {this.state.filterMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                this.renderMembersList()
              )}
            </TableBody>
        </Table>
      </TableContainer>
     </Box>
    )
  }

  renderPagination = () => {
    const { rowsPerPage, currentPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    const maxPageButtons = 8;

    const pageNumbers = this.generateMembersPageNumbers(
      currentPage,
      totalPages,
      maxPageButtons
    );

    const handleClickData = (page: any) => {
      if (page !== "...") {
        this.setMembersPage(page);
      }
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footerSecond}>
          <Box sx={{flexGrow:0.9}}></Box>
          <Box style={webStyle.footerThird}>
            <ChevronLeftIcon
              style=
              {
                {
                  ...webStyle.footerIcon,
                  width: "24px",
                  height: "24px",
                  color: isFirstPage ?"#475569": "#64748B"
                }
              }
              onClick={!isFirstPage ? this.handlePreviousMemberPage : undefined}
              data-test-id="previousPage"
            />
            {
              pageNumbers.map((page, index) =>
              (
                <Box key={index}
                  sx={{ marginLeft: "8px", marginRight: "8px" }}>
                  <Typography
                    data-test-id="pageNumber"
                    style={
                      {
                        ...webStyle.footerFont,
                        cursor: page === "..." ?
                          "default" :
                          "pointer",
                        "bold": "normal",
                        fontWeight: page === this.state.currentPage ? 600 : 400,
                        fontSize: page === this.state.currentPage ? "16px" : "12px",
                        color: page === this.state.currentPage ? "#325962" : "#000",
                      }}
                    onClick={() => handleClickData(page)}
                  >
                    {page}
                  </Typography>
                </Box>
              ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isLastPage ? "#64748B": "#475569"
              }}
              onClick={!isLastPage ? this.handleNextMemberPage : undefined}
              data-test-id="nextButton"
            />
          </Box>
          <Typography style={webStyle.footer3Style}>
          {`${(currentPage - 1) * rowsPerPage + 1} -  ${currentPage === totalPages ? totalMembers : currentPage * rowsPerPage}  of ${totalMembers} results`}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDownloadModal = () => {
    return (
      <AddTaskDialog
        data-test-id="downlaodModal"
        onClose={this.closeDownloadModal}
        open={this.state.openDownloadModal}
      >
        <Box sx={{margin: "10px"}}>
          <Box sx={{display: "flex", }}>
            <Typography
              sx={{
                color: "#0F172A",
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                fontFamily: "Poppins",
              }}
            >
              Do you want to download your invoices?
            </Typography>
            <img onClick={this.closeDownloadModal}
                  style={{ width: 11, height: 11, marginTop: "8px", marginLeft: "8px"}}
                  src={cross}
                />
          </Box>
          <Typography
            sx={{
              color: "#1E293B",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Poppins",
              marginTop: "10px"
            }}
          >
            Click 'Download' to save your invoices to your device.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              flexWrap: "wrap",
              marginTop: "30px"
            }}
          >
            <Button
              onClick={this.closeDownloadModal}
              data-test-id="cancelButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#E0EDF0",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                lineHeight: "24px",
                padding: "10px 16px",
                textTransform: "none"
              }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="downloadButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#237182",
                color: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                padding: "10px 16px",
                textTransform: "capitalize",
                textTransform: "none"
              }}
            >
              Download
            </Button>
          </Box>
        </Box>
      </AddTaskDialog>
    );
  };

  renderCreateInvoiceModal = () => {
    return (
      <CreateInvoiceDialog
        testID="createInvoiceModal"
        onClose={this.closeCreateInvoiceModal}
        open={this.state.openCreateInvoiceModal}
      >
        <Grid container spacing={2} >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "24px", fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins' }}>
              Create Invoice
            </span>
            <CloseIcon
              style={{ cursor: "pointer", width: '20px', height: '20px' }}
              onClick={this.closeCreateInvoiceModal}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="Task" style={webStyle.addTaskPopupHeadings}>Invoice Name <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px"}}>*</span></label>
            <TextField
              fullWidth
              id="invoiceName"
              data-test-id={"invoiceName"}
              name="invoiceName"
              placeholder="Invoice 001"
              variant="outlined"
              value={this.state.invoiceNameCI}
              onChange={this.handleInvoiceNameCI}
              error={Boolean(this.state.invoiceError || this.state.invoiceNameLimitError)} 
              helperText={this.state.invoiceError || this.state.invoiceNameLimitError}
              InputProps={{
                sx: {
                  '& ::placeholder': {
                    color: "#A6B0BF",
                    fontSize: "16px",
                    weight: 400,
                    fontFamily: "Poppins",
                    lineHeight: "24px"
                  },
                  input: {
                    color: "#0F172A",
                  },
                },
              }}
              sx={webStyle.textFieldError}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="MeasurementUnit" style={webStyle.addTaskPopupHeadings}>Project Name  <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px"}}>*</span></label>
            <MeasurementUnitField>
              <FormControl fullWidth error={this.state.projectIdError.length > 0}>
                <Select
                  id="measurementUnit"
                  name="measurementUnit"
                  data-test-id={"QtyNew"}
                  placeholder="Select Project"
                  fullWidth
                  displayEmpty
                  value={this.state.projectNameCI}
                  onChange={this.handleProjectNameCI}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#E0EDF0"
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#E0EDF0",
                        }
                      }
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#0F172A",
                      fontSize: "16px",
                      weight: 400,
                      fontFamily: "Poppins",
                      lineHeight: "24px",
                    }
                  }}
                >
                  {this.state.projectNameandID.map((project) => (
                    <MenuItem
                      key={project.id}
                      value={project.attributes.project_name.project_name}
                      onClick={this.handleProjectClick.bind(this,project.id)}
                    >
                      {project.attributes.project_name.project_name}
                    </MenuItem>
                  ))}
                </Select>
                {this.state.projectIdError.length > 0 && (
                  <FormHelperText sx={{ml : "0px"}}>{this.state.projectIdError}</FormHelperText>
                )}
              </FormControl>
            </MeasurementUnitField>

          </Grid>
          <Grid item xs={6}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>Amount (in £) <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px"}}>*</span></label>
            <TextField
              fullWidth
              id="labourCostUnit"
              data-test-id={"labourCostUnitNew"}
              name="labourCostUnit"
              placeholder="Enter Cost"
              variant="outlined"
              value={this.state.amountCI}
              onChange={this.handleAmountCI}
              error={!!this.state.amountError || !!this.state.amountErrorBlank} 
              helperText={this.state.amountError || this.state.amountErrorBlank}
              InputProps={{
                disableUnderline: true,
                sx: {
                  '& ::placeholder': {
                    color: "#A6B0BF",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    lineHeight: "24px"
                  },
                  input: {
                    color: "#0F172A"
                  },
                },
              }}
              sx={webStyle.textFieldError}
            />
          </Grid>

          <Grid item xs={6}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>
              Due Date <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px"}}>*</span>
            </label>
            <Box
              component="div"
              onClick={this.openDueDateCalendar}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                border: `1px solid ${this.state.dueDateErrorCI?.length > 0 ? "red" : "rgb(209 224 242)"}`,
                padding: "14px 14px 14px 8px",
                mt: "4px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <Typography sx={this.state.dueDateCI ? webStyle.dynamicDate : webStyle.displayText}>
                {this.state.dueDateCI
                  ? new Date(this.state.dueDateCI).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })
                  : "DD/MM/YY"}
              </Typography>
              <TodayIcon sx={{ color: "#94A3B8", marginLeft: "8px" }} />
            </Box>

            {this.state.dueDateErrorCI?.length > 0 && (
              <Typography sx={{ color: "rgb(211, 47, 47)", fontSize: "12px", mt: "4px" }}>
                {this.state.dueDateErrorCI}
              </Typography>
            )}

            <Popover
              data-testid="datePopover"
              anchorEl={this.state.anchorDueDate}
              open={Boolean(this.state.anchorDueDate)}
              onClose={this.closeDueDateCalendar}
              disableAutoFocus
              disableRestoreFocus
              disableScrollLock
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                  padding: "16px",
                },
              }}
            >
              <StyledCalendar
                data-test-id="styledduedateCalendar"
                value={this.state.dueDateCI ? new Date(this.state.dueDateCI) : null}
                onChange={(date) => this.handleSelectDueDateCI(date)}
                minDate={new Date()}
              />
            </Popover>
          </Grid>


          <Grid item xs={12}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>
              Payment Status <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px" }}>*</span>
            </label>

            <Box sx={{ ...webStyle.statusContainer }}>
              {["Unpaid", "Upcoming", "Paid"].map((status) => (
                <Typography
                  key={status}
                  sx={{
                    ...webStyle.statusButtons,
                    color: this.state.statusCI === status ? "#0F172A" : "#64748B",
                    backgroundColor: this.state.statusCI === status ? "#D3E4E9" : "transparent",
                    padding: "8px 60px",
                    borderRadius: "60px",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                  onClick={this.handleStatusChange.bind(this, status)}
                >
                  {status}
                </Typography>
              ))}
            </Box>

            {this.state.statusError.length > 0 && (
              <FormHelperText sx={{ color: "rgb(211, 47, 47)", fontSize: "12px" }}>{this.state.statusError}</FormHelperText>
            )}
          </Grid>

        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            flexWrap: "wrap",
            marginTop: "30px"
          }}
        >
          <Button
            onClick={this.closeCreateInvoiceModal}
            data-test-id="cancelButton"
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#E0EDF0",
              color: "#325962",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              lineHeight: "24px",
              padding: "10px 16px",
              textTransform: "capitalize",
              boxShadow : "none",
              "&:hover": {
                color: "#325962",
                backgroundColor: "#E0EDF0", 
              },
            }}
          >
            Cancel
          </Button>
          <Button
            data-test-id="downloadButton"
            variant="contained"
            color="primary"
            onClick={this.handleCreateInvoice}
            sx={{
              backgroundColor : this.state.createButtonHighlight ? "#237182" : "#F1F5F9",
              color: this.state.createButtonHighlight ? "#FFFFFF" : "#64748B",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              padding: "10px 16px",
              textTransform: "capitalize",
              boxShadow : "none",
              "&:hover": {
                backgroundColor: this.state.createButtonHighlight ? "#237182" : "#F1F5F9",
                color: this.state.createButtonHighlight ? "#FFFFFF" : "#64748B",
              },
            }}
          >
            Create
          </Button>
        </Box>
      </CreateInvoiceDialog>
    );
  };

  renderFilters = () => {
    const overAllProjectInvoice = this.state.invoiceData.invoices.data
    return (
      <FilterDialog className="setDynamic" onClose={this.handleCloseFilters} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen} >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "14px", height: "100%"}}>
          <Box >
            <Box className="mainFilter">
              <Box>
                <Typography className="filterText">Filters</Typography>
              </Box>
              <Box className="innerFilter">
                <Box onClick={this.handleCloseFilters}>
                  <img width={11} height={11} src={cross} style={{ cursor: "pointer" }} alt="cancel_icon" />
                </Box>
              </Box>
            </Box>
            <AccordionBox className="setAccroedion" >
              <Accordion key={1} style={{ height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#334155" }} />}
                  style={{ minHeight: '40px', padding: '0' }}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      margin: "2px !important",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: "2px !important"
                    },
                  }}
                >
                  <Typography className="accText">By Client Name</Typography>
                </AccordionSummary>
                <AccordionDetails 
                data-test-id = "byClientNameSTyleTest"
                sx={{
                  padding: "0px !important",
                  backgroundColor: "#FFFFFF !important",
                  "&.MuiAccordionDetails-root": {
                    padding: "0px 23px !important",
                  },
                  "& .MuiCollapse-wrapperInner": {
                    padding: "0px 23px !important"
                  }
                }}>
                  <Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="clientSerach"
                      sx={webStyle.searchInputStyle}
                      onChange={(event) => this.handleClientSearch(event.target.value)}
                      InputProps={{ startAdornment: (<InputAdornment position="start"> <SearchIcon style={{ height: "20px", color: "#94A3B8", width: "20px" }} /></InputAdornment>) }}
                      placeholder="Search or Select Client..."
                    />
                  </Box>
                  <Box sx={webStyle.filterDataContainer}>
                    {this.state.filteredClientName.length > 0 ? (
                      this.state.filteredClientName.map((client, index) => {
                        const isChecked = this.state.selectedClientName.includes(client);

                        return (
                          <Box key={index} sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px"
                          }}>
                            <StyledCheckbox
                              type="checkbox"
                              checked={isChecked}
                            date-test-id="clientFilterTest"
                            onChange={this.handleFilterClientName.bind(this, client)}
                            />
                            <Typography sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontFamily: "Poppins",
                              color: "#0F172A"
                            }}>
                            {client}</Typography>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography sx={{ textAlign: "center", color: "#000", fontSize: "16px", marginTop: "20px" }}>
                        No client found
                      </Typography>
                    )}

                  </Box>  </AccordionDetails>
              </Accordion>
              <Accordion key={2} style={{ height: 'auto', padding: '0' }} className="accordianBorder">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#334155" }} />}
                  style={{ minHeight: '40px', padding: '0' }}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      margin: "2px !important",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: "2px !important"
                    },
                  }}
                >
                  <Typography className="accText">By Due Date</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                  padding: "0px !important",
                  backgroundColor: "#FFFFFF !important",
                  "&.MuiAccordionDetails-root": {
                    padding: "0px 23px !important",
                  },
                  "& .MuiCollapse-wrapperInner": {
                    padding: "0px 23px !important",
                  }
                }}>
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "8px 0px" }}>
                      <Typography sx={webStyle.rangeStaticText}>From:</Typography>
                      <Box
                        data-test-id="openFromDatePopUp"
                        component="div"
                        onClick={this.openFromDatePopUp}
                        sx={webStyle.dueDateContainer}>
                        <TodayIcon sx={{ color: "#94A3B8" }} />
                        {this.state.fromDate ?
                          <Typography sx={webStyle.dynamicDate}>{new Date(this.state.fromDate).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit'
                          })}
                          </Typography> :
                          <Typography sx={webStyle.displayText}>
                            DD/MM/YY
                          </Typography>
                        }
                      </Box>
                      <Popover
                        data-testid="datePopover"
                        anchorEl={this.state.fromDateAnchorEl}
                        open={Boolean(this.state.fromDateAnchorEl)}
                        onClose={this.closeFromDatePicker}
                        disableAutoFocus
                        disableRestoreFocus
                        disableScrollLock
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: "8px",
                            padding: "16px"
                          }
                        }}
                      >
                        <StyledCalendar data-test-id="styledFromCalender" value={this.state.fromDate} maxDate={this.state.toDate}  onChange={(date) => this.selectFromDate(date as Date)} />
                      </Popover>
                      <Typography sx={{ ...webStyle.rangeStaticText, marginLeft: "16px" }}>To:</Typography>
                      <Box data-test-id="openDatePopUp" onClick={this.openToDatePopUp} sx={webStyle.dueDateContainer}>
                        <TodayIcon sx={{ color: "#94A3B8" }} />
                        {this.state.toDate ?
                          <Typography sx={webStyle.dynamicDate}>{new Date(this.state.toDate).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit'
                          })}
                          </Typography> :
                          <Typography sx={webStyle.displayText}>
                            DD/MM/YY
                          </Typography>
                        }
                      </Box>
                      <Popover
                        data-testid="datePopover"
                        anchorEl={this.state.toDateAnchorEl}
                        open={Boolean(this.state.toDateAnchorEl)}
                        onClose={this.closeToDatePicker}
                        disableAutoFocus
                        disableRestoreFocus
                        disableScrollLock
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: "8px",
                            padding: "16px"
                          }
                        }}
                      >
                        <StyledCalendar
                          data-test-id="selectToDate"
                          value={this.state.toDate}
                          onChange={this.selectToDate}
                          minDate={this.state.fromDate}
                        />
                      </Popover>
                      {this.state.toDate && this.state.fromDate &&
                        <Typography
                          data-test-id="clearDate"
                          onClick={this.clearDateRange}
                          sx={{ color: "#237182", fontFamily: "Poppins", fontSize: "12px", fontWeight: 600, lineHeight: "16px", marginLeft: "16px", cursor: "pointer" }}>Clear Date Range</Typography>}
                    </Box>
                      {this.state.dueDateFilterError.length > 0 && 
                        <Typography sx={{color: "#EF4444", fontWeight: 600,  fontSize: "12px", fontFamily: "Poppins", lineHeight: "16px"}}>{this.state.dueDateFilterError}</Typography>
                      }

                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion key={3} style={{ height: 'auto', padding: '0' }} className="accordianBorder">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#334155" }} />}
                  style={{ minHeight: '40px', padding: '0' }}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      margin: "2px !important",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: "2px !important"
                    },
                  }}
                >
                  <Typography className="accText">By Project Name</Typography>
                </AccordionSummary>
                <AccordionDetails 
                data-test-id = "byProjectNameSTyleTest"
                sx={{
                  padding: "0px !important",
                  backgroundColor: "#FFFFFF !important",
                  "&.MuiAccordionDetails-root": {
                    padding: "0px 23px !important",
                  },
                  "& .MuiCollapse-wrapperInner": {
                    padding: "0px 23px !important",
                  }
                }}>
                  <Box>
                    <TextField
                      data-test-id="projectSearch"
                      fullWidth
                      sx={webStyle.searchInputStyle}
                      variant="outlined"
                      placeholder="Search or Select Project..."
                      onChange={(event) => this.handleProjectSearch(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ height: "20px", color: "#94A3B8", width: "20px" }} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  <Box sx={webStyle.filterDataContainer}>
                    {this.state.filteredProjectName.length > 0 ?
                      (this.state.filteredProjectName).map((projectName, index) => {
                        const isChecked = this.state.selectedProjectName.includes(projectName);

                        return (
                          <Box key={index} sx={{ display: "flex", alignItems: "center", padding: "5px 0px" }}>
                            <StyledCheckbox
                              type="checkbox"
                              checked={isChecked}
                              data-test-id="projectNameFilterTest"
                              onChange={() => this.handleFilterProjectName(projectName)}
                            />
                            <Typography sx={{
                              fontSize: "14px", fontWeight: 400, lineHeight: "22px",
                              fontFamily: "Poppins", color: "#0F172A"
                            }}>
                              {projectName}
                            </Typography>
                          </Box>
                        );
                      })
                      :
                      (
                        <Typography sx={{ textAlign: "center", color: "#000", fontSize: "16px", marginTop: "20px" }}>
                          No project found
                        </Typography>
                      )
                    }
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  padding: '0',
                  height: 'auto'
                }}
                key={4}
                className="accordianBorder">
                <AccordionSummary
                  style={{
                    padding: '0',
                    minHeight: '40px'
                  }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "#334155" }} />}
                  sx={{
                    "& .MuiAccordionSummary-content.Mui-expanded": { margin: "2px !important" },
                    "& .MuiAccordionSummary-content": { margin: "2px !important" },
                  }}>
                  <Typography className="accText">By Status</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: "#FFFFFF !important",
                  padding: "0px !important",
                  "& .MuiCollapse-wrapperInner": { padding: "0px 22px !important" },
                  "&.MuiAccordionDetails-root": {
                    padding: "0px 22px !important",
                  }
                }}>
                  <Box sx={{ display: "flex", alignItems: "center", height: "40px" }}>
                    <StyledCheckbox
                      type="checkbox"
                      onChange={this.handleStatusFilter.bind(this, 'ALL')}
                    />
                    <Typography style={{ ...webStyle.filterStatusText, fontWeight: 400}}>
                      ALL
                    </Typography>
                  </Box>
                  {["PAID", "UPCOMING", "UNPAID"].map((status) => {
                    const isChecked = this.state.selectedStatus.includes(status);
                    return (
                      <Box sx={{ display: "flex", alignItems: "center", height: "40px" }}>
                        <StyledCheckbox
                          type="checkbox"
                          checked={isChecked}
                          onChange={this.handleStatusFilter.bind(this, status)}
                        />
                        <Typography style={{ ...webStyle.filterStatusText, fontWeight: status === 'ALL' ? 400 : 700, color: this.checkStatusColor(status), backgroundColor: this.checkStatusBgColor(status) }}>
                          {status}
                        </Typography>
                      </Box>)
                  })}
                </AccordionDetails>
              </Accordion>
            </AccordionBox>
          </Box>
          <Box className="setApplyBtn">
            <button className="applyBtn" data-test-id="applyButtonTest" onClick={this.handleApplyButton}>Apply</button>
            <button className="clearButton" data-test-id = "clearAllbuttonTest" onClick={this.handleClearAllFilters}>Clear All</button>
          </Box>
        </Box>
      </FilterDialog>
    )
  }

  renderNoSearchMessage = () => {
    return (
      <Box sx={{height: "400px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Typography sx={{fontWeight: 600, fontSize: "18px",color: "#000"}}>
          No invoice found
        </Typography>
      </Box>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Box style={{ background: "#f0f6f7", height: "100%" }}>
        <MyProfileHeader
          navigation={this.props.navigation}
          tabName="Users&Teams"
        >
          {this.renderCreateInvoiceModal()}
          {this.renderDownloadModal()}
          {this.renderFilters()}
          {this.renderInvoiceSummary()}
          {this.renderTableUperDiv()}
          {this.state.filteredInvoices.invoices.data
            .length === 0 ?
            this.renderNoSearchMessage() :
            this.renderTable()
          }
          {this.renderPagination()}
        </MyProfileHeader>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  textFieldError: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0
    }
  },
  dueDateContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    alignItems: "center",
    marginLeft: "8px",
    padding: "4px 8px"
  },

  displayText: {
    color: "#94A3B8",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    marginLeft: "8px"
  },

  filterDataContainer: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "200px",
    width: "100%",
    paddingRight: "10px"
  },

  searchInputStyle: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "white",
    height: "32px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "32px",
      minHeight: "32px",
      "& fieldset": {
        borderColor: "#CBD5E1",
      },
      "& input": {
        padding: "5px 10px",
        height: "32px",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
      },
    },
    "& ::placeholder": {
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#A6B0BF"
    },
    "& .MuiOutlinedInput-input" : {
      lineHeight: "20px",
      fontWeight: 400,
      color: "rgb(15, 23, 42)",
      fontFamily: "Poppins",
      fontSize: "12px",
    }
  },

  dynamicDate: {
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px"
  },

  rangeStaticText: {
    color: "#475569",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "22px"
  },

  footerFont: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3Style: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: "20px 40px",
    boxShadow: 1,
  },
  footerSecond: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 450px)': {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
      flexDirection: "column"
    },
  },
  footerThird: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-start",
    color: "#94A3B8",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  progressNumber: {
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: "26px"
  },

  progressText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",

  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    marginTop: "32px",
    marginBottom: "20px",
    backgroundColor: "#f0f5f7",
    flexWrap: "wrap",
    '@media (max-width: 1127px)': {
      justifyContent: "center"
    },
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "12px",
    '@media (max-width: 804px)': {
      justifyContent: "center",
      width: "100%"
    },
  },

  SubDivC: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
  },

  filterStatusText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Poppins",
    padding: "3px 6px",
    height: "20px",
    borderRadius: "30px",
    MarginTop: "20px"
  },
  totalExpense: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 0,
    alignContent: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#0F172A'
  },
  createIcon: {
    marginRight: "10px",
    width: "24px",
    height: "24px"
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px"
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
    margin: "10px 30px"
  },
   
  addTaskPopupHeadings: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#475569',
  },

  statusButtons: {
    fontFamily: "poppins", fontWeight: 600, fontSize: "16px", lineHeight: "24px", cursor: "pointer"
  },

  statusContainer : {
     display: "flex", justifyContent: "space-around" , mt : "4px", borderRadius : "50px", py : "10px", backgroundColor: "#F8FAFC"
  }
};

const StyledCalendar = styled(Calendar)({
  "&.react-calendar": {
    border: "none"
  },
  "& .react-calendar__navigation": {
    alignItems: "center",
    marginBottom: "0px"
  },
  "& .react-calendar__navigation__prev2-button": {
    display: "none"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none !important"
  },
  "& .react-calendar__navigation__label": {
    display: "flex",
    flex: "unset !important",
    justifyContent: "space-between",
    margin: "auto",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#0F172A"
  },
  "& .react-calendar__navigation__label__divider": {
    display: "none"
  },
  "& .react-calendar__viewContainer": {
    margin: "0px"
  },
  "& .react-calendar__month-view__weekdays": {
    textTransform: "none",
    color: "#64748B"
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: "none"
  },
  "& .react-calendar__tile--active:enabled:hover": {
    background: "#E0EDF0",
  },
  "& .react-calendar__tile--now": {
    background: "transparent"
  },
  "& .react-calendar__tile--active": {
    background: "#E0EDF0",
    borderRadius: "50%",
    border: "5px solid #fff"
  },
  "& .react-calendar__tile": {
    color: "#0F172A"
  },
  "& .custom-calendar .disabled-date": {
    color: "gray", 
    pointerEvents: "none",
    opacity: "0.5"
  },
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .react-calendar__navigation__prev-button": {
    fontSize: '24px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  },
  "& .react-calendar__navigation__next-button": {
    fontSize: '24px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  }


});

 const StyledCheckbox = styled('input')({
   width: "20px",
   height: "20px",
   borderRadius: "6px",
   border: "1px solid #64748B",
   cursor: "pointer",
   position: "relative",
   appearance: "none",
   outline: "none",
   backgroundColor: "#fff",
   marginRight: "10px",
   marginLeft: "0px",
   "&:checked": {
    backgroundColor: "#325962",
    border: "none"
  },
  "&:checked::after": {
    content: '""',
    position: "absolute",
    top: "2px",
    left: "7px",
    width: "5px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)"
  }
});

const AddTaskDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '35px 24px',
    boxShadow: 'none'
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  }
});
const CreateInvoiceDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    backgroundColor:"#fff",
    padding: '24px',
    boxShadow: 'none'
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginTop: '4px',
    color: '#94A3B8',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  },
  "& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: '8px'
  },
  "& .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    borderRadius: '8px'
  }

});

const FilterButton = styled(Button)({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  color: "#0F172A",
  fontWeight: 400,
  fontSize: "16px",
  height: "32px",
  letterSpacing: 0,
  lineHeight: "22px",
  textTransform: "none",
  padding: "10px 10px 10px 10px",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Poppins",
  cursor: "pointer",
});

const MeasurementUnitField = styled(Box)({
  '& .MuiOutlinedInput-root': {
    paddingRight: '10px',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }
});

const CalendarStyle = styled(Menu)({
  "& .react-calendar": {
    border: "none",
    fontFamily: "Poppins",
    width: "348px",
    boxShadow: 'none'
  },
  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
    width: '348px',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: 'none'
  }
  ,
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    width: '24px',
    height: '24px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0F172A',
    fontSize: '14px',
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: '12px'
  },
  "& .react-calendar__navigation": {
    display: 'flex',
    gap: '10px'
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '30px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    fontSize: "14px",
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    fontFamily: "Poppins",
    textDecoration: 'none',
    backgroundColor: 'transparent'
  },
  '& .react-calendar__month-view__weekdays': {
    padding: '8px'
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    fontFamily: "Poppins",
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: 'none'
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#E0EDF0',
    color: '#325962',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'

  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '0 100% 100% 0'
  },
  "& .react-calendar__tile--active.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
  },
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .custom-calendar": {
    "& .react-calendar__tile--disabled": {
      backgroundColor: "#F1F5F9",
      color: "#94A3B8",
      pointerEvents: "none",
      cursor: "not-allowed"
    },
    "& .react-calendar__tile--disabled:hover": {
      backgroundColor: "#F1F5F9"
    },
  },
  "& .custom-calendar .disabled-date": {
    color: "gray",
    pointerEvents: "none",
    opacity: "0.5"
  }
});

const FilterDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .applyBtn': {
    fontWeight: 600,
    fontSize: "16px",
    color: "#fff",
    background: '#237182',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 20px',
    lineHeight: "24px",
    cursor : "pointer",
  },
  "& .clearButton": {
    color: "#237182",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Poppins",
    background: '#fff',
    border: 'none',
    cursor : "pointer",
    lineHeight: "26px"
  },
  '& .setApplyBtn': {
    padding: '20px',
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  '& .innerFilter': {
    gap: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  '& .mainFilter': {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    display: 'flex',
  },
  '& .filterText': {
    fontFamily: 'poppins',
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#0F172A"
  },
  '& .clearText': {
    fontFamily: 'poppins',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#527FE2',
    fontWeight: 400,
  },
  '& .MuiDialog-container': {
    right: '0',
    position: 'absolute',
    '& .MuiPaper-root': {
      margin: '0',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      width: "576px",
      '@media (max-width: 570px)': {
        width: '350px',
      },
      '@media (max-width: 370px)': {
        width: '300px',
      },
    },
  },
  '& .MuiAccordion-root': {
    boxShadow: 'none',
    border: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: 'none',
  },
  '& .MuiAccordionDetails-root': {
    padding: '8px 16px', 
  },
});
const AccordionBox = styled(Box)({
  width: '100%',
  "& .accordianBorder": {
    "&.MuiAccordion-root::before": {
      backgroundColor: "transparent"
    }
  },
  '& .accText': {
    fontWeight: 600,
    color: "#0F172A",
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle : "poppins"
  },
  '& .MuiPaper-root': {
    '& .MuiButtonBase-root': {
      padding: '0px 20px !important',
      '& .MuiIconButton-label': {
        color: 'black'
      }
    },
    '& .MuiCollapse-root': {
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper': {
        '& .MuiCollapse-wrapperInner': {
          '& .MuiAccordionDetails-root': {
            padding: '0px 28px',
            background: '#F8FAFC',
            '& .MuiFormControl-root': {
              marginTop: '0px',
              marginBottom: '0px'
            }
          }
        }
      }
    }
  }
});
// Customizable Area End
