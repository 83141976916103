import React from 'react';
import Select from 'react-select';
import SearchIcon from "@material-ui/icons/Search";
  import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText ,styled} from "@mui/material";
import AddIcon from "@material-ui/icons/Add";

import {
  Box,
  Typography,
} from "@material-ui/core";
import AddMembersModalController  from './AddMembersModalController';




class CustomSelect extends AddMembersModalController{

  CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef} {...innerProps}
        style={{ padding: '0 10px', borderRadius: '4px', backgroundColor: '#FFFFFF', color: '#333',
          cursor: 'pointer',display: 'flex',
          alignItems: 'center', transition: 'background-color 0.3s ease', border: "none",
        }}
      >
        <Box style={{ overflowY: "auto", width: "100%" }}>
          <List style={{padding:"0px"}}> 
            <ListItem
              style={{ paddingLeft: "0px",paddingRight: "0px", paddingBottom: "0px", }}
            >
              <ListItemAvatar>
                <Avatar style={{ background: "#D3E4E9", color: "#ABCDD5" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: 700,
                      paddingTop: "4px",
                    }}
                  >
                    {data.label.slice(0, 2).toUpperCase()}
                  </span>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                   
                    <Typography variant="subtitle1" style={{fontWeight:600 , fontSize:"16px" , fontFamily:"poppins" ,color:"#000", lineHeight:"24px"}}>
                    {data.label}

                  </Typography>
                    <Typography variant="subtitle1" style={{fontWeight:400 , fontSize:"14px" , fontFamily:"poppins",color:"#64748B" , lineHeight:"22px"}}>
                    {data.email} 

                 
                  </Typography>
                  </Typography>
               
                }
              />
              <IconButton
                style={{ color: "#000", opacity: 1 }}
              >
                <AddIcon />
              </IconButton>
            </ListItem>
          </List>
        </Box>
      </div>
    );
  };

  CustomMenuList = (props: any) => {
    return (
      <CustomScroll>
        {props.children}
      </CustomScroll>
    );
  };






  render() {
    const { options  } = this.state;
    console.log(this.props.memberError)
    return (
      <>
      <Box
        mt="1.5rem"
        style={{  position: "relative", display: "flex",flexDirection: "column",  alignItems: "stretch",}}
      >
        <style>
          {`
            .basic-multi-select { width: 100%; position: unset;
            }
          `}
        </style>
        <CustomWrapper>
        <Box style={{ position: "relative" }}>
          <SearchIcon
            style={{
              position: "absolute",left: "1rem",
              top: "28px",transform: "translateY(-50%)",
              color: "grey", zIndex: 100,
            }}
          /> 
          <Select
            options={options}  isMulti
            styles={{
              control: (provided, state) => {
                const borderColor = this.props.memberError ? "1px solid #DC2626" : "1px solid #ccc";
                return {
                  ...provided,
                  display: "flex",
                  flexWrap: "wrap",
                  padding: state.hasValue ? "0.5rem" : "0.9rem",
                  border: borderColor,
                  borderRadius: "8px",
                  paddingLeft: "2.5rem",
                  boxShadow: "none",
                  fontFamily: "Poppins",
                  width:"85%",
                  "&:hover": {
                    border:borderColor ,
                  },
                };
              },
              menu: (provided) => ({
                ...provided,
                boxShadow: "none",
                overflow: "auto",
                borderRadius: "8px",
                position: "relative",
                maxHeight: "395px",
              }),
              multiValue: (provided) => ({
                ...provided,
                display: "flex", alignItems: "center",
                marginRight: "5px",
                background: "#E0EDF0",
                padding: "6px 12px", fontSize: "14px",
                borderRadius: "26px",
                fontWeight: 400, color: "#325962",
                fontFamily: "Poppins",
                marginLeft: "10px",
              }),
              singleValue: (provided) => ({
                ...provided, fontSize: "14px",
                color: "#325962",fontWeight: 400,
                fontFamily: "Poppins",
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                fontSize: "14px", fontFamily: "Poppins",
                color: "#325962", fontWeight: 400,
              }),
              option: (provided) => ({
                ...provided,
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#325962",
                fontWeight: 400,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                width: "auto",
                padding: "0 6px",
                fontSize: "16px",
                flexGrow: 1,
                color: "#0F172A",
                fontFamily: "Poppins",
                fontWeight: 400,
                lineHeight: "24px",
              }),
              placeholder: (provided) => ({
                ...provided,
                paddingLeft: "6px",
                fontFamily: "Poppins",
              }),
              noOptionsMessage: (provided) => ({
                ...provided,
                fontSize: "16px",fontWeight: 400,textAlign: "center",
                fontFamily: "Poppins",
              }),
            }}
            menuIsOpen
            getOptionLabel={(e) => e.label}
            classNamePrefix="select"
            className="basic-multi-select"
            filterOption={this.filterOption}
            onChange={this.handleChange}
            noOptionsMessage={this.noOptionsMessage}
            onInputChange={this.onInputChange}
            components={{
              MenuList: this.CustomMenuList,
              Option: this.CustomOption,
              DropdownIndicator: () => null,
            }}
            data-test-id="select-id"
            placeholder="Search and add members"
          />
        </Box>
        {this.props.memberError && (
          <Box
            mt="0.5rem"
            style={{
              fontFamily: "Poppins",lineHeight: "1rem",
              fontSize: "0.75rem",
              color: "#DC2626",
              position:'absolute', top:"3.5rem",
              zIndex:10
            }}
            data-test-id="error-message"
          >
            {this.props.memberError}
          </Box>
        )}
        </CustomWrapper>
      </Box>
    </>
    
    
    
    
    );
  }
}
const CustomWrapper = styled(Box)({
" & .css-xn6sei-menu":{
  maxHeight: "unset"}
});
const CustomScroll = styled(Box)({
  marginTop:"0.6rem",
          backgroundColor: 'white',
          padding: '0px',  maxHeight: "386px",
          boxShadow: 'none',
          scrollbarWidth: "auto",  overflowY: "scroll", 
    overflowX: "hidden", 
          scrollbarColor:" #CBD5E1 #F1F5F9", 
           "&::-webkit-scrollbar": {
            width: "10px", },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            backgroundColor: "#CBD5E1", },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#F1F5F9", },
  });

export default CustomSelect;
