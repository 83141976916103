import moment from "moment";
import React, { Component } from "react";
import { Calendar as BigCalendar, EventProps, momentLocalizer } from "react-big-calendar";  
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Style.css";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";


const localizer = momentLocalizer(moment)

interface CalendarState {
  events: any[];
  title: string;
  start: string | Date;
  end: string | Date;
  desc: string;
  openSlot: boolean;
  openEvent: boolean;
  clickedEvent: any;
  monthName: { id: number; name: string }[]; 
}
interface CalendarProps {
  activeTabs: any;
  eventValues:any;
  nextWeek:any;
  dateValue:any;
  prevWeek:any;
}

 
class Calendar extends Component<{activeTabs:any,eventValues:any,nextWeek:any,dateValue:any;prevWeek:any}, CalendarState,CalendarProps> {
  constructor(props: CalendarProps) {
    super(props);
  }

  getDaysInMonth(year:any, month:any) {
    return new Date(year, month + 1, 0).getDate();
  }
  generateMonthData(date: Date) {
    if (!date) return; 
  
    const daysInMonth = moment(date).daysInMonth(); 
    const monthData = [];
  
    for (let i = 1; i <= daysInMonth; i++) {
      const dayName = moment(date).date(i).format("dd").charAt(0); 
      monthData.push({ id: i, name: dayName });
    }
  
    this.setState({ monthName: monthData });
  }
  
  componentDidMount() {
    this.generateMonthData(this.props.dateValue);
  }
  
  componentDidUpdate(prevProps: CalendarProps) {
    if (!moment(prevProps.dateValue).isSame(this.props.dateValue, "month")) {
      this.generateMonthData(this.props.dateValue);
    }
  }
    
  
  RenderCustomToolbar=({ dateValue,activeTabs }:{dateValue: any,activeTabs:any })=>{
    const currentWeek = moment(dateValue).week(); 
    const currentMonth = moment(dateValue).format('MMMM');
    const currentYear = moment(dateValue).year();
    const day = dateValue.getDate(); 
    const month = dateValue.getMonth() + 1; 
    const NextWeek = moment(dateValue).add(1,'w').toDate()
    const day2 = NextWeek.getDate();
    const month2 = NextWeek.getMonth()+1; 
    const year2 = NextWeek.getFullYear()

    const days = this.getDaysInMonth(currentYear,dateValue.getMonth())

  const startOfWeek = moment(dateValue).startOf("week");
  const endOfWeek = moment(dateValue).endOf("week");

  const startOfMonth = moment(dateValue).startOf("month");
  const endOfMonth = moment(dateValue).endOf("month"); 
  const lastDateOfMonth = moment(dateValue).endOf("month").format("DD/MM/YYYY");

  return (
    <div className='rbc-toolbar'>
      { activeTabs === 0 &&
        <div className="rbc-btn-group">
        <div style={{display:'flex',alignItems:'center'}}>
          <span onClick={()=>this.props.prevWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowBackIos style={{width:'18px',height:'18px'}}/> </span>
          <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>Week {" "}{" "}{currentWeek}</span> 
          <span onClick={()=>this.props.nextWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
        </div>
        <div style={webStyle.periodText}>
          <span>Period :</span>
          <span> {startOfWeek.format("DD/MM/YYYY")} - </span>
          <span> {endOfWeek.format('DD/MM/YYYY')}</span>
        </div>  
      </div>
      }
       { activeTabs === 1 &&
       <>
        <div className="rbc-btn-group">
        <div style={{display:'flex',alignItems:'center'}}>
          <span onClick={()=>this.props.prevWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
          <span style={{fontWeight:600,fontSize:"16px",fontFamily:"Poppins",color:"#325962",marginLeft:'8px',marginRight:'8px'}}>{currentMonth} {" "}{" "}{" "}{currentYear}</span> 
          <span onClick={()=>this.props.nextWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
        </div>
        <div style={webStyle.periodText}>
          <span>Period :</span>
          <span> {startOfMonth.format("DD/MM/YYYY")} - </span>
          <span> {endOfMonth.format("DD/MM/YYYY")}</span>
        </div>  
      </div>
      {this.state?.monthName?.length > 0 ? (
    this.state.monthName.map((val: any) => (
      <div  key={val?.id} style={{display: 'flex',
        justifyContent: 'center',
        flexDirection:'column',
        alignItems: 'center',
       
        marginTop:'20px',
        }}>
                  <span style={webStyle.monthText}>{val.name}</span>
                  <span style={webStyle.dateText}>{val.id}</span>
                </div>
    ))
  ) : (
    <span>Loading...</span>
  )}
      </>
      }
    </div>
  );
}



renderEvent(eventValues: any) {
  const { event } = eventValues;

  const startDate = moment(event.start);
  const endDate = moment(event.end);
  const startDay = startDate.date(); 
  const endDay = endDate.date(); 

  const eventDuration = endDate.diff(startDate, 'days') + 1;
  const eventWidth = `${eventDuration * 100}%`;

  return (
    <div
      style={{
        backgroundColor: 'white',
        border: "1px solid black",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "absolute",
        left: `${(startDay - 1) * 100}%`, 
        width: eventWidth,
      }}
    >
      <span style={{ color: "black", fontSize: "14px", fontWeight: 600 }}>{event.title}</span>
      <span style={{ fontSize: '12px', color: '#334155', fontWeight: 400, fontFamily: "Poppins" }}>
        {`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`}
      </span>
    </div>
  );
}

CustomWeekHeader = ({ date }: { date: Date }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Poppins', marginTop:'-4px' }}>
      <span style={{ fontSize: '14px', fontWeight: 600, color: '#325962' }}>
        {moment(date).format('dd')[0]}
      </span>
      <span style={{ fontSize: '12px', fontWeight: 400, color: '#64748B' }}>
        {moment(date).format('D')}
      </span>
    </div>
  );
};

 
 render() {
  const {activeTabs,eventValues,dateValue} = this.props;

    return (
      <div id="Calendar">
        {
          activeTabs === 0 &&
          <BigCalendar
          localizer={localizer} 
          views={["week"]}
          timeslots={4}
          defaultView="week"
          events={eventValues}
          defaultDate={dateValue}
          allDayAccessor="allDay" 
          date={dateValue}
          components={{
            toolbar: (props: any) => this.RenderCustomToolbar({ ...props, dateValue,activeTabs}),  
            event: (event:any)=>this.renderEvent(event),  
            header: (props: any) => this.CustomWeekHeader(props),
          }}
        />
        }
          {
          activeTabs === 1 &&
          <BigCalendar
          localizer={localizer} 
          events={eventValues}
          views={["month"]}
          defaultView="month"
          toolbar={true}
          timeslots={1}
          defaultDate={new Date()}
          components={{
            toolbar: (props: any) => this.RenderCustomToolbar({ ...props, dateValue,activeTabs }), 
            event: (event:any)=>this.renderEvent(event),  
          }}
        />
        }
      </div>
    );
  }
}

export default Calendar;
const webStyle={
  dateText:{
    color:"#1E293B",
    fontSize:'12px',
    fontWeight:600,
    fontFamily:'Poppins',
  },
  periodText:{
    color:"#64748B",
    fontSize:"12px",
    fontWeight:600,
    fontFamily:'Poppins',
  },
  monthText:{
    color:'#334155',
    fontSize:"12px",
    fontWeight:400,
    fontFamily:'Poppins'
  }
}