import React from "react";
import VisualAnalyticsController, {
    Props,
    configJSON,
  } from "./VisualAnalyticsController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import "./ViewDetail.css";
import { Box,
  IconButton,
  InputAdornment,
  TextField} from "@mui/material";
import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { expandUpArrow,directoryIcon,downArrow } from "./assets";


const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      body1: {
        fontFamily: "Poppins",
      },
    },
  });


export default class ViewDetailPage extends VisualAnalyticsController {
    constructor(props: Props) {
      super(props);
    }

    rowDatareturn=(dataValue:any,i:any)=>{
        const expandedOne = dataValue.id === this.state.levelOneId ;

        return(
          <>
        <TableRow
         style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}
        >
        <TableCell align="left"
        style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}
        > 
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => this.handleOpenSubTaskList(dataValue?.id)}
            style={{marginRight:"15px"}}
          >
            {(this.state.openTaskList && expandedOne) ? <img src={expandUpArrow} alt="down"/>:<img src={downArrow} alt="down"/>}
         </IconButton>   
         {dataValue.attributes.room_name}     
        </TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}>{dataValue.attributes && dataValue?.attributes?.start_date }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}>{dataValue.attributes && dataValue?.attributes?.start_date }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}>{dataValue.attributes && dataValue?.attributes?.retention }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontWeight:400,color:"#0F172A"}}>
          {dataValue.attributes && dataValue?.attributes?.room_cost?.toFixed(2) }
          </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={(this.state.openTaskList && expandedOne)} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">  
                <TableBody>
                  {
                  (this.state.subTaskListData && this.state.subTaskListData.data && this.state.subTaskListData.data.length)?
                  this.state.subTaskListData.data.map((val:any,i:any) => {
                    return this.rowDatareturnTwo(val)
                  }):(
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                        No data available.
                        </TableCell>
                    </TableRow>
                    )
                }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
          </>
        )
    }

    rowDatareturnTwo =(dataValue:any)=>{
         
        const expandedTwo = dataValue.id === this.state.levelTwoId 
        const attributes = dataValue && dataValue.attributes
        const attributesValue =   attributes?.task_list_name

        return(
          <>
           <TableRow style={{fontSize:"20px",fontWeight:400,color:"#0F172A"}} onClick={()=>this.handleSUbListOpenTask(dataValue?.id)} data-test-id="sublistClickBtn">
             <TableCell>
             <IconButton
                aria-label="expand row"
                size="small"
                data-test-id="iconBtnClick"
                style={{marginRight:"15px"}}
             >
               <img src={directoryIcon} alt="DirectoryIcon"/>
             </IconButton> 
             {attributes?.task_list_name}
            </TableCell> 
           </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={(expandedTwo && this.state.subList)} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="purchases" >  
                        <TableHead style={{backgroundColor:"#D3E4E9"}}>
                            <TableRow>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Task</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Specifications</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Qty</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Measurement</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Labour cost / unit</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Materials cost / unit</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Unit cost</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Total Cost</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Client Supplied</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{width:"100%"}}>
                        {attributes && attributes.project_tasks.map((val:any,i:any) => (
                            <TableRow key={i} style={{backgroundColor:"#F0F5F7",marginTop:"10px"}}>
                            <TableCell component="th" scope="row">
                                {attributesValue}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.specification_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.quantity}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes.specifications[0]?.measurement_unit}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.labour_cost}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.material_cost}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.total_cost}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.unit_total}
                            </TableCell>
                            <TableCell component="th" scope="row">
                            {val.attributes?.specifications[0]?.material_cost}
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
          </>
        )
    }
    render() {
  
      return (
        <ThemeProvider theme={theme}>
            <MyProfileHeader showSidebar={true} navigation={this.props.navigation} >
              <Box sx={styles.parentCont}>
                 <div className="container">
                     <div style={{display:"flex",gap:"30px",alignItems:"center"}}>
                        <span style={{color:"#334155",fontSize:"16px",fontWeight:600,display:"flex",alignItems:"center"}} onClick={()=>this.navigateSideTabs("VisualAnalytics")}>{"<"}{" "}{" "}{" "}Back</span>
                        <span  style={{color:"#0F172A",fontWeight:700,fontSize:"24px"}}> {configJSON.textHeader}</span>
                     </div>
                     <div className="subHeader">
                       <Box>
                        <TextField
                            variant="outlined"
                            placeholder="Search here..."
                            data-test-id="search-input"
                            value={this.state.searchText}
                            onChange={(e:any) => this.handleSearch(e)}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon
                                    style={{
                                    height: "20px",
                                    color: "#94A3B8",
                                    width: "20px",
                                    }}
                                />
                                </InputAdornment>
                            ),
                            style: {
                                width: "400px",
                                height: "40px",
                                borderRadius: "8px",
                                fontFamily:"poppins"
                            },
                            }}
                            inputProps={{
                            style: {
                            padding: "5px 5px", 
                            fontFamily:"Poppins"
                            },
                            maxLength: 50,
                            }}
                            style={{
                            borderRadius: "8px",
                            backgroundColor: "white",
                            height: "40px",
                            marginRight: "0.9rem",
                            fontFamily:"poppins"
                            }}
                            sx={{
                            "& .MuiInputBase-input::placeholder": {
                                fontFamily: "Poppins",
                            },
                            }}
                         />
                      </Box>
                      <Box style={{display:'flex',alignItems:"center",gap:"2px"}}>
                       <span style={{fontSize:"18px",color:"#334155",fontWeight:600}}>Sort by:</span> 
                       <select
                            value={this.state.optionValue}
                            onChange={this.handleChangeDropDown}
                            style={{ padding: '6px',outline:"none",border:"none",backgroundColor:"rgb(224, 237, 240)",fontSize:"18px"}}
                            data-test-id="sortValue"
                        >
                            <option style={{ fontSize:"16px"}} value="NONE">None</option>
                            <option style={{ fontSize:"16px"}} value="NAME">Name</option>
                            <option style={{ fontSize:"16px"}} value="START_DATE">Start Date</option>
                       </select>
                        </Box>
                     </div>
                     <div className="tableBody">
                     <Table style={{ width: '100%' }}>
                     <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Rooms / Area Type</TableCell>
                            <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Start Date</TableCell>
                            <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>End Date</TableCell>
                            <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Retention %</TableCell>
                            <TableCell align="left" style={{fontSize:"14px",fontWeight:600}}>Cost</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {this.state.pieChartData?.data?.data.length > 0 ? (
                        this.state.pieChartData.data.data
                        .filter((item: any) => 
                          this.state.searchText === '' || 
                          item?.attributes?.room_name.toLowerCase().includes(this.state.searchText.toLowerCase())
                        ).map((val: any,i:any) => {
                            return this.rowDatareturn(val,i);
                        })
                        ) : (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                            No data available.
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                     </Table>
                     </div>
                 </div>
              </Box>
            </MyProfileHeader>
        </ThemeProvider>
      );
    }
  }

  const CustomDropDown = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgb(240, 245, 247)",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
      color: "#237182",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSelect-select": {
      paddingBlock: "10px",
      color: "#237182",
      fontSize: "16px",
      fontWeight: 600,
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSvgIcon-root": {
      color: "#237182",
    },
  });

const styles = {
    parentCont: {marginLeft: {sm:'5.5rem', xs: '6rem'},padding: '30px 50px 10px 50px', gap: 3, display: 'flex', flexDirection: 'column',backgroundColor:"rgb(224, 237, 240)"},
  };