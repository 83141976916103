// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.sectionEndPoint  = "bx_block_kanbanboard/sections"
exports.settingsEndPoint  = "bx_block_kanbanboard/settings"
exports.GetTaskListEndPoint = "bx_block_tasks/task_lists"
exports.validationApiMethodType = "GET"
exports.httpPostMethod="POST"
exports.httpPatchMethod="PATCH"
exports.httpsDeleteMethod="DELETE"
exports.httpsPutMethod="PUT"
exports.GetRoomListEndPoint = "bx_block_profile/project_rooms/project_room_list"
exports.GetProjectMembersEndPoint ="bx_block_profile/project_teams_and_members/project_teams_and_members"
exports.PostAddAssignEndPoint = "bx_block_tasks/task_lists/add_assignee"
exports.GetProjectTaskListEndPoint="bx_block_tasks/projects"
exports.validationApiContentType = "application/json";
exports.patchChangeStatusEndPoint = "bx_block_tasks/projects"
exports.patchChangePriorityEndPoint = "bx_block_tasks/projects"
exports.kanboardTaskListAPIs="bx_block_tasks/projects/kanban_task_lists"
exports.kanbanBoardPutAPIs="bx_block_tasks/project_rooms_task_lists_tasks"
exports.getCommentListAPIs="bx_block_tasks/tasklist_comments?project_tasklist_id="
exports.commentsPostAPis="bx_block_tasks/tasklist_comments"
exports.attachmentListValues="bx_block_tasks/projects/list_uploaded_files"
exports.deleteCommentApis="bx_block_tasks/projects/delete_file/"
exports.tasklistApisEndpoint ="bx_block_profile/project_rooms"


// Customizable Area End
