// Customizable Area Start
import React from "react";

import { Box,Checkbox,Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  RadioGroup,
  TextField,
  Select,
  Typography, } from "@mui/material";

import "./ProjectCard.css";
import "react-multi-carousel/lib/styles.css";
import { projectCarouselData, status } from "./types";
import { ProjectCarousel } from "./ProjectCarousel.web";
import { Dialog, DialogActions, InputBase, Table, TableBody, TableCell, TableHead, TableRow, withStyles, CircularProgress,Chip} from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText}  from "@mui/material";
import { buildStyles, CircularProgressbar,  } from 'react-circular-progressbar';
import KanbanBoardController, { Props } from "./KanbanBoardController";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {checklistAPisIcon,calendarIconApi,increaseDecrease,unCheckIcon,checkedIcon, success,nextBtnClick,downArrowBtn,UpArrowBtn,UploadImageBtn,PdfBtnImage,threeDotIcon,attachment,rightArrowBtnValue, cross} from "./assets"
import { Close, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp, ExpandMore, Done, CheckCircleRounded } from "@material-ui/icons";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CustomSelect from "./AddMembersModal.web";
import { Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Clear, Search, } from "@material-ui/icons";
import {reload,cancelIcon } from "./assets"
import Dropzone from "react-dropzone";
import Loader from "../../../components/src/Loader.web";
import { FileDownloadOutlined } from "@mui/icons-material";
import { pdfFileIcon, pdfFileWarningIcon } from "../../../blocks/drawing/src/assets";
import moment from "moment";
import { crossSearchIcon, crossSmallIcon, searchIcon } from "../../../blocks/projectportfolio/src/assets";



export default class KanbanBoard extends KanbanBoardController {
  constructor(props: Props) {
    super(props);
  }

   UploadButton = styled("button")(({ isEnabel }:any) => ({
      borderRadius: "8px",
      border: "1px solid #C5E0E7",
      fontWeight: 600,
      fontSize: "16px",
      height: "56px",
      letterSpacing: 0,
      textTransform: "none",
      padding: "10px 16px",
      boxShadow: "none",
      fontFamily: "Poppins",
      borderWidth: 0,
      cursor: "pointer",
      backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
      color: isEnabel ? "#FFFFFF" : "#64748B",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
      },
    }));

  renderChangeListView=()=>{
    return(
      <Box component="div" style={webStyle.OptionBox}>
                  <Box component="div" style={webStyle.singleOptionBox}>
                    <Box
                      data-test-id="button1"
                      onClick={() => this.handleToggle("Kanban View")}
                      component="div"
                      style={{
                        ...webStyle.PersonalInformationOption,
                        backgroundColor: this.state.kanbanViewState === "Kanban View" ? "#E0EDF0" : "",
                        color: this.state.kanbanViewState === "Kanban View"? "#325962" : "#64748B",
                      }}
                    >
                      Kanban View
                    </Box>
                    <Box
                      data-test-id="button2"
                      onClick={() => this.handleToggle("List View")}
                      component="div"
                      style={{
                        ...webStyle.BusinessInformationOption,
                        backgroundColor: this.state.kanbanViewState === "Kanban View" ? "" : "#E0EDF0",
                        color: this.state.kanbanViewState === "Kanban View" ? "#64748B" : "#325962",
                      }}
                    >
                      List View 
                    </Box>
                  </Box>
                </Box>
    )
  }
  
  handleSort = (roomId: any, key: any, direction: any) => {
  
    const sortedData = { ...this.state.savelistResponseValue };
  
    if (Array.isArray(sortedData[roomId])) {
      sortedData[roomId] = [...sortedData[roomId]].sort((a, b) => {
  
    let aValue = a.attributes[key] ?? ""; 
    let bValue = b.attributes[key] ?? "";
  
    if (key === "priority") {
      const priorityOrder: any = { high: 3, medium: 2, low: 1 };
      aValue = priorityOrder[aValue] || 0;
      bValue = priorityOrder[bValue] || 0;
    }
    if (key === "tasks_count") {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }
  
    if (aValue < bValue) return direction === "asc" ? -1 : 1;
    if (aValue > bValue) return direction === "asc" ? 1 : -1;
    return 0;
  });
    }
  
    this.setState((prevState) => ({
      savelistResponseValue: sortedData,
      sortedColumn: { ...prevState.sortedColumn, [roomId]: key },
      sortConfig: { key, direction },
    }));
  };
  
  
  renderKanbanListViewCard =()=>{
    const statusMapping :any= {
      to_do: { label: "To Do", bgColor: "#3B82F6", textColor: "#FFFFFF" },
      in_progress: { label: "In Progress", bgColor: "#FBBF24", textColor: "#FFFFFF" },
      complete: { label: "Completed", bgColor: "#34D399", textColor: "#FFFFFF" },
      deleted: { label: "Blocked", bgColor: "#F87171", textColor: "#FFFFFF" }
    };
   
    const roomNameCount: { [key: string]: number } = {};
    const roomInstances: { [key: string]: number } = {};

  this.state.originalRoomList.forEach((room: any) => {
    const baseName = room?.attributes.room_name;
    roomNameCount[baseName] = (roomNameCount[baseName] || 0) + 1;
  });
  
    return (
      <>
      <div style={{display:"flex",flexDirection:"column",width:"100%",marginTop:"20px",borderRadius:"8px",fontFamily:"Poppins",color:"#64748B",gap:"10px"}}>
      {this.state.originalRoomList.length === 0 && (
        <div style={{ textAlign: "center", fontSize: "16px", color: "#475569", borderRadius: "8px",fontFamily:'Poppins' }}>
          No results found.
        </div>)
  }
        {
          this.state.originalRoomList?.map((val:any)=>{
            const baseName = val.attributes.room_name;

            if (!roomInstances[baseName]) {
              roomInstances[baseName] = 1;
            } else {
              roomInstances[baseName]++;
            }
  
            const roomNameWithSeries =
              roomNameCount[baseName] > 1 && roomInstances[baseName] > 1
                ? `${baseName} ${roomInstances[baseName] - 1}`
                : baseName; 
  

            return(
              <>
              <div style={{display:"grid",gridTemplateColumns:"5% 45% 25% 25%",height:"50px",alignItems:"center",backgroundColor:"white"}} data-test-id="handleOpenTaskListView" onClick={()=>this.handleOpenTaskListView(val?.id)}>
              <div style={{display:"flex",justifyContent:"center"}}>
                    {this.state.openedTaskLists.includes(val?.id) ? (
                      <img src={downArrowBtn} alt="down_btn" />
                    ) : (
                      <img src={rightArrowBtnValue} alt="right_arrow" />
                    )}
                  </div>
              <div style={{display:"flex",justifyContent:"start"}}>{roomNameWithSeries}</div>
              <div style={{display:"flex",justifyContent:"center"}}><div style={{display:"flex",gap:"10px"}}>Tasklist Count:<div style={{display:"flex",alignItems:"center",color:"#237182",gap:"10px",fontWeight:600}}><img src={checklistAPisIcon} alt="checklist"/>{val?.attributes?.completed_tasks_count}/{val?.attributes.task_list_count}</div></div></div>
              <div  style={{display:"flex",justifyContent:"center"}}><div style={{display:"flex",gap:"10px"}}><img src={calendarIconApi} alt="calendar_icon"/> {this.formatDate(val?.attributes?.start_date)}-{this.formatDate(val?.attributes?.end_date)}</div></div>
            </div>
            {
                 (this.state.openedTaskLists.includes(val?.id)) &&
                <div style={{width:"100%",marginBottom:"30px"}}>
                <Table size="small" aria-label="purchases" >  
                    <TableHead style={{backgroundColor:"white"}}>
                        <TableRow>
                        <TableCell style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" ,position:'relative'}}>
              Tasklist Name
              <div style={{position:'absolute',left:108,height:23,width:20,borderRadius:'50%',background:  this.state.sortedColumn?.[val.id]=== "task_list_name" ? "#D3E4E9" : "transparent"}}>
              <KeyboardArrowUp fontSize="small" style={{ cursor: "pointer",position:'absolute',top:-2,color:'#475569' }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"task_list_name", "asc")} />
              <KeyboardArrowDown fontSize="small" style={{ cursor: "pointer",position:'absolute',top:6,color:'#475569' }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"task_list_name", "desc")} />
              </div>
            </div>
          </TableCell> 

                            <TableCell  style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins"}}> 
                              <div style={{display:"flex",alignItems:"center",gap:"10px",position:'relative'}}> Tasks inside
                              <div
      style={{
        position: "absolute",
        left: 90,
        height: 23,
        width: 20,
        borderRadius: "50%",
        background:  this.state.sortedColumn?.[val.id]==="tasks_count" ? "#D3E4E9" : "transparent",
      }}
    >
      <KeyboardArrowUp
        fontSize="small"
        style={{ cursor: "pointer", position: "absolute", top: -2, color: "#475569" }}
        data-test-id="handleSort"
        onClick={() => this.handleSort(val.id,"tasks_count", "asc")}
      />
      <KeyboardArrowDown
        fontSize="small"
        style={{ cursor: "pointer", position: "absolute", top: 6, color: "#475569" }}
        data-test-id="handleSort"
        onClick={() => this.handleSort(val.id,"tasks_count", "desc")}
      />
    </div></div></TableCell>
                              <TableCell style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" ,position:'relative'}}>
              Priority
              <div style={{position:'absolute',left:54,height:23,width:20,borderRadius:'50%', backgroundColor:  this.state.sortedColumn?.[val.id] === "priority" ? "#D3E4E9" : "transparent"}}>
              <KeyboardArrowUp fontSize="small" style={{ cursor: "pointer",position:'absolute',top:-2 ,color:'#475569' }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"priority", "asc")} />
              <KeyboardArrowDown fontSize="small" style={{ cursor: "pointer",position:'absolute',top:6 ,color:'#475569' }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"priority", "desc")} />
            </div>
            </div>
          </TableCell>
          <TableCell style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px",position:'relative' }}>
              Status 
              <div style={{position:'absolute',left:51, background:'#D3E4E9',height:23,width:20,borderRadius:'50%',backgroundColor:  this.state.sortedColumn?.[val.id] === "status" ? "#D3E4E9" : "transparent"}}>
              <KeyboardArrowUp fontSize="small" style={{ cursor: "pointer",position:'absolute',top:-2,color:'#475569'  }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"status", "asc")} />
              <KeyboardArrowDown fontSize="small" style={{ cursor: "pointer",position:'absolute',top:6,color:'#475569'  }} data-test-id="handleSort" onClick={() => this.handleSort(val.id,"status", "desc")} />
            </div>
            </div>
          </TableCell>
                            <TableCell  style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins"}}>Team</TableCell>
                        </TableRow>
                    </TableHead>
                    {
              (Array.isArray(this.state.savelistResponseValue[val?.id]) ? this.state.savelistResponseValue[val?.id] : []).map((val: any) => {
              return (
                          <TableBody style={{width:"100%",backgroundColor:"white",fontFamily:"Poppins",fontSize:"14px"}} key={val?.id} >
                          <TableRow  style={{marginTop:"10px"}}>
                          <TableCell component="th" scope="row" style={{display:"flex",alignItems:"center",gap:"10px"}}>
                          <Checkbox
                                data-test-id="tasklist-change-id"
                                checked={this.state.isProjectTaskList?.includes(val?.id)}
                                onChange={() => this.handleChangeProjectTaskListSelect(val?.id, val?.attributes.roomId)}
                                icon={
                                  <img
                                    src={unCheckIcon}
                                    style={{ width: 16, height: 16 }}
                                    alt="unchecked"
                                  />
                                }
                                checkedIcon={
                                  <img
                                    src={checkedIcon}
                                    style={{ width: 16, height: 16 }}
                                    alt="checked"
                                  />
                                }
                                style={{ color: "#64748B" }}
                              />
                                <div data-test-id="handleTaskListCard" onClick={() => {this.setState({addedmembers:val.attributes.task_list_members});this.handleTaskListDetailCard(val?.id, val?.attributes?.room_id, val.attributes.priority, val.attributes.status)}}>{val?.attributes?.task_list_name}</div>
                              </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{display:"flex",alignItems:"center",color:"#237182",gap:"10px",fontWeight:600}}><img src={checklistAPisIcon} alt="checklist"/>{val?.attributes?.completed_tasks_count}/{val?.attributes.tasks_count}</div>
                          </TableCell>
                          <TableCell component="th" scope="row">
  {val.attributes.priority === "medium" ? (
    <span style={{ backgroundColor: "#FEF3C7", color: "#D97706", padding: "7px", borderRadius: "8px", fontWeight: 600, width: "100px" }}>
      Mid
    </span>
  ) : val.attributes.priority === "low" ? (
    <span style={{ backgroundColor: "#DBEAFE", color: "#2563EB", padding: "7px", borderRadius: "8px", fontWeight: 600, width: "100px" }}>
      Low
    </span>
  ) : (
    <span style={{ backgroundColor: "#FEE2E2", color: "#DC2626", padding: "7px", borderRadius: "8px", fontWeight: 600, width: "100px" }}>
      High
    </span>
  )}
</TableCell>

<TableCell component="th" scope="row">
  {val.attributes.status && statusMapping[val.attributes.status] && (
    <span
      style={{
        backgroundColor: statusMapping[val.attributes.status].bgColor,
        color: statusMapping[val.attributes.status].textColor,
        padding: "7px",
        borderRadius: "8px",
        fontWeight: 600,
        width: "100px",
        display: "inline-block",
        textAlign: "center"
      }}
    >
      {statusMapping[val.attributes.status].label}
    </span>
  )}
</TableCell>


                          <TableCell component="th" scope="row">
                          <div style={{position:'relative'}}>
                              {this.groupMem(val.attributes.task_list_members)}
                              </div>

                          </TableCell>
                          
                          </TableRow>
                      </TableBody>
                        )
                      })
                    }
                </Table>
              </div>
            }
            </>
            )
          })
        }
        
      </div>
     
    
     </> 
    )
  }
  groupMem = (members: any) => {
    const safeMembers = Array.isArray(members) ? members : []; // Ensure it's always an array
    const totalAddMem = safeMembers.slice(0, 3);
    const remainingCount = safeMembers.length - 3;
  
    return (
      <>
        <div style={{ display: 'flex', position: 'absolute', marginTop: '-15px', left: safeMembers.length > 3 ? -14 : 0 }}>
          {totalAddMem.map((item: any, index: any) => (
            <div
              key={index}
              style={{
                padding: 4,
                borderRadius: '50%',
                background: '#D3E4E9',
                left: index === 0 ? "0px" : index === 1 ? "16px" : "35px",
                border: '2px solid #E0EDF0',
                height: '18px',
                position: 'absolute',
                color: '#ABCDD5',
                fontFamily: 'Poppins',
                fontSize: '10px',
                fontWeight: 700,
                width: 22,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {item?.name?.split(" ").map((word: string) => word[0]).join("").toUpperCase()}
            </div>
          ))}
          {remainingCount > 0 && (
            <div
              style={{
                padding: 4,
                left: "66px",
                position: 'absolute',
                color: '#237182',
                fontFamily: 'Poppins',
                top: 4,
                fontSize: '10px',
                fontWeight: 700,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              +{remainingCount}
            </div>
          )}
        </div>
      </>
    );
  };
  
  render() {
    const statuses = [
      { name: "To Do", colorCode: "#60A5FA" },
      { name: "In Progress", colorCode: "#F59E0B" },
      { name: "Completed", colorCode: "#10B981" },
      { name: "Blocked", colorCode: "#F87171" },
      { name: "Archived", colorCode: "#6B7280" }
    ];
    return (
      <>
        <ThemeProvider theme={theme}>
        <MyProfileHeader showSidebar={true} navigation={this.props.navigation}>
        <Loader loading={this.state.loader}/>
        {this.state.messageToggle && (
              <MessageBox
                message={this.state.tostifymessage}
                status={this.state.status}
              />
            )}
          <Grid container sx={webStyle.gridCont} gap={2}>
            <Box style={{ margin: "2rem 2rem 2rem 2.9rem", width: "100%" }}>
              <Box style={{fontSize:"24px" ,fontFamily:"Poppins", fontWeight:700 , color:"#0F172A", lineHeight:"32px" }}>{this.state.projectName}</Box>
              <Box style={webStyle.headerSection2}>
              <Box style={{display:"flex",alignItems:"center",gap:"24px"}}> 
                 {this.renderChangeListView()}
                 <Box
                  style={{color: this.state.roomDataSelected.length === 0 ?'#94A3B8':"#00000033",
                    
                    border: '1px solid #CBD5E1',
                    borderRadius: '8px',
                    padding: '10px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: this.state.roomDataSelected.length === 0 ? 'not-allowed' : 'pointer',
                    opacity: this.state.roomDataSelected.length === 0 ? 0.6 : 1,
                    backgroundColor: this.state.roomDataSelected.length === 0 ? "" : "#fff"
                  }}
                  onClick={this.state.roomDataSelected.length !== 0 ? this.handleMenuOpen : undefined}
                >
                  <Typography sx={{
                    color: this.state.roomDataSelected.length === 0 ?'#94A3B8':"#000",
                    fontWeight: 400, fontFamily: "Poppins", fontSize: "16px", lineHeight: "24px"
                  }}>Bulk Action</Typography>
                  <KeyboardArrowDownIcon  sx={{ color: "#000" }} />
                </Box>
                
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  <MenuItem
                    onClick={(e:any) => this.handleActionClick('Change Status', e)}
                    sx={{
                      borderRadius: '10px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  >
                    Change Status <KeyboardArrowRightIcon />
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleActionClick('Assign User', '')}
                    sx={{
                      borderRadius: '10px',
                    }}
                  >
                    Assign User
                  </MenuItem>
                </Menu>
                
                <Menu
                  anchorEl={this.state.subMenuAnchorEl}
                  open={Boolean(this.state.subMenuOpen)}
                  onClose={this.handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  {['To Do', 'In Progress', 'Completed', 'Blocked', 'Archived'].map((status) => (
                  <MenuItem
                  key={status}
                  data-test-id="sub-menu-id"
                  onClick={() => this.handleSubMenuClick(status)}
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: '#ffffff',
                    },
                  }}
                >
                  <Typography
                    style={{
                      ...webStyle.statusStyle,
                      backgroundColor: this.getStatusBackgroundColor(status), 
                    }}
                  >
                    {status}
                  </Typography>
                </MenuItem>
                  ))}
                </Menu>
            </Box>
                
              <Box style={{display:"flex",alignItems:"center",gap:"24px"}}> 
                      <Box style={webStyle.topBox}>
                        <Typography style={webStyle.uploadedBy}>Sort By
                          <span style={webStyle.selectFont}>{this.state.selectedSortDate}</span>
                        </Typography>
                        <SortInput
                          disableUnderline
                          IconComponent={ExpandMore}
                          MenuProps={{
                            sx: menuStyles,
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root": { width: "180px"},
                               "& .MuiList-root": { padding: "0px" }
                              }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                               vertical: "top",
                              horizontal: "right"
                            }
                          }}
                          sx={{
                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            
                           
                          }}
                          data-test-id="sortBy"
                          onChange={this.handleDateSort}
                          renderValue={() => null} >
                          {["Closest First", "Furthest First"].map((option) => (
                            <MenuItem sx={{
                              "& .MuiList-root-MuiMenu-list": {
                                padding: "0px 0px",
                              },
                              "&:hover": {
                                backgroundColor: "#E0EDF0"
                              },
                           
                            
                              backgroundColor:
                                this.state.selectedSortDate === option ? "#E0EDF0 !important" : "transparent",
                              paddingRight: "20px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                            }} key={option} value={option}>
                              <Box style={webStyle.menuItem}>
                                <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight:"22px", color: "#0F172A", textAlign: "start", fontFamily: "Poppins"}}>{option}</Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </SortInput>
                      </Box>

                <Box onClick={this.handleFilter} data-test-id="filter-id" style={webStyle.filterStyle}>
                 <FilterListIcon width="15px" height="10px" /> <Typography style={{color:'#94A3B8' ,textAlign:"center" , paddingLeft:"0.2rem", cursor:"pointer"}}>Filter</Typography> 
                </Box>

                <Box>
                  <TextField
                    variant="outlined"
                    placeholder="Search here..."
                    data-test-id="search-input"
                    value={this.state.searchText}
                    onChange={(e:any) => this.handleSearch(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              height: "20px",
                              color: "#94A3B8",
                              width: "20px",
                            }}
                          />
                        </InputAdornment>
                      ),

                      style: {
                        width: "460px",
                        height: "6rem",
                        borderRadius: "8px",
                        fontFamily:"poppins"
                      },
                    }}
                    inputProps={{
                    style: {
                    padding: "10px 16px", 
                    fontFamily:"Poppins"
                     },
                    maxLength: 50,
                    }}
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "white",
                      height: "46px",
                      marginRight: "0.9rem",
                      fontFamily:"poppins"
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                </Box>
              </Box>
              </Box>
              {
                this.state.kanbanViewState === "Kanban View" &&
                <Grid container style={{ width: "100%" }}>
               {statuses.every((statusObj) => this.filterTasksByStatus(statusObj.name.toLowerCase()).length === 0) ? (
  <Box
    style={{
      textAlign: "center",
      fontSize: "16px",
      color: "#475569",
      display:'flex',justifyContent:'center',
      position:'absolute',top:'50%',left:'50%',fontFamily:'Poppins'
    }}
  >
    No data found.
  </Box>
) : (
  <Box sx={{ display: "flex", overflowX: "auto", gap: "16px", pb: "10px", width: "89vw" }}>
{  statuses.map((statusObj) => (
    <Grid data-test-id="dragDrop" onDragOver={(event) => event.preventDefault()}  onDrop={(event) => this.handleDrop(event, statusObj.name)} key={statusObj.name} xs="auto">
      <Box  sx={{ minWidth: "360px" }}>
        {this.renderStatusData(
          statusObj.name,
          this.filterTasksByStatus(statusObj.name.toLowerCase()).length,
          statusObj.colorCode
        )}
        {this.filterTasksByStatus(statusObj.name.toLowerCase()).map((task, index) => {
          const textColor = this.getPriorityColor(task?.priority, "text");
          const backgroundColor = this.getPriorityColor(task?.priority, "background");
          const assigneeName = task?.assignee_name?.[0] || "No Assignee";

          const startDate = task.startDate ? new Date(task.startDate) : new Date();
          const endDate = task.endDate ? new Date(task.endDate) : new Date();

          const formattedDateRange = `${startDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })} - ${endDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`;

          return this.renderCard(
            task?.taskId,
            task?.roomName,
            task?.taskName,
            task?.priority,
            textColor,
            task?.checklist,
            assigneeName,
            formattedDateRange,
            backgroundColor,
            task?.roomId,
            task.status,
            task.assignee_namearray,
            task.assignee_ids
          );
        })}
      </Box>
    </Grid>
  ))}
  </Box>
)}

              </Grid>
              }
              {
                this.state.kanbanViewState === "List View" &&
                <Grid container style={{ width: "100%"}}>
                 { this.renderKanbanListViewCard()}
                </Grid>
              }  
            </Box>
            {this.renderModal()}
            {this.renderConfirmDeleteModal()}
            {this.renderFilterModal()}
            {this.renderTaskListDetailModel()}
            {this.renderUploadModal()} 
            <DialogCustom   open={this.state.openError} data-test-id="closeee" onClose={()=>this.setState({openError:false})}
                style={{display: "flex", alignItems: "flex-start",  justifyContent: "center",    }}
                BackdropProps={{ style: { backgroundColor: "transparent" } }} >
                <div style={{ background: "white",  padding: "10px 16px",  borderRadius: "8px", display:'flex'}}>
                  {this.state.isSuccess?
                  <CheckCircleRounded style={{color:'#34D399',marginRight:'5px'}}/> : <ErrorOutlinedIcon style={{color:'#DC2626', marginRight:'5px'}}/>}
                <Typography > {this.state.statusError}</Typography> 
                </div></DialogCustom>
           <CustomToast  />
          </Grid>
        </MyProfileHeader>
      </ThemeProvider>
      </>
    );
  }
  renderFilterModal = () => {
    return (
      <Modal open={this.state.isFilterModal} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            overflowY:"scroll",
            width: "40vw",
            height: "100%",
            padding: "16px 16px",
            boxShadow:
              "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
            background: "white",
            position: "fixed",
            top: 0,
            right: 0,
            transform: "translateX(0)",
            display:"flex",
            justifyContent:"space-between",
            flexDirection:"column",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",   
            whiteSpace: "nowrap",
            gap:"5px"

          }}
        >
         <Box> <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            <Typography style={webStyle.renameText}>Filter</Typography>
            <Close
              data-test-id="close-filter-modal-id"
              onClick={this.handleFilterModal}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box>
            <Box style={webStyle.filterSection} onClick={this.handleOpenRoomList} data-test-id="filter_Room_open_id" >
              <Box style={{cursor:"pointer"}}>By Room/Area</Box>
              <Box> {this.state.isRoomListOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
              { this.state.isRoomListOpen ? this.renderRoomData(): ""}
            <Box style={webStyle.filterSection} onClick={this.handleOpenTaskList} data-test-id="filter_Task_open_id">
              <Box style={{cursor:"pointer"}}>By Tasklist</Box>
              <Box> {this.state.isTaskListOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
            { this.state.isTaskListOpen ? this.renderTaskListData(): ""}
            
            <Box style={webStyle.filterSection} onClick ={this.handleOpenPriorityList} data-test-id="filter_priority_open_id">
              <Box style={{cursor:"pointer"}}>By Priority</Box>
              <Box> {this.state.isPriorityOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
            { this.state.isPriorityOpen ? this.renderPriorityListData(): ""}

          </Box>
          </Box>
  
          <Box style={webStyle.filterButton}>
           
            <RenameButton
              onClick={this.ApplyFilterHandle}
              data-test-id="apply-filter-data-id"
              style={{
                padding:"0.8rem 1rem"
              }}
              disabled={
                !(
                  this.state.isTaskListSelectedFilter.length > 0 || 
                  this.state.isPrioritySelected.length > 0 || 
                  this.state.isRoomSelected.length > 0
                )
              }
               >
              Apply
            </RenameButton>
            <Typography
            style={{...webStyle.cancelFilterButton,cursor:"pointer"}}
              onClick={() => this.handleClearAllFilter()}
              data-test-id="clear-all-id"
            >
              Clear All
            </Typography>
          </Box>
        </Box>
      </Modal>
    );
  };
  

  renderRoomData = () => {
    const roomNameCount = new Map(); 
  
    return (
      <Box>
        {this.state.roomList.map((room) => {
          const roomName = room.attributes.room_name;
  
          const count = roomNameCount.get(roomName) || 0;
          roomNameCount.set(roomName, count + 1);
          const roomNameDisplay = count === 0 ? roomName : `${roomName} ${count}`;
  
          return (
            <Box key={room.id} style={webStyle.roomDataStyle}>
              <Checkbox
                data-test-id="room-change-id"
                checked={this.state.isRoomSelected.includes(room.id)}
                onChange={() => this.handleChangeRoomSelected(room.id)}
                icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
                checkedIcon={<img src={checkedIcon} style={{ width: 16, height: 16 }} />}
                style={{ color: "#64748B", padding: "9px 9px 9px 0px" }}
              />
              <Box>
                <Typography style={webStyle.roomsStatusCircleStyle}></Typography>
              </Box>
              <Box>{roomNameDisplay}</Box> 
            </Box>
          );
        })}
      </Box>
    );
  };
  

  renderTaskListData = () =>{
    return(
      <Box>  {this.state.taskList.map((task)=>(
        <Box style={webStyle.roomDataStyle}>
        <Checkbox
          data-test-id="room-change-id"
          checked={this.state.isTaskListSelectedFilter.includes(task.id)} 
        onChange={() => this.handleChangeTaskListSelected(task.id)}
         
         icon={
           <img
             src={unCheckIcon} style={{ width: 16, height: 16 }} />
         }
         checkedIcon={  <img
             src={checkedIcon}
             style={{ width: 16, height: 16 }}
           />}
         style={
           { color: "#64748B" ,padding:"9px 9px 9px 0px" }
         }
         />
         <Box style={{color:"#0F172A" , fontSize:"14px" , fontWeight:400 , fontFamily:"poppins"}}>{task.attributes.name}</Box>

        </Box>
      ))}</Box>
    )
  }
  
  renderPriorityListData = () =>{
    return(
      <Box>
        
       {this.state.isPriorityList.map((priority)=>(
        <Box style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
           <Checkbox
          data-test-id="room-change-id"
          checked={this.state.isPrioritySelected.includes(priority.id)} 
        onChange={() => this.handleChangePriority(priority.id)}
         
         icon={
           <img
             src={unCheckIcon} style={{ width: 16, height: 16 }} />
         }
         checkedIcon={  <img
             src={checkedIcon}
             style={{ width: 16, height: 16 }}
           />}
         style={
           { color: "#64748B",padding:"9px 9px 9px 0px"  }
         }
         />
          <Box style={{...webStyle.priorityStyleList,backgroundColor:priority.PriorityBackgroundColor}}>
            <Typography
              style={{
                ...webStyle.roomStatusCircleStyle,
                backgroundColor: priority.PriorityColor,
              }}
            ></Typography>
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Poppins",
                color: priority.PriorityColor,
              }}
            >
              {priority.Priority_name}
            </Box>
          </Box>
        </Box>
       ))}
      </Box>
    )
  }
  

  renderStatusData = (status: string, count: number, colorCode: string) => {
    return (
      <Box 
      style={webStyle.outerBoxStatus}>
        <Typography
          style={{
            ...webStyle.statusCircleStyle,
            backgroundColor: colorCode,
          }}
        ></Typography>
        <Box style={{ fontFamily: "poppins" }}>{status}</Box>
        <Box style={webStyle.statusCountStyle}>{count}</Box>
      </Box>
    );
  }
  
    renderCard = (()=>{
    return (
      id:string,
   roomType: string,
   taskName: string,
   priority: string,
   priorityColor: string,
   checklist: string,
   assignedTo: string,
   dateRange: string,
   priorityBgColor:string,
   roomId:string,
   status: string,
   assignee_namearray:any,
   assignee_ids:any
   ) => {
     const { priorityDropdownOpen } = this.state;

     return (
       <Grid data-test-id="dragStart" style={webStyle.cardOuterContainer} onDragStart={(event) => this.handleDragStart(event, id)} draggable>
         <Box style={webStyle.roomStyleMainCard}>
           <Box style={webStyle.roomStyleCard}>
             <Typography style={webStyle.roomStatusCircleStyle}></Typography>
             <Box style={webStyle.roomTypeStyle} data-test-id="handleTaskListDetailCard" onClick={()=>{this.setState({addedmembers:assignee_namearray,assignee_ids:assignee_ids});this.handleTaskListDetailCard(id,roomId,priority,status )}}>{roomType}</Box>
           </Box>
           <Box>
             <Checkbox
               data-test-id="tasklist-change-id"
               checked={this.state.isProjectTaskList?.includes(id)}
               onChange={() => this.handleChangeProjectTaskListSelect(id, roomId)}
               icon={
                 <img
                   src={unCheckIcon}
                   style={{ width: 16, height: 16 }}
                   alt="unchecked"
                 />
               }
               checkedIcon={
                 <img
                   src={checkedIcon}
                   style={{ width: 16, height: 16 }}
                   alt="checked"
                 />
               }
               style={{ color: "#64748B" }}
             />
           </Box>
         </Box>
        
         <Typography
           style={{
             ...webStyle.roomNameStyle,
             maxWidth: "220px",
             overflow: "hidden",
             textOverflow: "ellipsis",
             whiteSpace: "nowrap"
           }}
           title={taskName}
           data-test-id="nameee"
         >
           {taskName}
         </Typography>
         <Box
           style={{
             display: "flex",
             alignItems: "center",
             justifyContent: "space-between",
             marginTop: "10px",
           }}
         >
           <Box
             style={{ ...webStyle.priorityStyle, backgroundColor: priorityBgColor }}
             onClick={() => this.togglePriorityDropdown(id)}
           >
             <Typography
               style={{
                 ...webStyle.roomStatusCircleStyle,
                 backgroundColor: priorityColor,
               }}
             ></Typography>
             <Box
               style={{
                 fontSize: "12px",
                 fontWeight: 400,
                 fontFamily: "Poppins",
                 color: priorityColor,
               }}
             >
               {priority === "medium" ? "Mid" : priority}
             </Box>
             <KeyboardArrowDownIcon
               style={{ fontSize: "12px", color: priorityColor }}
             />
           </Box>
 
           {priorityDropdownOpen[id] && (
             <Box
               style={{
                 position: "absolute",
                 backgroundColor: "white",
                 boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                 zIndex: 1000,
                 padding: "10px",
                 borderRadius: "4px",
                 marginTop:"9rem"
               }}
             >
               {["Low", "Mid", "High"].map((level) => (
                 <Box
                   key={level}
                   style={{
                     padding: "5px 10px",
                     cursor: "pointer",
                     fontFamily: "Poppins",
                   }}
                   data-test-id="priority-change-id"
                   onClick={() => this.handlePriorityChange(id, roomId, level)}
                 >
                   {level}
                 </Box>
               ))}
             </Box>
           )}
 
           <Typography
             style={{
               color: "#237182",
               display: "flex",
               alignItems: "center",
             }}
           >
             <ChecklistIcon style={{ width: "16px", height: "16px" }} />
             <Typography style={{ fontSize: "12px" }}>{checklist}</Typography>
           </Typography>
         </Box>
         <Divider style={{ marginTop: "10px" }} />
         <Box
           style={{
             display: "flex",
             alignItems: "center",
             justifyContent: "space-between",
             marginTop: "10px",
           }}
         >
           <Box style={webStyle.containerDataStyle}>
             <PersonOutlineOutlinedIcon width="16px" height="16px" />{" "}
             <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {assignee_namearray.length > 0
                ? `${assignee_namearray[0]}${assignee_namearray.length > 1 ? ` +${assignee_namearray.length - 1}` : ""}`
                : "No Assignee"}
            </Typography>

           </Box>
           <Box style={webStyle.containerDataStyle}>
             <DateRangeIcon />
             <Box>
               <Typography
                 style={{
                   fontSize: "12px",
                   fontFamily: "Poppins",
                   fontWeight: 400,
                 }}
               >
                 {dateRange}
               </Typography>
             </Box>
           </Box>
         </Box>
       </Grid>
     );
   };
  })()
   getFileErrorMessage = (isFileTooLarge: boolean, errorMessage: string | null, fileSize: string) => {
    if (isFileTooLarge) {
      return "File size must not exceed 10 MB.";}
    if (errorMessage) {return "File loading error"; } return fileSize;}
  renderFileBlock = (fileMetaData:any) => {
    if (!fileMetaData || fileMetaData.length === 0) { return null; }
  
    return (
      <>
        <Typography className="attachedFileTypo">Attached Files:</Typography>
        {fileMetaData.map((fileData:any, index:any) => (
          <Paper
            key={index}
            style={{
              boxShadow: "rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px",
              borderRadius: "8px",
              padding: "6px 8px",
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: fileData.errorMessage ? "2px solid #DC2626" : "2px solid #34D399",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
              <img src={fileData.errorMessage? pdfFileWarningIcon : pdfFileIcon} alt="pdfFileIcon" />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className="fileNameTypo">{fileData.name}</Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    textAlign: "left",
                    color: fileData.errorMessage ? "#DC2626" : "#1E293B",
                  }}
                >
                  {fileData.errorMessage || fileData.size}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", gap: 8 }}>
              {fileData.errorMessage && (  <Box onClick={() => this.handleRemoveUploadedFile(fileData.name)}> <img src={reload} style={{ marginTop: "5px", height: 18, width: 18, cursor: "pointer" }} />
                </Box>)}
              <Box onClick={() => this.handleRemoveUploadedFile(fileData.name)}><img src={cancelIcon} alt="cancelIcon" /></Box>
            </Box>
          </Paper>
        ))}
      </>
    );
  };
  

  renderUploadModal=()=>{
    const hasErrorFiles = Array.isArray(this.state.fileMetadata) ? this.state.fileMetadata.some((file) => file.errorMessage)  : false;
  
    return(
      <MainWrapper>
        
                   
                           <CustomUploadDialog  PaperProps={{
                    style: {
                      width: '584px',
                      borderRadius: "16px",
                      padding: "24px 24px",
                      color: "#FFFFFF",
                      boxSizing:"border-box"
                    }
                  }} open={this.state.uploadFilePopUp} onClose={this.handleCloseUploadDialog}>
                    <Loader loading={this.state.loader}/>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "40px",
          }}
        >
          <Typography className="dialogTitleTypo">Upload Files</Typography>
          <Typography
            data-test-id="handleCloseUploadDialog"
            onClick={this.handleCloseUploadDialog}
            style={{
              color: "black",
              cursor: "pointer",
              width: "20px",
            }}
          >
            <div data-test-id="closedialog" onClick={()=>this.setState({isCommentAttatchment:false})}>
            <Clear style={{width:'20px'}}/>
            </div>
          </Typography>
        </Box>
        <Box>
       <Dropzone
        onDrop={this.onDrop}
        multiple={true}
      >
        {({ getRootProps, getInputProps }:any) => (
          <Box
            {...getRootProps()}
            style={{
              border: "2px dashed #CBD5E1",
              borderRadius: "8px",
              padding: "40px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#F8FAFC",
            }}
          >
            <input {...getInputProps()}  multiple/>
            <Typography
              style={{
                color:'#292524',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                textAlign: 'center',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
              }}
            >
             Drag & Drop File Here
            </Typography>
            <Typography
              style={{
                marginTop:'4px',
                color:'#475569',
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
                textAlign: 'center',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
              }}
            >
             Acceptable formats: JPG, PDF, PNG
            </Typography>
            <Typography
              style={{
                color:'#475569',
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
                textAlign: 'center',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
              }}
            >
              (Max size: 10MB)
            </Typography>
          </Box>
        )}
      </Dropzone>
       {this.state.file && this.renderFileBlock(this.state.fileMetadata)} 
     
      
    </Box>
       
        <DialogActions
          style={{
            marginTop: "38px",
            gap: "16px",
            padding: 0,
          }}
        >
          <CancelButton data-test-id="handleCloseUploadDialog" onClick={this.handleCloseUploadDialog}>Cancel</CancelButton>
          <this.UploadButton
            disabled={hasErrorFiles || !this.state.file || this.state.file.length === 0}
            isEnabel={!hasErrorFiles && this.state.file && this.state.file.length > 0}         
              onClick={this.handleUploadFile}>
              Upload
            </this.UploadButton>
        </DialogActions>
      </Box>
    </CustomUploadDialog>
    </MainWrapper>
      
    )
   }
   getUserName = (val: any) => {
    return val.attributes?.user_name || val.attributes?.uploaded_by;
  };
  calculateLeftPosition = (length:any) => {
    switch (length) {
      case 0:
        return 25;
      case 1:
        return 45;
      case 2:
        return 63;
      case 3:
        return 83;
      default:
        return 83;
    }
  };
  renderMembers = (totalAddMem: any) => {
    return (
      <>
        {(Array.isArray(totalAddMem) ? totalAddMem : []).map((item: any, index: number) => {
          const name = item?.name || "";
          const initials = name.split(" ").map((word:any) => word[0]).join("").toUpperCase();
          const profilePicture = item?.profile_picture;
  
          return (
            <div
              key={index}
              style={{
                padding: 4,
                borderRadius: "50%",
                background: "#D3E4E9",
                left: index === 0 ? "0px" : index === 1 ? "16px" : "35px",
                border: "2px solid #E0EDF0",
                height: "24px",
                width: "24px",
                position: "absolute",
                color: "#ABCDD5",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: 700,
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt={name}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                initials
              )}
            </div>
          );
        })}
      </>
    );
  };
  
  renderTaskListDetailModel= () =>{

    const taskListValueData = this.state.showAll ? this.state.taskListValues : this.state.taskListValues.slice(0,4)
    const commentListData = this.state.showComment ? this.state.commentListAll :this.state.commentListAll.slice(0,4)
    const renderAttachmentList = this.state.showAttachments ? this.state.attachmentList : this.state.attachmentList.slice(0,4)
    const mergedCommentList = [...this.state.commentListAll, ...this.state.comment_attachmentList].sort(
      (a, b) => new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime()
    );
    const completedTasks = this.getCompletedTasks(this.state.taskListMetaDetail.attributes);
    const totalTasks = this.getTotalTasks(this.state.taskListMetaDetail.attributes);
    const progress = this.calculateProgress(completedTasks, totalTasks);
    const visibleComments = this.state.showComment ? mergedCommentList : mergedCommentList.slice(0, 4);
    const totalAddMem = (this.state.taskListMetaDetail.attributes?.task_list_members || []).slice(0, 3)
    return (
      <Modal open={this.state.isTaskListDetailOpen} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            overflowY:"scroll",
            width: "40vw",
            height: "100%",
            padding: "24px",
            background: "white",
            position: "fixed",
            top: 0,
            outline: "none", 
            boxShadow: "none", 
            border: "none",
            right: 0,
            transform: "translateX(0)",
            display:"flex",
            gap:"20px",
            flexDirection:"column",
            justifyContent:"space-between",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
          }}
        >
         <Box> 
          <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            <Typography style={webStyle.renameText}>{this.state.taskListMetaDetail.attributes?.task_list_name}</Typography>
            <Close
              data-test-id="close-filter-modal-id"
              onClick={()=>this.handleTaskListDetailCard("","")}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , gap:"32px"}}>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between",width:"35%",lineHeight:"24px"}}>
               <Box style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                  <Typography style={webStyle.innerDetail} > <AdjustOutlinedIcon style={webStyle.iconDetailColorStyle} /> <Typography style={webStyle.multipleDetailDataStyle}>Status:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><AssistantPhotoOutlinedIcon style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Priority:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><PeopleOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Team:</Typography> </Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"10px"}}>
               <Box style={{ display: "inline-flex", flexShrink: 0,justifyContent:'center' }}>
               <Typography
                onClick={this.handleMenuClickDetail}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: this.getStatusBackgroundColorDetail(this.state.selectedStatus),
                  padding: "1px 6px 1px 8px",
                  borderRadius: "40px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  lineHeight:"16px",
                   marginTop:'-26px'
                }}
          >
            {this.state.selectedStatus}
            <span style={{ marginLeft: "8px" }}><KeyboardArrowDownIcon/></span>
      </Typography>
      </Box>
      <Menu
        anchorEl={this.state.anchorElStatus}
        open={Boolean(this.state.anchorElStatus)}
        onClose={this.handleClose}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        {this.state.statuses.map((status) => (
          <MenuItem
            key={status}
            onClick={() => this.handleSubMenuClick(status)}
            sx={{
              borderRadius: "8px",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Typography
              style={{
                backgroundColor: this.getStatusBackgroundColorDetail(status),
                padding: "4px 6px 4px 8px",
                borderRadius: "40px",
                color: "white",
              }}
            >
              {status}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <Box style={{ display: "inline-flex", flexShrink: 0,justifyContent:'center' }}>
      <Typography
        onClick={this.handlePriorityClick}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: this.getPriorityBackgroundColor(this.state.selectedPriority),
          padding: "0px 8px",
          borderRadius: "16px",
          fontSize:'12px',
          marginTop:'-5px',
          color:this.getPriorityColorStatus(this.state.selectedPriority)
        }}
      > <span style={{...webStyle.roomStatusCircleStyle,
        backgroundColor:this.getPriorityColorStatus(this.state.selectedPriority),marginRight:"3px"}}></span>
       <span> {this.getPriorityText(this.state.selectedPriority)}</span>
        <span ><KeyboardArrowDownIcon/></span>
      </Typography>
      </Box>
      <Menu
        anchorEl={this.state.anchorElPriority}
        open={Boolean(this.state.anchorElPriority)}
        onClose={this.handleCloseAction}
        PaperProps={{
          style: {
            borderRadius: "8px",
            width: "109px",
          },
        }}
        style={{padding:"8px 16px"}}
      >
        {this.state.priorities.map((priority:string) => (
          <MenuItem
            key={priority}
            onClick={() => this.handlePriorityItemClick(priority)}
            sx={{
              padding: "8px 16px",
              borderRadius: "16px",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
         
            <Typography
              style={{
                backgroundColor: this.getPriorityBackgroundColor(priority),
                color:this.getPriorityColorStatus(priority),
                padding: "1px 8px",
                borderRadius: "16px",
                display:"flex",
                alignItems:"center",
                gap:"4px"
              }}
            >
                 <Typography style={{...webStyle.roomStatusCircleStyle,
                backgroundColor:this.getPriorityColorStatus(priority)}}></Typography>
             <Typography> {this.getPriorityText(priority)}</Typography>
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <div style={{display:'flex',position:'relative',marginTop:'-5px'}}>

{this.renderMembers(totalAddMem)}

<Typography data-test-id="assignee" onClick={()=>this.setState({isAssignMemberModalOpen:true,addedmembers:this.state.taskListMetaDetail.attributes?.task_list_members})}><GroupAddOutlinedIcon style={{cursor:'pointer',width:"22px",height:"22px",position:'absolute',left:this.calculateLeftPosition(totalAddMem.length),marginTop:'5px',color:"#237182"}}/></Typography>
</div>

               </Box>
            </Box>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , width:"40%"}}>
            <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px", paddingRight:"0px"}}>
                  <Typography style={webStyle.innerDetail} > <DateRangeOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Start Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><DateRangeOutlinedIcon style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Due Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><LocalOfferOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Room/Area:</Typography></Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px",marginRight:"0px"}}>
               <Typography style={{color:"#237182",fontSize:"16px",fontWeight:600,fontFamily:"Poppins"}}>{moment(this.state.taskListMetaDetail.attributes?.room_details.start_date).format("DD/MM/YY")}</Typography>
                  <Typography style={{color:"#237182",fontSize:"16px",fontWeight:600,fontFamily:"Poppins"}}>{moment(this.state.taskListMetaDetail.attributes?.room_details.end_date).format("DD/MM/YY")}</Typography>
                  <Typography style={{color:"#64748B",fontSize:"12px",fontWeight:600,fontFamily:"Poppins" , display:"flex",alignItems:"center",gap:"4px",}}> <Typography style={webStyle.roomsStatusCircleStyle}></Typography><span style={{maxWidth: "100px", 
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer"}}
                        data-test-id="nameee"
                       
                        title={this.state.taskListMetaDetail.attributes?.room_details.name}>{this.state.taskListMetaDetail.attributes?.room_details.name}</span></Typography>
               </Box>
            </Box>
          </Box>   
          <Box style={{display:"flex",flexDirection:"column",gap:"5px", marginBottom:"10px", marginTop:"24px"}}>
          <Box style={{display:'flex',justifyContent:'space-between'}}>
          <Typography style={{fontSize:"16",fontWeight:600,color:"#334155",fontFamily:"Poppins",}}>Tasklist Details</Typography>
          <Box style={webStyle.taskIndicatorContainer}>
            <Typography style={webStyle.taskIndicatorCount}>{completedTasks}/{totalTasks}</Typography>
                  <Box sx={{ height: "20px", width: "20px" }}>
                    <CircularProgressbar
                      data-test-id="progressBar"
                      styles={buildStyles({
                        pathColor: "#325962",
                        trailColor: "#E4E8EB",
                      })}
                      strokeWidth={10}
                      value={progress}
                    >
                    </CircularProgressbar>
                  </Box>
          </Box>
          </Box>
          <Typography style={webStyle.checkboxMessage}>Click the checkbox next to the task to mark it as completed.</Typography>
          <div style={{ width: '100%' }}>
            {
              this.state.taskListValues?.length > 0 && taskListValueData?.map((val:any)=>{
                return (
                  <div style={{display:"flex",flexDirection:"column",gap:"5px",justifyContent:"space-around",marginBottom:"10px"}}>
                  <div 
                    style={{
                      display:"flex",
                      alignItems:"center",
                      fontSize:"16px",
                      height:"40px",
                      justifyContent:"space-between",
                      textAlign:"center"
                    }} 
                    onClick={()=>this.toggleItem(val?.id)}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <Checkbox
                        data-test-id="tasklist-change-id"
                        onClick={()=>this.handleCheckStatus(val?.id)}
                        checked={val.attributes.complete_status}
                        icon={
                          <img
                            src={unCheckIcon}
                            style={{ width: 20, height: 20 }}
                            alt="unchecked"
                          />
                        }
                        checkedIcon={
                          <img
                            src={checkedIcon}
                            style={{ width: 20, height: 20 }}
                            alt="checked"
                          />
                        }
                        style={{ color: "#64748B" }}
                      />
                      <span 
                      data-test-id="taskName"
                        style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          color:val.attributes.complete_status?'#94A3B8': '#0F172A',
                          fontSize: "14px",
                          lineHeight: "22px",
                          display: "block",
                          maxWidth: "195px",
                          alignItems: "center",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          cursor:'pointer',
                          textDecoration:val.attributes.complete_status?'line-through':'none'
                        }}
                      
                        title={val.attributes.task_list_name}
                      >
                         {val.attributes.task_list_name}
                      </span>
                    </div> 
                    {
                      this.state.toggleID === val?.id 
                      ? <img src={downArrowBtn} style={{height:"24px", width:"24px"}}/> 
                      : <img src={nextBtnClick} style={{height:"24px", width:"24px"}}/> 
                    }
                  </div>
                  
                  {
                    this.state.toggleID === val?.id &&
                    <div style={{
                      display: "flex",
                      backgroundColor: "#F0F5F7",
                      justifyContent: "space-between",
                      marginLeft: "28px",
                      borderRadius: "12px",
                      paddingTop: "8px", 
                      paddingBottom:"8px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0%",
                      alignItems: "center"
                    }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <div style={{ display: "flex", alignItems: "center"}}>
                          <span style={{ width: "102px", color:"#475569", paddingLeft:"16px"}}>Specifications:</span>
                          <span style={{ color: "#0F172A" }}>{val?.attributes?.specifications[0]?.specification_name}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "102px",  color:"#475569", paddingLeft:"16px"}}>Quantity:</span>
                          <span style={{ color: "#0F172A" }}>{val.attributes.specifications[0].quantity}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "102px",  color:"#475569", paddingLeft:"16px" }}>Unit cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val?.attributes?.specifications[0]?.unit_total || 0).toFixed(2)}</span>
                        </div>
                      </div>
                      
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px", paddingRight:"40px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Labour cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val?.attributes?.specifications[0]?.labour_cost || 0).toFixed(2)}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Material cost/ unit:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val?.attributes?.specifications[0]?.material_cost || 0).toFixed(2)}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ width: "131px",  color:"#475569" }}>Line Total:</span>
                          <span style={{ color: "#0F172A" }}>£ {parseFloat(val?.attributes?.specifications[0]?.total_cost || 0).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                
                )
              })
            }
          </div> 
          {this.state.taskListValues.length>5&&
          <div style={{display:"flex",justifyContent:"center"}}><span style={{color:"#237182",fontSize:"14px",fontWeight:600,display:"flex",alignItems:"center",fontFamily:"Poppins",cursor:'pointer'}} onClick={this.toggleShowAllTasks}>{this.state.showAll ? "Show less" :"Show more"}{this.state.showAll ? <KeyboardArrowUp style={{color:"237182",height:"25px"}}/>:<KeyboardArrowDown style={{color:"237182",height:"25px"}}/>}</span></div>      
          }     
          <div style={{height:"2px",borderRadius:"10px",width:"100%",backgroundColor:"#E2E8F0"}}></div>  
          <div style={{display:"flex",justifyContent:"space-between", marginTop:"16px"}}>
            <span style={{fontSize:"16px",fontWeight:600,color:"#334155",fontFamily:"Poppins"}}>Attachments</span>
                <span style={{
                  color: "#237182",
                  fontSize: "16px",
                  lineHeight:"24px",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontFamily: "Poppins",
                   cursor:'pointer'
                }}
                onClick={this.handleUploadFileBtn}
                >
                  <img
                    src={UploadImageBtn}
                    style={{
                      width: "16px",
                      height: "20px",
                    }}
                    alt="Upload"
                  />
                  Upload Files 
                </span>

          </div> 
          <div style={{display:"flex",flexDirection:"column",gap:"10px",marginBottom:"10px"}}>
            {
             this.state.attachmentList?.length > 0 && renderAttachmentList?.map((val:any,i:any)=>{
                return(
                  <Paper elevation={2} key={i} style={{justifyContent:"space-between",display:"flex",alignItems:"center",borderRadius:"10px"}}>
                  <div style={{display:"flex",gap:"20px",alignItems:"center", padding:'10px'}}>
                      <div style={{backgroundColor:"#E0EDF0",borderRadius:"10px",display:"flex",alignItems:"center",justifyContent:"center"}}> <img src={PdfBtnImage} style={{height:"43px"}}/></div>
                      <div style={{display:"flex",flexDirection:"column",fontSize:"14px",fontFamily:"Poppins"}}>
                         <span>{val.attributes.file_name}</span> <span style={{fontSize:'12px',color:'#64748B'}}>{val.attributes.file_size}</span>
                        </div>
                  </div>
                  <div>
                  <Button 
                  id="basic-button"
                  data-test-id="basic-button"
                  aria-controls={this.state.commentPopUpState ? 'basic-menu' : undefined}
                  onClick={(e:any)=>this.handlePopUpOpen(e,val)}>
                    <img src={threeDotIcon} style={{height:"20px"}} /> 
                </Button>
                <Menu
                    anchorEl={this.state.anchorElPriorityUpload}
                    open={Boolean(this.state.anchorElPriorityUpload)}
                    onClose={()=>{this.setState({anchorElPriorityUpload:null})}}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                  }}
                  transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                  }}
                  PaperProps={{
                      style: {
                          marginTop: "4px",
                          padding: '1px 4px',
                          borderRadius: '8px',
                          width: '147px',
                      }
                  }}
                    style={{padding:"8px 16px"}}
                  >

                      <MenuItem
                        style={{
                          padding: "8px 16px",
                          borderRadius: "16px",
                          backgroundColor: "transparent",
                          cursor:'pointer',
                          fontFamily:'Poppins',fontSize:'14px',
                          display:'flex',gap:5
                        }}
                        onClick={()=>this.downloadFile(val.attributes.url)}
                      >
                        <FileDownloadOutlined/>
                        <Typography> Download</Typography>
                      </MenuItem>
                      <MenuItem
                        style={{
                          color:'#DC2626',
                          padding: "8px 16px",
                          borderRadius: "16px",
                          fontFamily:'Poppins',fontSize:'14px',
                          backgroundColor: "transparent",
                          cursor:'pointer',display:'flex',gap:5
                        }}
                        onClick={this.openConfirmDelete}
                      >
                        <DeleteOutline style={{color:"#DC2626"}}/>
                        <Typography>Delete</Typography>
                      </MenuItem>
                  </Menu>
                  </div>
              </Paper>
                )
              })
            }
          </div>
          {this.state.attachmentList.length>4 &&
          <div style={{display:"flex",justifyContent:"center",marginTop:"16px"}}><span style={{color:"#237182",fontSize:"14px",fontWeight:600,cursor:'pointer',display:"flex",alignItems:"center",fontFamily:"Poppins"}} onClick={this.toggleShowAttachment}>{this.state.showAttachments ? "Show less" :"Show more"}{this.state.showAttachments ? <KeyboardArrowUp style={{color:"237182",height:"25px"}}/>:<KeyboardArrowDown style={{color:"237182",height:"25px"}}/>}</span></div>  
          }
          {/* <div style={{display:"flex",justifyContent:"center",marginTop:"16px"}}><span style={{color:"#237182",fontSize:"14px",fontWeight:600,display:"flex",alignItems:"center",fontFamily:"Poppins"}} onClick={this.toggleShowAttachment}>{this.state.showAttachments ? "Show less" :"Show more"}{this.state.showAttachments ? <img src={UpArrowBtn} style={{color:"237182",height:"25px"}}/>:<img src={downArrowBtn} style={{color:"237182",height:"25px"}}/>}</span></div>   */}
          <div style={{height:"2px",borderRadius:"10px",width:"100%",backgroundColor:"#E2E8F0",marginBottom:"5px"}}></div>
          <div style={{fontSize:"16px",fontWeight:600,color:"#334155",fontFamily:"Poppins",marginBottom:"2px", marginTop:"10px"}}>Comments</div>  
          <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                        {this.state.commentListAll.length + this.state.comment_attachmentList.length > 0 &&
  visibleComments.map((val: any, i: any) => {
    return (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Poppins",
          marginBottom: "10px",
        }}
      >
        {/* User Info and Date */}
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Poppins",
              color: "#0F172A",
            }}
          >
            {this.getUserName(val)}
          </span>
          <span
            style={{
              color: "#94A3B8",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {this.formatDateResponse(val.attributes.created_at)}
          </span>
        </div>

        {/* If it's a text comment */}
        {val.attributes.comment && (
          <div style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: 400 }}>
            {val.attributes.comment}
          </div>
        )}

        {/* If it's an attachment */}
        {val.attributes.file_name && (
          <Paper
            elevation={2}
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#E0EDF0",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={PdfBtnImage} style={{ height: "43px" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              >
                <span>{val.attributes.file_name}</span>{" "}
                <span style={{ fontSize: "12px", color: "#64748B" }}>{val.attributes.file_size}</span>
              </div>
            </div>
            <div>
              <Button
                id="basic-button"
                data-test-id="basic-button"
                aria-controls={this.state.commentPopUpState ? "basic-menu" : undefined}
                onClick={(e: any) => this.handlePopUpOpen(e, val)}
              >
                <img src={threeDotIcon} style={{ height: "20px" }} />
              </Button>
              <Menu
                anchorEl={this.state.anchorElPriorityUpload}
                open={Boolean(this.state.anchorElPriorityUpload)}
                onClose={this.closeActionTab}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    marginTop: "4px",
                    padding: "1px 4px",
                    borderRadius: "8px",
                    width: "147px",
                  },
                }}
                style={{ padding: "8px 16px" }}
              >
                <MenuItem
                  style={{
                    padding: "8px 16px",
                    borderRadius: "16px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    display: "flex",
                    gap: 5,
                  }}
                  onClick={() => this.downloadFile(val.attributes.url)}
                >
                  <FileDownloadOutlined />
                  <Typography> Download</Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    color: "#DC2626",
                    padding: "8px 16px",
                    borderRadius: "16px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    display: "flex",
                    gap: 5,
                  }}
                  onClick={this.openConfirmDelete}
                >
                  <DeleteOutline style={{ color: "#DC2626" }} />
                  <Typography>Delete</Typography>
                </MenuItem>
              </Menu>
            </div>
          </Paper>
        )}
      </div>
    );
  })}
</div>
{/* Show More / Show Less Button */}
{mergedCommentList.length > 4 && (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <span
      style={{
        color: "#237182",
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        fontFamily: "Poppins",
        cursor: "pointer",
        marginBottom:'20px'
      }}
      onClick={this.toggleShowComments}
    >
      {this.state.showComment ? "Show less" : "Show more"}
      {this.state.showComment ? (
        <KeyboardArrowUp style={{ color: "237182", height: "25px" }} />
      ) : (
        <KeyboardArrowDown style={{ color: "237182", height: "25px" }} />
      )}
    </span>
  </div>
)}
          </Box>  
          </Box>
          <div style={{display:"flex",position:"sticky",bottom:"39px",gap:"10px",opacity:1}}>
            <div style={{ position: "relative", width: "100%" }}>
              <input
                type="text"
                style={{
                  width: "100%",
                  height: "44px", 
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #CBD5E1",
                  padding: "12px 40px 12px 16px",
                  boxSizing: "border-box",
                  outline: "none", 
                  color: "#475569" 
                }}
                onKeyDown={(e) => { this.sendEnter(e)}}
                data-test-id="handleTypeComment"
                onChange={(e) => this.handleTypeComment(e.target.value)}
                value={this.state.commenttext}
                placeholder="Type a comment here..."
              />

              <img
               data-test-id="attachImage"
              onClick={()=>{this.setState({file: [],
                fileMetadata: [],isCommentAttatchment:true,uploadFilePopUp:true})}}
                src={attachment} 
                alt="Attachment"
                style={{
                  position: "absolute",
                  right: "12px", 
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                  opacity: 0.6 
                }}
              />
            </div>
            {
              this.state.commenttext?.length > 0 ?
              <button style={{width:"80px",height:"44px",border:"none",backgroundColor:"#237182",fontSize:"16px",fontFamily:"Poppins",cursor:'pointer',fontWeight:600,color:"white",borderRadius:"10px",bottom:"24px"}} 
              onClick={this.postCommentAPIs}>Send</button>:
              <button style={{width:"80px",height:"44px",border:"none",backgroundColor:"#F1F5F9",fontSize:"16px",fontFamily:"Poppins",cursor:'pointer',fontWeight:600,color:"#64748B",borderRadius:"10px",bottom:"24px"}} disabled={true}>Send</button>
            }
          </div>   
        </Box>
      </Modal>
    );
  }
  renderConfirmDeleteModal = () => {
    return (
      <DeleteDialog data-test-id="CustomDeletePopup" PaperProps={{
        style: {
          height: "232px",
          width: '584px',
          borderRadius: "16px",
          padding: "42px 32px",
          color: "#FFFFFF",
          boxSizing: "border-box"
        }
      }} open={this.state.openConfirmDeleteModal} onClose={this.closeDeleteModal}>
        <Box sx={{ padding: 0 }}>
          <Box style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Typography className="title" >Are you sure you want to delete this file? </Typography> <Typography onClick={this.closeDeleteModal} style={{
              color: "black",
              width: "20px",
              marginTop: "1px"
            }}>

              <Clear /></Typography></Box>
          <Typography sx={{ color: "#1E293B", fontSize: "16px", fontWeight: 400, fontFamily: "Poppins", lineHeight: "24px", marginTop: "10px" }}>This action cannot be undone.</Typography>

          <DialogActions style={{
            marginTop: "25px",
            gap: "12px",
            padding: "0px"
          }}>
            <Button data-test-id="handleCloseDialog" className="cancelButton" onClick={this.closeDeleteModal}>
              Cancel
            </Button>
            <Button data-test-id="handleConfirmDelete" className="confirmButton" onClick={this.handleDeleteUploadedFile}>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </DeleteDialog>
    )
  }

  hasNoResults=(searchText:any, list:any)=>{
    return searchText && list.length === 0;
  }
  getMemberMessage = (noResultsMember:any, noMembersMember:any) => {
    if (noResultsMember) {
      return "No results found";
    }
    if (noMembersMember) {
      return "No members available";
    }

    return "";

  };
  renderModal() {
    const {selectedMembers, searchText2, searchFieldError} = this.state
    const members = Array.isArray(this.state.users) ? this.state.users : []
    const filteredMembers = members.filter((member:any) =>
      member.first_name.toLowerCase().includes(this.state.searchText2.toLowerCase()) ||
      member.email.toLowerCase().includes(this.state.searchText2.toLowerCase())
    );
    const noResultsMember = this.hasNoResults(this.state.searchText2, filteredMembers);
    const noMembersMember = members.length === 0;
    return (
      <Modal open={this.state.isAssignMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={{...webStyle.modalHeader, marginBottom: "8px"}}>
            <Typography style={webStyle.renameText}>
            Assign Member
             </Typography>
            <Close
              data-test-id="close-data-id" onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}> Select a user from the list below or search by name or email.
                    </Typography>
                    <Box style={{
                      flexDirection:'row', 
                      display:'flex',
    alignItems: "center", gap:'16px', width: "100%"}}>
        <Box style={{
          overflowY: "auto", width: "100%",maxHeight:'70px',
           display: "flex",flexDirection: "row",
           padding: "2px 5px",
           alignItems: "center",gap: "12px",
           border: "1px solid #ccc",borderRadius: "8px",
        }}>
  <img src={searchIcon} 
  style={{ marginRight: '0px',marginLeft: '5px',marginTop: '-3px'}} />
      <Box sx={{
        display:'flex',
       flexWrap: "wrap",width:'100%',
      }}
        >


{selectedMembers.map((member:any) => (
<CustomChip
label={member.first_name}
onDelete={() => this.removeChipMember(member.id)}
key={member.id}
deleteIcon={<img src={crossSmallIcon}/>}
style={{  color: '#325962',fontWeight: 400,backgroundColor: '#E0EDF0',borderRadius: '16px',fontSize:'14px',fontFamily:'poppins',}}/>))}
 {this.state.customTextEntries.map((text: string, index: number) => (
    <CustomChip
    data-test-id="removee"  key={`custom-${index}`} label={text}
      onDelete={() => this.removeCustomText(index)}
      deleteIcon={<img src={crossSmallIcon} />}
      style={{
        color: "#475569",
        fontSize: "14px",
        backgroundColor: "#F1F5F9", 
        fontFamily: "Poppins",
        borderRadius: "16px",
        fontWeight: 400,
      }}
    />
  ))}


<TextField
  variant="standard"
  error={!!this.state.searchFieldError}
  size="small"
  placeholder={this.getMemberPlaceholder()} 
  value={searchText2}
  fullWidth
  onChange={this.handleSearch2}
  onKeyDown={this.handleKeyDownMember}
  InputProps={{
    disableUnderline: true,
    style: {
      textAlign: 'left',
      color: '#0F172A',
      minWidth:'50px',
      fontFamily: 'Poppins',
      margin: '0px',
      padding: '0px',
      textUnderlinePosition: 'from-font',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textDecorationSkipInk: 'none',
    },
  }}
  sx={{
    flex: 1,
    '& .MuiInputBase-input': {
      fontSize: '16px',
      fontWeight: 400,
      padding: '0px !important',
      fontFamily: 'Poppins',
      margin: '8px',
      textAlign: 'left',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '16px',
      color: '#94A3B8',
      marginLeft:'-10px',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
  }}
/>


</Box>
<IconButton onClick={this.clearSearch}
sx={{ marginLeft: "8px", color: "gray",marginTop:'-2px' }}><img src={crossSearchIcon} />
</IconButton>
</Box>

<Box sx={{display: "flex", justifyContent: "flex-end",marginRight:'-0px'}}>
</Box>
</Box>
            <Box>
{this.state.searchFieldError &&
 (<Box mt={1} 
        bgcolor=""
         p={1} borderRadius={1}>
          <Typography 
          style=
          {{color:'#DC2626',
            fontWeight:600,
          fontFamily:'poppins',
          marginTop:'-16px',
          fontSize:'12px',
          marginLeft:'-6px',
          }} variant="body2">
            {this.state.searchFieldError}
           </Typography>
         </Box>
        )}
    </Box>
    {(noResultsMember || noMembersMember) && 
    (
  <Box
    sx={{ textAlign: "center",
      color: "#0F172A", fontFamily: "Poppins",
      fontSize: "16px",fontWeight: 400, lineHeight: "24px",
      padding: "10px",
    }}
  >
    <Typography>
      No matches found. Add '{this.state.searchText2}' as text?
    </Typography>
    
    {this.state.searchText2 && (
            <Box
              style={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0F172A",
                  textAlign: "center",
                }}
              > {this.state.searchText2}
              </Box>   {this.state.customTextEntries.includes(searchText2.trim())? (
          <Box
            style={{
              alignItems: "center",
              gap: "6px",
              fontWeight: 600,
              display: "flex",
              color: "#16A34A",
              fontSize: "16px",
            }}
          >  Added as text <Done/>
          </Box>
        ) : (
          <RenameButton
            style={{
              padding: "6px 10px",
              color: "#325962",
              backgroundColor: "#E0EDF0",
            }}
            onClick={this.handleAddText}
          >
            Add as text
          </RenameButton>
        )}    
            </Box>
          )}
  </Box>
)}


          
            {!noResultsMember && !noMembersMember && (
              <List sx={{ mt: 2, maxHeight: "300px", overflowY: "auto" }}>
              {this.getFilteredMember(filteredMembers, selectedMembers).map((member: any) => (
                <ListItem
                  key={member.id}
                  sx={{
                    cursor: "pointer",
                    padding: "8px 15px",
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  data-test-id="toggleSelectMember"
                  onClick={() => this.toggleSelectMember(member)}
                >
                  <Checkbox
                  data-test-id="checkedd"
                  checked={
                    selectedMembers.some((m: any) => m.id === member.id) ||
                    (Array.isArray(this.state.addedmembers) && 
                      (this.state.addedmembers.some((m: any) => m.id === member.id) || 
                      (this.state.addedmembers.every((m: any) => typeof m === "string") && 
                       this.state.assignee_ids.includes(member.id))))
                  }
                    onClick={(e) => e.stopPropagation()} 
                    onChange={() => this.toggleSelectMember(member)}
                    icon={
                      <img
                        src={unCheckIcon}
                        style={{ width: 20, height: 20 }}
                        alt="unchecked"
                      />
                    }
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        style={{ width: 20, height: 20 }}
                        alt="checked"
                      />
                    }
                    style={{ pointerEvents: "auto" }}
                  />
            
            <ListItemIcon>
  {member.profile_picture ? (
    <img
      src={member.profile_picture}
      alt={member.first_name}
      style={{
        width: "44px",
        height: "44px",
        borderRadius: "50%",
        border: "2px solid #E0EDF0",
        objectFit: "cover",
      }}
    />
  ) : (
    <Box
      style={{
        background: "#D3E4E9",
        color: "#ABCDD5",
        width: "44px",
        height: "44px",
        borderRadius: "50%",
        border: "2px solid #E0EDF0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "26px",
          textAlign: "center",
        }}
      >
        {member.first_name.slice(0, 2).toUpperCase()}
      </span>
    </Box>
  )}
</ListItemIcon>

            
                  <ListItemText
                    primary={
                      <>
                        <Typography className="memberTabNameTypo">{member.first_name}</Typography>
                        <Typography className="memberTabEmailTypo">{member.email}</Typography>
                      </>
                    }
                    sx={{
                      pointerEvents: "none",
                      transition: "none",
                      ":hover": {
                        color: "black",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
            
            )}
           <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={ this.handleAddMemberModal}      
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
              data-test-id="assign-member-data-id"
              onClick={this.handleAddButtonClick}
            >
              Save
            </RenameButton>
          </Box>

        </Box>
      </Modal>
    );
  };
}

const CustomToast = styled(ToastContainer)({
  width: "unset",
  marginTop: "80px",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});
const DeleteDialog = styled(Dialog)(({ theme }) => ({
  "& .title": {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0F172A",
    fontFamily: "Poppins",
    lineHeight: "32px",
    letterSpacing: "-0.005em"
  },
  "& .cancelButton": {
    backgroundColor: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "90px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#E0EDF0",
    }
  },
  "& .confirmButton": {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "84px",
    padding: "10px 16px 10px 16px",
    fontWeight: 600,
    "&:hover": {
      background: "#237182",
    }
  },
  "& .dialogPaper": {
    borderRadius: '12px',
    border: '1px solid #237182',
    backgroundColor: '#F0F5F7',
    padding: '24px'
  },
  "& .dialodDescTypo": {
    color: '#334155',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .dialogLabel": {
    "& .MuiFormControlLabel-label": {
      color: '#0F172A',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
    }
  }
}));

const DialogCustom =styled(Dialog)({
  "& .MuiDialog-paper":{position: 'absolute',top: '43px',
  }
})
const CustomChip = styled(Chip)(({ theme }) => ({
  color: '#475569',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'right',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  backgroundColor: '#E0EDF0',
  padding: '6px 2px 6px 6px',
  borderRadius: '24px',
  gap: '2px',
  alignItems:'center',
  height: '32px',
  margin:'4px',
  '& .MuiChip-deleteIcon': {
    color: '#475569',
  },
  '& .MuiChip-label':{
    marginLeft:'-8px',
    marginRight:'-4px'
  }
}));
const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const SortInput = styled(Select)({
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "0px",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#F0F5F7",
  },
  "& .MuiSelect-icon": {
    color: "#237182",
    right:"unset"
  },
});
const menuStyles = {
  "& .MuiPaper-root": {
    borderRadius: "8px",
    minWidth: "500px",
    border: "1px solid #CBD5E1"
  },
  
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});

const webStyle = {
  checkboxMessage:{
    fontSize:"16px",
    color:"#475569"
  },
  taskIndicatorCount: {
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400
  },
  taskIndicatorContainer: {
    display:'flex',
    gap:5
  },
  iconDetailColorStyle:{
    color:"#475569",
    width:"24px",
    height:"24px",
    radius:"40px",
  },
  multipleDetailDataStyle:{
    color:"#475569",
    fontSize:"14px",
    fontWeight:400,
    fontFamily:"Poppins"
  },
  topBox: {
    display: "flex",
    alignItems: "center",
  },
  uploadedBy: {
    color: "#334155",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    fontFamily: 'Poppins',
  },
  selectFont:{
    color: "#237182",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginLeft:"6px"
  },
  menuItem:{
    display:"flex",
    gap:"15px",
    
  },
  roomDataStyle:{
    display:"flex",
    justifyContent:"start",
    alignItems:"center",
    gap:"8px",
    fontFamily:"poppins",
    fontWeight:600,
    fontSize:'12px',
    color:"#64748B"
  },
  filterSection:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"poppins",
    gap:"10px",
    padding:"6px 8px 6px 0px",
    color:"#0F172A"
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  filterModal: {
    overflowY:"scroll",
    width: "40vw",
    height: "100%",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    position: "fixed",
  top: 0,
  right: 0, 
  transform: "translateX(0)",
  whiteSpace:"nowrap",
  },
  inviteMemberStyle:{
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    margin: "0.5rem auto",
    paddingLeft:"12px"
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  filterButton: {
    marginTop:"16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom:"2rem"
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  cancelFilterButton: {
    color: "#237182",
    fontWeight:700,
    fontFamily:"poppins",
    fontSize:"18px"

  },
  statusStyle:{
    backgroundColor:"#3B82F6" ,
     fontWeight:400 ,
     fontSize:"12px",
     fontFamily:'Poppins',
     padding:"4px 6px 4px 8px",
     borderRadius:"40px",
     color:"#FFFFFF"

  },
  filterStyle:{
     borderRadius:"8px",
     backgroundColor:"white",
     BorderColor:"#CBD5E1",
     display:"flex",
     alignItems:"center",
     padding:"10px 12px",
     paddingRight:"25px",
     width:"7%"
  },
  containerDataStyle: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "#64748B",
  },
  priorityStyle: {
    borderRadius: "16px",
    width: "69px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "1px 8px",
  },
  priorityStyleList: {
    borderRadius: "16px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "1px 8px",
  },
  roomNameStyle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#334155",
  },
  roomStyleCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  roomStyleMainCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  roomStatusCircleStyle: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
  },
  roomTypeStyle: {
    fontFamily: "Poppins",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 600,
    cursor: "pointer",
  },
  cardOuterContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: "12px",
    margin:"1rem 0rem",
    width:"95%"
  },
  mainStatusStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusCountStyle: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#237182",
    color: "white",
    textAlign: "center" as "center",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "poppins",
  },

  statusCircleStyle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
    marginLeft:"1rem"
  },
  roomsStatusCircleStyle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#237182",
  },
  outerBoxStatus: {
    marginTop:"2rem",
    backgroundColor: "#FFFFFF",
    width: "95%",
    borderRadius: "8px",
    padding: "6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    gap: "8px",
  },
  gridCont: {
     paddingLeft:"6.5rem"
  },

  headerSection2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "poppins",
    fontSize: "16px",
    marginTop:"1.5rem"
  },
  outerBox: {
    borderRadius: "5px",
    padding: "0px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.625rem 40px 0px 40px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "50px",
    padding: "4px",
  },

  PersonalInformationOption: {
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "9px 16px",
    cursor: "pointer",
    fontSize: "16px",
  },

  BusinessInformationOption: {
    padding: "9px 16px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 &&
      ({
        textAlign: "center",
      } as React.CSSProperties)),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px 0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
  },

  sort: {
    marginRight: "3px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  innerDetail:{
    display:"flex",
    alignItems:"center",
    gap:"8px",
    fontSize:"14px"
  }
  
};

const MainWrapper = styled(Box)(({ theme }) => ({
  height:'100%',
  "& .main_box": {
      marginTop: '24px'
  },
  "& .main_paper": {
      marginTop: '32px',
      marginBottom: '32px',
      borderRadius: '12px'
  },
  "& .main_paper1": {
      marginTop: '16px',
      marginBottom: '32px',
      borderRadius: '12px'
  },
  "& .main_paper2": {
      marginTop: '32px',
      marginBottom: '24px',
      borderRadius: '12px'
  },
  "& .first_grid_box": {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '0px 24px'
  },
  "& .second_grid_box": {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '24px 24px'
  },
  "& .third_grid_box": {
    padding: '16px 24px'

  },
  "& .thirdGridHeadingTypo": {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .thirdGridDataTypoBox": {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '4px',
      alignItems:'center'
  },
  "& .thirdGridDataTypoBox2": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
  },
  "& .thirdGridDataTypo": {
      color: '#64748B',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none'
  },
  "& .thirdGridNameTypo": {
    color:'#64748B',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .mainBoxFirst":{
    display:'flex',
    justifyContent:'space-between'

  },
  "& .OptionBox": {
    display: "flex",
    justifyContent: "start",
  },
  "& .singleOptionBox": {
    display: "flex",
    backgroundColor: "#F0F5F7",
    borderRadius: "50px",
    padding: "4px",
    height:'36px',
    gap:'8px'
  },
  "& .search": {
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
}));
const styles = {
  root: {
    borderRadius: '50px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: '6px 12px',
    cursor: 'pointer',
    fontSize: '14px',
    backgroundColor: (props: any) =>
      props.toggle === props.type ? '#E0EDF0' : '#F0F5F7',
    border: (props: any) =>
      props.toggle === props.type ? '1px solid #D3E4E9' : 'none',
    color: (props: any) =>
      props.toggle === props.type ? '#325962' : '#64748B',
    transition: 'all 0.3s ease',
  },
};

const CustmoButtonBox = withStyles(styles)((props: any) => {
  const { toggle, type, ...otherProps } = props;
  return <Box {...otherProps} />;
});

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border:'1px solid #CBD5E1',
  padding: theme.spacing(0.5, 1),
  maxWidth: '400px',
  boxShadow: `0px 2px 4px ${theme.palette.divider}`,
}));

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  marginRight: 0,
  color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    transition: theme.transitions.create(['width']),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      color:'#94A3B8',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      '&:focus': {
        width: '250px',
        color:'#334155',
      },
    },
  },
}));
const ScalingButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  height: "44px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
  '&:hover': {
    backgroundColor: '#E0EDF0', 
  },


});
const CustomUploadDialog = styled(Dialog)({
  "& .dialogTitleTypo":{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .fileNameTypo":{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .fileSizeTypo":{
    color:'#64748B',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .attachedFileTypo":{
    marginTop:'16px',
    color:'#334155',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  }


});
const CancelButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  fontWeight: 600,
  fontSize: "16px",
  height: "56px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
  '&:hover': {
    backgroundColor: '#E0EDF0', 
  },
});

// Customizable Area End
// Customizable Area End
