export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const calendar = require("../assets/image_Calendar.png");
export const backButton = require("../assets/backButton.svg");
export const warningSign = require("../assets/warningSign.svg");
export const settingIcon = require("../assets/settingIcon.svg");
export const arrowNext = require("../assets/arrowNext.svg");
export const folderIcon = require("../assets/folderIcon.svg");
export const pdfIcon = require("../assets/pdfIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const arrowUpIcon = require("../assets/arrowUpIcon.svg");
export const selectedRadioButton = require("../assets/selectedRadioButton.svg");
export const unselectedRadioButton = require("../assets/unselectedRadioButton.svg");
export const percentageIcon = require("../assets/percentageIcon.svg");
export const addPlusIcon = require("../assets/addPlusIcon.svg");
export const textlabel_amount = require("../assets/textlabel_amount.png");
export const percentage = require("../assets/percentage.png");
export const download_icon = require("../assets/download_icon.png");
export const approve_icon = require("../assets/doubleRight.svg");
export const approveImg = require("../assets/approve_icon.svg");
export const rejectImg = require("../assets/reject_icon.svg");




