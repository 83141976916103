import React from "react";

import {
    // Customizable Area Start
    ThemeProvider,
    Box,
    Typography,
    Button,
    styled,
    Dialog,
    List,
    TextField, 
    Chip, 
    Checkbox, 
    ListItem, 
    ListItemText,
    DialogContent,
    IconButton,
    ListItemIcon,
    Divider
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { ToastContainer } from "react-toastify";
import { createTheme } from "@mui/material/styles";
import { imageEyeOff,imageEye, arrowDown, searchIcon, crossSearchIcon, addIcon,successIcon,crossSmallIcon } from "./assets";
import Menu from '@mui/material/Menu';
interface CustomButtonBoxProps {
  toggle: string;
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#9b59b6",
            contrastText: "#fff",
        },
        secondary: {
            main: "#f3f4f6",
        },
    },
});


import { Clear,Check } from "@material-ui/icons";
const ITEM_HEIGHT = 48;
// Customizable Area End

import AddAndViewMemberController, {
    Props
} from "./AddAndViewMemberController";


export default class AddAndViewMember extends AddAndViewMemberController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    hasNoResults=(searchText:any, list:any)=>{
      return searchText && list.length === 0;
    }
    renderEyeIcon = () => {
      const { viewAddedMemeber } = this.state;
      return viewAddedMemeber ? (
        <img src={imageEye} alt="View On" />
      ) : (
        <img src={imageEyeOff} alt="View Off" />
      );
    };
    getMemberMessage = (noResultsMember:any, noMembersMember:any) => {
      if (noResultsMember) {
        return "No results found";
      }
      if (noMembersMember) {
        return "No members available";
      }
      return "";
    };
    getTeamMessage = (noResultsTeam:any, noMembersTeam:any) => {
      if (noResultsTeam) {
        return "No results found";
      }
      if (noMembersTeam) {
        return "No teams available";
      }
      return "";
    };
    getMemberCount = (id: string) => {
      const team = this.state.getAllTeam.find((team) => String(team.id) === id);
      return team ? team.attributes.members_count : 0;
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { searchText, selectedMembers, selectedTeams, getAllMembers,getAllTeam } = this.state;

      
       
        const filteredTeams = getAllTeam.filter((team) =>
          team.attributes.team_name.toLowerCase().includes(searchText.toLowerCase())
        );
    
        const noResultsTeam = this.hasNoResults(searchText, filteredTeams);
        const noMembersTeam = getAllTeam.length === 0;


        const filteredMembers = getAllMembers.filter((member) =>
          member.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
          member.email.toLowerCase().includes(searchText.toLowerCase())
        );
        
        const noResultsMember = this.hasNoResults(searchText, filteredMembers);
        const noMembersMember = getAllMembers.length === 0;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <MainWrapper data-test-id="mainWrapper">
                      <CustomDialog 
                      data-test-testId = "themeProvider"
                      fullWidth
                      maxWidth="sm"
                      PaperProps={{
                        style: {
                          width: '616px',
                          borderRadius: "16px",
                          padding: "32px 32px",
                          color: "#FFFFFF",
                          boxSizing:"border-box",
                         
                        }
                      }} open={this.props.open} onClose={this.props.close}>
                        
                        <CustomToast data-test-id="customToast" icon={<img src={successIcon} />} />

                        <Box data-test-id="searchButton" style={{
                          marginTop:'-10px',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center'
                          }}>
                            <Typography data-test-id="addMember" className="title" >
                              Search and Add Member
                            </Typography> 
                            <Typography data-test-id="clearMember" onClick={this.props.close} style={{
                              color: "#0F172A",
                              width: "20px"
                              }}>
                                <Clear />
                            </Typography>
                            </Box>
                            <Box className="mainBoxFirst">
                              <Box className="OptionBox">
                                <Box className="singleOptionBox">
                                  <CustmoButtonBoxMembers
                                  toggle={this.state.toggle}
                                  data-test-id="handleToggleMembers"
                                  onClick={() => this.handleToggle("Members")}
                                  component="div"
                                  >
                                    Members
                                  </CustmoButtonBoxMembers>
                                  <CustmoButtonBoxTeams
                                  toggle={this.state.toggle}
                                  data-test-id="handleToggleTeams"
                                  onClick={() => this.handleToggle("Teams")}
                                  component="div"
                                  >
                                    Teams
                                  </CustmoButtonBoxTeams>
                                </Box>
                              </Box>
                              <Box className="viewAddedMembersBox" data-test-id="viewAddedMembersBox" onClick={this.handleViewAddedMemeber}>
                                <Box>{this.renderEyeIcon()}</Box>
                                <Box><Typography className="viewAddedMembersTypo">View Added Members</Typography></Box>
                              </Box>
                            </Box>

                            {this.state.viewAddedMemeber && <Box sx={{marginTop:'16px'}}>
                              <Box>
                                <Box style={{display:'flex',justifyContent:'space-between'}}>
                                  <Box><Typography className="membersAlreadyAddedTypo">Members Already Added:</Typography></Box>
                                  <Box><Typography className="membersAndTeamsCountTypo">{this.state.getAllAddedMemeber.members.length} members</Typography></Box>
                                </Box>
                                <TeamsAlreadyAddedListWrapper style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflowX: 'auto',
                                  maxHeight: '186px',
                                  marginTop:'5px'
                                }}>
                                  {this.state.getAllAddedMemeber.members.map((item) => 
                                  (
                                  <StyledAddedMemberListItem
                                  key={item.id}
                                 
                                  
                                  style={{display:'flex',justifyContent:'space-between'}}>
                                    <Box style={{display:'flex',alignItems:'center',gap:'12px'}}>
                                      <Box>
                                        <Box>
                                          <Box style={{ background: "#D3E4E9", color: "#ABCDD5",
                                            width:'44px',height:'44px', borderRadius:'50%', border:'2px solid #E0EDF0',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                           }}>
                                            <span
                                            style={{
                                              fontFamily: 'Poppins',
                                              fontSize: '18px',
                                              fontWeight: 700,
                                              lineHeight: '26px',
                                              textAlign: 'center',
                                              textUnderlinePosition: 'from-font',
                                              textDecorationSkipInk: 'none',
                                            }}
                                            >
                                              {item.first_name.slice(0, 2).toUpperCase()}
                                            </span>
                                          </Box>
                                        </Box>
                                      </Box>
                                    <Box>
                                      <CustomListItemAddedMemberTabText primary={item.first_name} />
                                      <Typography className="memberTabEmailTypo">{item.email}</Typography>
                                    </Box>
                                  </Box>

       <Box style={{display:'flex',alignItems:'center',gap:'4px'}}>
       <Box data-test-id="deleteMemberById" onClick={()=>{this.deleteMemberById(item.id)}}><Typography className="removeTypo">Remove</Typography></Box>
       </Box>
       </StyledAddedMemberListItem>
     )
     )}

                          </TeamsAlreadyAddedListWrapper>

                        </Box>
                        <Box sx={{marginTop: '16px',marginBottom: '16px'
}}><Divider/></Box>
                        <Box>
                        <Box style={{display:'flex',justifyContent:'space-between'}}>
                            <Box><Typography className="membersAlreadyAddedTypo">Teams Already Added:</Typography></Box>
                            <Box><Typography className="membersAndTeamsCountTypo">{this.state.getAllAddedMemeber.teams.length} teams</Typography></Box>
                          </Box>
                          <TeamsAlreadyAddedListWrapper style={{
                           display: 'flex',
                           flexDirection: 'column',
                           overflowX: 'auto',
                           maxHeight: '186px',   
                        }}>
                        {this.state.getAllAddedMemeber.teams.map((item) => 
       (
       <StyledAddedMemberListItem
         key={item.id}
         
         style={{display:'flex',justifyContent:'space-between', alignItems:"center"}}
         
       >
        <Box style={{display:'flex',alignItems:'center'}}>

         <Box>

         <CustomListItemAddedTeamsMemberTabText primary={item.team_name} />
                                <Box style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px'
                                }}>
         
          <Box data-test-id="handleOpenMembersList" onClick={(event) => {
                  event.stopPropagation();
                  this.handleOpenMembersList(String(item.id));
                }}  style={{display:'flex',alignItems:'center',gap:'4px'}}>
                <Typography style={{
                    color:'#64748B',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                }}
               
                >{this.getMemberCount(String(item.id))} members</Typography>
                                 <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{padding:"0px"}}
        >
         <DropDownIcon>
<img style={{pointerEvents: 'auto', cursor: 'pointer'}}  src={arrowDown} alt="img"/>
</DropDownIcon>
        </IconButton>
        <ListMenuTeam
          id="long-menu"
          MenuListProps={{ 'aria-labelledby': 'long-button' }}
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          slotProps={{
            paper: {
              style: { maxHeight: ITEM_HEIGHT * 4.5, width: '20ch' },
            },
          }}
        >
          {this.state.teamsMembersList.map((member, index) => (
            <Typography  style={{padding:'8px 16px 8px 16px',
              color:'#0F172A',fontFamily: 'Poppins !important',   fontSize: '12px',fontWeight: 400,lineHeight: '16px', }}  key={index}>
                                    {member.attributes.first_name} {member.attributes.last_name}
                                  </Typography>))}
        </ListMenuTeam>
      </div>
                                </Box>
                                </Box>
                                </Box>
                                </Box>
                            <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <Box data-test-id="deleteTeamById" onClick={() => { this.deleteTeamById(item.id) }}><Typography className="removeTypo">Remove</Typography></Box>
                            </Box>
                          </StyledAddedMemberListItem>
                        )
                        )}
                      </TeamsAlreadyAddedListWrapper>
                    </Box>
                  </Box>}
                        
                    
                        {!this.state.viewAddedMemeber && 
                        this.state.toggle==="Teams" &&

                    <DialogContent
                      sx={{
                        padding: '16px 10px',
                        overflow: 'unset'
                      }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: "center",
                        gap: '16px',
                        width: "100%"
                      }}>
                        <Box data-test-id="searchContainer" sx={{
                          display: "flex", flexDirection: "row",
                          alignItems: "center", gap: "12px",
                          border: "1px solid #ccc", borderRadius: "8px",
                          padding: "2px 5px",
                          overflowY: "auto", width: "100%",maxHeight: '70px'
                        }}>
  <img src={searchIcon} style={{ marginRight: '0px',marginLeft: '5px',marginTop: '-3px'}} />
  
      <Box
      sx={{
        display:'flex',
       flexWrap: "wrap"
        ,width:'100%',
      }}
        >


{selectedTeams.map((member) => (
<CustomChip
 data-test-id="removeChipTeam"
key={member.id}
label={member.attributes.team_name}
onDelete={() => this.removeChipTeam(member.id)}
deleteIcon={<img src={crossSmallIcon}/>}
sx={{ 
backgroundColor: '#E0EDF0',borderRadius: '16px',
color: '#325962',fontWeight: 400,
fontSize:'14px',fontFamily:'poppins',
}}
/>
))}


<TextField
  variant="standard"
  size="small"
  placeholder={this.getTeamPlaceholder()} 
  error={!!this.state.searchFieldError}
  value={searchText}
  onChange={this.handleSearch}
  onKeyDown={this.handleKeyDownTeam} fullWidth
  InputProps={{disableUnderline: true,
    style: {minWidth:'50px',color: '#0F172A',
      fontFamily: 'Poppins',margin: '0px',
      padding: '0px',fontSize: '16px',
      fontWeight: 400,lineHeight: '24px',
      textAlign: 'left',textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',}}}
  sx={{flex: 1,'& .MuiInputBase-input': {fontFamily: 'Poppins',
      fontSize: '16px',fontWeight: 400,
      margin: '8px',padding: '0px !important',
      textAlign: 'left',},
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Poppins !important',fontSize: '16px',
      fontWeight: 400,marginLeft:'-10px'} }}/>
</Box><IconButton 
onClick={this.clearSearch}
sx={{ marginLeft: "8px", color: "gray",
marginTop:'-2px' }}><img src={crossSearchIcon} /></IconButton></Box>
<Box sx={{display: "flex", justifyContent: "flex-end",marginRight:'-0px'}}>
<CustomAddButton onClick={this.handleAddButtonClick}> Add</CustomAddButton></Box></Box>
<Box>
  
{this.state.searchFieldError && (<Box mt={1}  bgcolor="" p={1} borderRadius={1}>
<Typography 
style={{color:'#DC2626', fontFamily:'poppins',
fontSize:'12px', fontWeight:600,
marginLeft:'-6px',marginTop:'-16px'}} variant="body2">
{this.state.searchFieldError}</Typography>
</Box>)}</Box>

{(noResultsTeam || noMembersTeam) && (
<Typography
variant="body2"
color="textSecondary"
sx={{ textAlign: "center", mt: 2,
color: '#0F172A',
fontFamily: 'Poppins',
fontSize: '16px',
fontWeight: 400,
lineHeight: '24px',
textUnderlinePosition: 'from-font',
textDecorationSkipInk: 'none',
}}
>
{this.getTeamMessage(noResultsTeam, noMembersTeam)}
</Typography>
)}


{!noResultsTeam && !noMembersTeam && (
<List sx={{ margin: "4px 0px 0px", maxHeight: "300px", overflowY: "auto" }}>
{filteredTeams
  .filter(
    (member) =>
      !selectedTeams.some(
        (selectedTeams) => selectedTeams.id === member.id
      )
  ).map((member) => (
<ListItem
key={member.id}
data-test-id="toggleSelectTeam"
onClick={() => this.toggleSelectTeam(member)}
sx={{
padding:'20px 16px 8px 10px',
cursor: "none",
pointerEvents:'none',
backgroundColor: 'transparent',
"&:hover": { backgroundColor: "transparent" },
}}
>
<ListItemIcon sx={{minWidth:'30px'}}>

<Checkbox
 tabIndex={-1}
 disableRipple
checked={selectedTeams.some(
(m:any) => m.id === member.id
)}
style={{
padding: 0,
borderRadius: '6px',
width: '20px',
height: '20px',
pointerEvents: 'auto'
}}
icon={
<span
style={{
backgroundColor:'#FFFFFF',
borderRadius: '6px',
width: '20px',
height: '20px',
border: '1px solid #64748B',
display: 'inline-block',
}}
/>
}
checkedIcon={
<span
style={{
backgroundColor: '#325962',
color: '#fff',
borderRadius: '6px',
width: '20px',
height: '20px',
display: 'inline-block',
border: '1px solid #325962',
}}
>
<Check style={{ color: 'white', fontSize: 18 }} />
</span>
}
data-test-id="handleToggleItem"


/>
</ListItemIcon>
<ListItemText
sx={{marginBottom:'0px'}}
primary={
<Typography className="memberTabNameTypo">{member.attributes.team_name}</Typography>
}
/>
<Box style={{pointerEvents: 'auto', cursor: "pointer"}} data-test-id="selectedMembers" onClick={()=>selectedMembers.some(
  (m:any) => m.id === member.id
)} >

  <Box

>

<Box data-test-id="handleMemberListOpen" onClick={(event) => {
event.stopPropagation();
this.handleMemberListOpen(member)
}}  style={{display:'flex',alignItems:'center',gap:'4px',pointerEvents: 'auto', cursor: 'pointer'}}>
<Typography style={{
color:'#64748B',
fontFamily: 'Poppins',
fontSize: '14px',
fontWeight: 400,
lineHeight: '22px',
textAlign: 'left',
textUnderlinePosition: 'from-font',
textDecorationSkipInk: 'none',
}}

>{member.attributes.members_count} members</Typography>
<IconButton
    aria-label="more"
    id="long-button"
    aria-controls={this.state.anchorElTeamMember ? "long-menu" : undefined}
    aria-expanded={this.state.anchorElTeamMember ? "true" : undefined}
    aria-haspopup="true"
    onClick={this.handleClickteamMember}
    style={{ padding: "0px" }}
  >
    <DropDownIcon>
      <img
        style={{ pointerEvents: "auto", cursor: "pointer" }}
        src={arrowDown}
        alt="img"
      />
    </DropDownIcon>
  </IconButton>
{this.state.selectedTeamId === String(member.id) && (
       <div>
 
  
       <ListMenuTeam
         id="long-menu"
         MenuListProps={{ "aria-labelledby": "long-button" }}
         anchorEl={this.state.anchorElTeamMember}
         open={Boolean(this.state.anchorElTeamMember)}
         onClose={this.handleCloseteamMember}
         slotProps={{
           paper: {
             style: { maxHeight: ITEM_HEIGHT * 4.5, width: "20ch" },
           },
         }}
       >
         {this.state.teamsMembersList.map((member, index) => (
           <Typography
             style={{
               padding: "8px 16px",
               color: "#0F172A",
               fontFamily: "Poppins !important",
               fontSize: "12px",
               fontWeight: 400,
               lineHeight: "16px",
             }}
             key={index}
           >
             {member.attributes.first_name} {member.attributes.last_name}
           </Typography>
         ))}
       </ListMenuTeam>
     </div>
    )}
</Box>
</Box>

</Box>
</ListItem>
))}
</List>
)}
</DialogContent>
                        
                        }

                        {!this.state.viewAddedMemeber && this.state.toggle==="Members" &&
                        <DialogContent sx={{padding:'16px 2px',overflow:'unset'}}>
                      <Box sx={{
    display:'flex',
    flexDirection:'row', 
    alignItems: "center",
    gap:'16px', width: "100%"}}>
        <Box sx={{
           display: "flex",flexDirection: "row",
           alignItems: "center",gap: "12px",
           border: "1px solid #ccc",borderRadius: "8px",
           padding: "2px 5px",
           overflowY: "auto", width: "100%",maxHeight:'70px'
        }}>
  <img src={searchIcon} style={{ marginRight: '0px',marginLeft: '5px',marginTop: '-3px'}} />
  
      <Box
      sx={{
        display:'flex',
       flexWrap: "wrap"
        ,width:'100%',
      }}
        >


{selectedMembers.map((member) => (
<CustomChip
key={member.id}
label={member.first_name}
onDelete={() => this.removeChipMember(member.id)}
deleteIcon={<img src={crossSmallIcon}/>}
sx={{ 
backgroundColor: '#E0EDF0',borderRadius: '16px',
color: '#325962',fontWeight: 400,
fontSize:'14px',fontFamily:'poppins',
}}
/>
))}


<TextField
  variant="standard"
  size="small"
  placeholder={this.getMemberPlaceholder()} 
  error={!!this.state.searchFieldError}
  value={searchText}
  onChange={this.handleSearch}
  onKeyDown={this.handleKeyDownMember}
  fullWidth
  InputProps={{
    disableUnderline: true,
    style: {
      minWidth:'50px',
      color: '#0F172A',
      fontFamily: 'Poppins',
      margin: '0px',
      padding: '0px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
    },
  }}
  sx={{
    flex: 1,
    '& .MuiInputBase-input': {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      margin: '8px',
      padding: '0px !important',
      textAlign: 'left',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      marginLeft:'-10px'
    },
  }}
/>


</Box>
<IconButton onClick={this.clearSearch}
sx={{ marginLeft: "8px", 
color: "gray",
marginTop:'-2px' 
}}><img src={crossSearchIcon} />
</IconButton>
</Box>

<Box sx={{
display: "flex", 
justifyContent: "flex-end",
marginRight:'-0px'
}}>

<CustomAddButton
onClick={this.handleAddButtonClick}>
Add
</CustomAddButton>
</Box></Box>
            <Box>
{this.state.searchFieldError && (<Box mt={1} 
        bgcolor=""
         p={1} borderRadius={1}>
          <Typography 
          style={{color:'#DC2626',
          fontFamily:'poppins',
          fontSize:'12px',
          fontWeight:600,
          marginLeft:'-6px',
          marginTop:'-16px'
          }} variant="body2">
            {this.state.searchFieldError}
           </Typography>
         </Box>
        )}
    </Box>

         
            {(noResultsMember || noMembersMember) && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: "center", mt: 2,
                  color: '#0F172A',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                 }}
              >
                {this.getMemberMessage(noResultsMember, noMembersMember)}
              </Typography>
            )}

          
            {!noResultsMember && !noMembersMember && (
              <List sx={{ mt: 2, maxHeight: "300px", overflowY: "auto" }}>
                {this.getFilteredMember(filteredMembers, selectedMembers).map((member: any) =>  (
                  <ListItem
                    key={member.id}
                    onClick={() => this.toggleSelectMember(member)}
                    sx={{
                      cursor: "none",
                      pointerEvents:'none',
                      padding:'8px 15px 8px 0px',
                      backgroundColor: 'transparent',
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <ListItemIcon>
                    <Box style={{ background: "#D3E4E9", color: "#ABCDD5", width:'44px',height:'44px', borderRadius:'50%', border:'2px solid #E0EDF0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                  <span
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '18px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      textAlign: 'center',
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      
                    }}
                  >
                    {member.first_name.slice(0, 2).toUpperCase()}
                  </span>
                </Box>
                     
                    </ListItemIcon>
                    <ListItemText
 primary={
  <>
  
  <Typography className="memberTabNameTypo">{member.first_name}</Typography>
  <Typography className="memberTabEmailTypo">{member.email}</Typography>
  </>
}
 sx={{
 
   pointerEvents: 'none',
   transition: 'none', 
   ':hover': {
     color: 'black', 
   },
 }}
/>
<Box data-test-id="addIcon" style={{cursor: "pointer", pointerEvents: "auto"}} onClick={()=>selectedMembers.some(
                          (m) => m.id === member.id
                        )} ><img  src={addIcon} alt=""/></Box>
                     
                  </ListItem>
                ))}
              </List>
            )}
                        </DialogContent>}


                      </CustomDialog> 
                      
                      
                    </MainWrapper>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
   
 
  
}));
const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .title": {
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A",
        fontFamily: "Poppins",
        lineHeight: "32px",
        letterSpacing: "-0.005em"
      },
    "& .cancelButton": {
        backgroundColor: "#E2E8F0",
        color: "#325962",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "96px",
        fontWeight:600,
      },
      "& .confirmButton": {
        background: "#237182",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "121px",
        padding: "10px 16px 10px 16px",
        fontWeight:600,
      },
      "& .mainBoxFirst":{
        display:'flex',
        justifyContent:'space-between',
        marginTop:'16px'

      },
      "& .OptionBox": {
        display: "flex",
        justifyContent: "start",
      },
      "& .singleOptionBox": {
        display: "flex",
        backgroundColor: "aliceblue",
        borderRadius: "50px",
        padding: "4px",
        height:'36px'
      },
      "& .viewAddedMembersBox":{
        display:'flex',
        alignItems:'center',
        gap:'8px'

      },
      "& .viewAddedMembersTypo":{
        cursor:'pointer',
        color :'#237182',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "22px",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      },
      "& .memberTypo":{
        color:'#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
     
      "& .memberTabEmailTypo":{
        color:'#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        marginTop:'-5px'
      },
      "& .memberTabNameTypo":{
        color:'#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        marginTop:'-5px'
      },
      "& .membersAlreadyAddedTypo":{
        color:'#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .membersAndTeamsCountTypo":{
        color:'#475569',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

      },
      "& .removeTypo":{
        color:'#DC2626',
        fontFamily: 'Poppins, sans-serif', 
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
     

   
}));
const CustomAddButton = styled(Button)(({ theme }) => ({

    width: "100%", height: "44px",
    padding: "10px 16px", gap: "8px",
    borderRadius: "8px", background: "#237182",
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: "#237182",
      },
}));
const CustmoButtonBoxTeams = styled(Box)<CustomButtonBoxProps>(({ toggle, theme }) => ({
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "5px 12px 5px 12px",
    cursor: "pointer",
    fontSize: "16px",
    backgroundColor: toggle === "Teams" ? "#E0EDF0" : "",
    color: toggle === "Teams" ? "#325962" : "#64748B",
}));
const CustmoButtonBoxMembers = styled(Box)<CustomButtonBoxProps>(({ toggle, theme }) => ({
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "5px 12px 5px 12px",
    cursor: "pointer",
    fontSize: "16px",
    backgroundColor: toggle === "Members" ? "#E0EDF0" : "",
    color: toggle === "Members" ? "#325962" : "#64748B",
}));
const StyledAddedMemberListItem = styled(ListItem)<{ toggle?: boolean }>(
    ({ toggle, theme }) => ({
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: "#0F172A",
      cursor: toggle ? "pointer" : "default",
      padding: "8px 8px 8px 8px !important",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
      },
      "&:active": {
        backgroundColor: "transparent",
      },
    })
);
const CustomListItemAddedMemberTabText = styled(ListItemText)(({ theme }) => ({
    "& .MuiTypography-body1":{
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      marginTop: '-4px',
      color:'#0F172A'
    }
}));
const CustomListItemAddedTeamsMemberTabText = styled(ListItemText)(({ theme }) => ({
    "& .MuiTypography-body1":{
      color:'#0F172A',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
    }
}));
const CustomChip = styled(Chip)(({ theme }) => ({
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'right',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    backgroundColor: '#E0EDF0',
    padding: '6px 2px 6px 6px',
    borderRadius: '24px',
    gap: '2px',
    alignItems:'center',
    height: '32px',
    margin:'4px',
    '& .MuiChip-deleteIcon': {
      color: '#475569',
    },
    '& .MuiChip-label':{
      marginLeft:'-8px',
      marginRight:'-4px'

    }
}));
const CustomToast = styled(ToastContainer)({
    width: "40vw",
    marginTop: "0px",
    "& .Toastify__toast": {
      boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
      padding: "0.5rem 1rem",
      borderRadius: "8px",
    },
    "& .Toastify__toast-body": {
      fontFamily: "Poppins",
      color: "#0F172A",
      padding: 0,
    },
});
const DropDownIcon = styled(Box)({
  pointerEvents: 'auto', 
  cursor: 'pointer',
  "&:hover": {
    backgroundColor: "#D3E4E9",
    borderRadius:"50%"
  },
});
const TeamsAlreadyAddedListWrapper = styled(Box)({
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #ffffff", 

   "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
});
const ListMenuTeam = styled(Menu)({
  scrollbarWidth: "auto", 
  scrollbarColor: "#CBD5E1 #ffffff", 
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
"& .MuiPaper-elevation8":{
  boxShadow:"none",
  border:"1px solid #CBD5E1",
  borderRadius:"8px"
}
});


// Customizable Area End
