// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Documentation";
exports.labelBodyText = "Documentation Body";

exports.btnExampleTitle = "CLICK ME";
exports.rename="Rename";
exports.delete="Delete";
exports.document="Upload Document";
exports.folder="Add Folder";
exports.documents="Documents";
exports.deleteFolder="Delete Folder";
exports.addFolder="Add Folder";
exports.cancelText="Cancel";
exports.createText="Create";
exports.renameText="Rename";
exports.deleteText="Delete";
exports.closeText="CLOSE";
exports.renameFile="RENAME";
exports.deleteFile="DELETE";
exports.yesModal='YES';
exports.noModal='NO';
exports.file='Do you want to delete the File ?';
exports.deletefolder='Do you want to delete the Folder ?';
exports.folderDocument='THIS FOLDER IS EMPTY !';
exports.uploadFiles="Upload Files";
exports.dragDrop="Drag & Drop File Here";
exports.formats="Acceptable formats: PDF, JPG, PNG";
exports.maxSize="Max size: 10MB";
exports.attachedFiles="Attached Files:";
exports.uploadedBy="Uploaded by:";
exports.sortBy="Sort by:";
exports.noFiles="No file has been uploaded yet";
exports.documentation="DATA NOT FOUND!";


// Customizable Area End
