// Customisable Area Start
import React from "react";
import SpecialistCostPlanController, {
    Props,
} from "./SpecialistCostPlanController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
    Box,
    Button,
    createTheme,
    Grid,
    Menu,
    MenuItem,
    styled,
    ThemeProvider,
    Typography,
  Select,
  SelectProps,
} from "@material-ui/core";
import { ArrowBackIos, DeleteOutline, KeyboardArrowUp,KeyboardArrowDown } from "@material-ui/icons";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { pdfIcon } from "./assets";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Country, State } from "country-state-city";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        body1: {
            fontFamily: "Poppins",
        },
    },
});

const StyledBox = styled(Box)({
    padding: "2rem 2.5rem",
    textAlign: "center",
    '@media screen and (max-width: 500px)':{
        padding:'2rem 2rem 2rem 1rem'
    },
    "& .pageTitle": {
        display: "flex",
        justifyContent: "space-between",
        height: '38px',
        marginBottom: "3.5rem",
    },
    "& .backButton": {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        cursor: "pointer",
        width: '353px',
        fontWeight: "600",
        "& p": {
            fontSize: "0.875rem",
            color: "#334155",
            fontWeight: 600,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            color: "#334155",
        },
    },
    "& .titleText": {
        color: "#0F172A",
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: "2rem",
    },
    "& .buttonsBlock": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    },
    "& .addMemberBtn": {
        display: "flex",
        gap: "0.5rem",
        cursor: "pointer",
    },
    "& .addMemberIcon": {
        width: "20px",
        height: "20px",
        color: "#237182",
    },
    "& .addMemberText": {
        fontWeight: 600,
        color: "#237182",
    },
    "& .saveDraftBtn": {
        padding: "10px 16px",
        borderRadius: "8px",
        background: "#E0EDF0",
        color: "#325962",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "1.5rem",
        textTransform: "none",
        fontFamily: 'Poppins',
    },
    "& .subTitle": {
        fontSize: "1.125rem",
        color: "#475569",
    },
    "& .titleBox": {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    "& .MuiGrid-spacing-xs-2": {
        margin: 0,
    },
    "& .planItem": {
        padding: "2rem 1.5rem",
        background: "#FFF",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        height: "calc(100% - 4rem)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        "& .planTitle": {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "1.75rem",
            color: "#1E293B",
            margin: "1.5rem 0 1rem",
        },
        "& .planDesc": {
            color: "#475569",
            marginBottom: "1rem",
        },
    },
    "& .selectedScopeItem": {
        border: "1px solid #237182",
    },
    "& .selectScopeBtn": {
        width: "100%",
        background: "#237182",
        padding: "10px 16px",
        borderRadius: "8px",
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "1rem",
        textTransform: "none",
        "&:disabled": {
            background: "none",
            color: "#64748B",
        },
    },
});

const ProjectTitle = styled(Typography)({
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 700,
    textAlign: 'center' as 'center',
    '@media screen and (max-width: 699px)': {
        fontSize: '22px',
    },
    '@media screen and (max-width: 664px)': {
        fontSize: '19px',
    },
    '@media screen and (max-width: 525px)': {
        marginLeft: '-34px'
    },
    '@media screen and (max-width: 378px)': {
        fontSize: '16px'
    },

})
const BackButton = styled(Typography)({
    color: '#334155',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    '@media screen and (max-width: 420px)': {
        display: 'none'
    },
})
const StatusText = styled(Typography)({
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    '@media screen and (max-width: 589px)': {
        fontSize: '12px'
    },
    '@media screen and (max-width: 491px)': {
        marginLeft: '85px'
    },
    '@media screen and (max-width: 404px)': {
        marginLeft: '61px'
    },
})
const ResubmitButton = styled(Button)({
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    background: '#237182',
    borderRadius: '8px',
    padding: '10px 16px 10px 16px',
    textTransform: 'none' as 'none',
    cursor: 'pointer',
    "&:hover":{
    background: '#237182',
        
    },
    "&:disabled" :{
        background: "#F1F5F9", 
        cursor: "not-allowed",
      },
    '@media screen and (max-width: 589px)': {
        fontSize: '12px',
        padding: '4px 12px'
    }
})
const PageTitle = styled(Box)({
    display: 'flex',
    marginBottom: '3.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width: 491px)': {
        flexWrap: 'wrap'
    }
})
const FileName = styled(Typography)({
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    alignContent: 'center',
    textAlign: 'left',
    '@media screen and (max-width: 1201px)': {
        fontSize: '15px'
    },
    '@media screen and (max-width: 1182px)': {
        fontSize: '13px'
    },
    '@media screen and (max-width: 429px)': {
        fontSize: '10px'
    },
})
const UploadText = styled(Typography)({
    color: '#237182',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media screen and (max-width: 403px)': {
        fontSize: '10px'
    },
})
const MenuDropdown = styled(Menu)({
    '& .MuiList-padding': {
        paddingTop: '3px',
        paddingBottom: '3px'
    }
})
const webStyle = {
    pageTitle: {
        display: 'flex',
        marginBottom: '3.5rem',
        justifyContent: 'space-between'
    },
    backButton: {
        gap: '0.5rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        marginRight: '38px'
    },
    textTitle: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 700,
        textAlign: 'center' as 'center'
    },
    statusType: {
        color: '#059669',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 700,
        backgroundColor: '#D1FAE5',
        padding: '2px 8px',
        borderRadius: '40px'
    },
    gridBox: {
        border: '1px solid #CBD5E1',
        borderRadius: '16px',
        background: '#FCFDFE',
        padding: '24px'
    },
    uploadedText: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'left' as 'left',
        marginBottom: '16px'
    },
    fileBox: {
        boxShadow: '0px 2px 8px 0px #00000014',
        border: '1px solid #E2E8F0',
        borderRadius: '14px',
        backgroundColor: '#fff',
        padding: '16px',
        marginBottom: '16px'
    },
    files: {
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        alignContent: 'center'
    },
    fileCount: {
        color: '#237182',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        alignContent: 'center'
    },
    boxLayout: {
        border: '1px solid #CBD5E1',
        padding: '10px 8px',
        backgroundColor: '#FCFDFE',
        borderRadius: '8px',
        color: '#94A3B8',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left' as 'left',
        marginBottom: '24px'
    },
    fieldTitle: {
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left' as 'left',
        marginBottom: '2px'
    },
    subFiles: {
        boxShadow: '0px 2px 8px 0px #00000014',
        padding: '6px 4px',
        borderRadius: '8px',
        display: 'flex',
        marginTop: '16px',
        justifyContent: 'space-between'
    },
    subFileName: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left' as 'left'
    },
    fileSize: {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'left' as 'left'
    },
    downloadText: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        marginLeft: '8px'
    },
    fileIcon: {
        background: '#E0EDF0', padding: '6.4px', borderRadius: '6.4px', height: '24px'
    },
    resubmitButton: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        background: '#237182',
        borderRadius: '8px',
        padding: '10px 16px 10px 16px',
        textTransform: 'none' as 'none'
    },
    errormsg: {
        color: '#DC2626',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left' as 'left',
        marginTop: '16px',
        fontFamily: 'Poppins'
    }
}
const CustomdropdownSelect = styled(Select)({
      borderRadius: '8px',
      fontFamily: "'Poppins', sans-serif",
      "& .MuiSelect-select:focus": {
        backgroundColor: "white",
        borderRadius: "8px",

      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: '8px',
        "& fieldset": {
            border: "1px solid #CBD5E1 !important",
        },
        "&:hover fieldset": {
            border: "1px solid #CBD5E1 !important",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid #CBD5E1 !important",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1 !important",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1 !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1 !important",
      },
      "& .MuiSelect-icon": {
        color: "#94A3B8",
      },
      " & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":{
        border: "1px solid #CBD5E1 !important",
      },

})
export default class SpecialistCostPlan extends SpecialistCostPlanController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        const { country,   } = this.state;
        const states = country
        ? State.getStatesOfCountry(country).map(state => ({
          isoCode: state.isoCode,
          name: state.name
        }))
        : [];
        
        return (
            <ThemeProvider theme={theme}>
                <MyProfileHeader navigation={this.props.navigation} tabName="Specialist">
                    <StyledBox>
                        <PageTitle>
                            <Box style={{ display: 'flex' }}>
                                <Box style={webStyle.backButton} data-test-id="backBtn" onClick={this.goBack.bind(this)}>
                                    <ArrowBackIos />
                                    <BackButton>Back</BackButton>
                                </Box>
                                <Box className="titleBox">
                                    <ProjectTitle>
                                        {this.state.projectName}
                                    </ProjectTitle>

                                </Box>
                            </Box>
                            <Box data-test-Id= "statusText" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <StatusText>Status:</StatusText>
                                {this.state.currentStatus !== 'Rejected' &&
                                    <Typography style={{ ...webStyle.statusType, color: this.state.currentStatus == 'Accepted' ? '#059669' : '#D97706', background: this.state.currentStatus == 'Accepted' ? '#D1FAE5' : '#FEF3C7', textTransform: "uppercase" }}>
                                        {this.state.currentStatus == "Pending"? "pending approval": this.state.currentStatus}</Typography>
                                }
                                {this.state.currentStatus == 'Rejected' &&
                                    <>
                                        <Typography style={{ ...webStyle.statusType, color: '#DC2626', background: '#FEE2E2', textTransform: "uppercase" }}>
                                            {this.state.currentStatus}</Typography>
                                            <ResubmitButton 
                                                data-test-id="handleResubmit" 
                                                onClick={this.handleSubmit} 
                                                disabled={this.isSubmitDisabled()}
                                                >
                                                Resubmit
                                            </ResubmitButton>
                                    </>
                                }
                            </Box>
                        </PageTitle>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box style={webStyle.gridBox}>
                                    <Typography style={webStyle.uploadedText}>Uploaded Files</Typography>
                                    {this.state.uploadedFiles?.map((item, index) => (
                                        <Box style={{ ...webStyle.fileBox, marginBottom: index == this.state.uploadedFiles.length - 1 ? 'unset' : '16px' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <Box style={{ display: 'flex', gap: '8px' }}>
                                                    <Box style={webStyle.fileIcon}>
                                                        <FolderOpenIcon style={{ color: '#237182' }} />
                                                    </Box>
                                                    <FileName>{item.section_title} {!item.mandatory && <span>(Optional)</span>}</FileName>
                                                </Box>
                                                <Box style={{ display: 'flex', gap: '8px' }}>
                                                    {this.state.currentStatus == 'Rejected' &&
                                                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }} data-test-id='handleFileUploadClick'
                                                            onClick={() => this.handleFileUploadClick(item.section_id)}>
                                                            <FileUploadOutlinedIcon style={{ color: '#237182' }} />
                                                            <UploadText>Upload Files</UploadText>
                                                        </div>
                                                    }
                                                    <input
                                                        type="file"
                                                        ref={this.fileInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        data-test-id="handleFileChange"
                                                        onChange={this.handleFileChange}
                                                    />
                                                    <Typography style={webStyle.files}>Files: <span style={webStyle.fileCount}>{item.filesCount}</span></Typography>
                                                    <Box style={{ alignContent: 'center' }} data-test-id="toggleExpand" 
                                                    
                                                    onClick={() => this.toggleExpand(index)}>
                                                         {this.state.expandedIndex === index ? (
                                                        <KeyboardArrowUp style={{ color: '#475569' }} />
                                                        ) : (
                                                            <KeyboardArrowDown style={{ color: '#475569' }} />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {this.state.expandedIndex === index && (
                                                <Box>
                                                    {item.mandatory && item.files.length == 0 &&
                                                        <Typography style={webStyle.errormsg}>At least one file needs to be uploaded for resubmission. Make sure the folder is not left empty.</Typography>
                                                    }
                                                    {item.files?.map((element, index) => (
                                                        <Box style={webStyle.subFiles}>
                                                            <Box style={{ display: 'flex', gap: '12px' }}>
                                                                <img src={pdfIcon} style={{ height: '38px' }} />
                                                                <Box>
                                                                    <Typography style={webStyle.subFileName}>{element.file_name}</Typography>
                                                                    <Typography style={webStyle.fileSize}>{element.size}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                data-test-id="handleMenuOpen"
                                                                style={{ cursor: "pointer", background: this.state.menuItemIndex === index && this.state.menuAnchor ? '#E0EDF0' : 'none', borderRadius: '50%', padding: '4px', height: '25px' }}
                                                                onClick={(event: any) => this.handleMenuOpen(event, index,element)}
                                                            >
                                                                <MoreVertIcon style={{ color: '#475569' }} />
                                                            </Box>
                                                            <MenuDropdown
                                                                anchorEl={this.state.menuAnchor}
                                                                open={Boolean(this.state.menuAnchor)}
                                                                data-test-id='handleMenuClose'
                                                                onClose={this.handleMenuClose}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "right",
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: "top",
                                                                    horizontal: "right",
                                                                }}
                                                                PaperProps={{
                                                                    style: {
                                                                        marginTop: "46px",
                                                                        padding: '1px 4px',
                                                                        borderRadius: '8px',
                                                                        width: '184px'
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem style={{ background: '#E0EDF0', borderRadius: '8px', cursor: 'pointer' }}
                                                                data-test-id="handleDownload"
                                                                    onClick={this.handleDownloadfile.bind(this)}
                                                                >
                                                                    <SaveAltIcon />
                                                                    <Typography style={webStyle.downloadText}>Download</Typography>
                                                                </MenuItem>
                                                                {this.state.currentStatus == 'Rejected' &&
                                                                    <MenuItem style={{ cursor: 'pointer' }}
                                                                        onClick={() => this.deleteFile(element.file_id)}
                                                                        data-test-id="deleteFile"
                                                                    >
                                                                        <DeleteOutline style={{ color: '#DC2626' }} />
                                                                        <Typography style={{ ...webStyle.downloadText, color: '#DC2626' }}>Delete</Typography>
                                                                    </MenuItem>
                                                                }
                                                            </MenuDropdown>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    ))}

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                            {this.state.currentStatus == 'Rejected' ?
                             <Box style={{ ...webStyle.gridBox, border: 'none' }}>
                             <Typography style={webStyle.uploadedText}>Project Information</Typography>
                             <form onSubmit={this.handleSubmit}>

                             <Typography style={webStyle.fieldTitle}>Project Name *</Typography>
                             <InputGroup>
                               <Box style={{position:"relative"}}>
                                 <input type="text" name="projectName"
                                  style={{...StyleSheet1.InputText1, backgroundColor:"rgba(255, 255, 255, 1)", border:"1px solid #CBD5E1",fontSize: "14px",
                                       fontWeight: 400}}
                                       value={this.state.projectName}
                                       data-test-id="txtInputEmail"
                                      onChange={this.handleInputChange}
                                    placeholder="Project Name"
                                    />
                                </Box>
                            </InputGroup>
                             <Typography style={{ ...webStyle.uploadedText, marginTop: '24px' }}>Address</Typography>
                             <Typography style={webStyle.fieldTitle}>Street Name *</Typography>
                             <InputGroup>
                               <Box style={{position:"relative"}}>
                                 <input type="text" name="streetName" 
                                  style={{...StyleSheet1.InputText1, backgroundColor:"rgba(255, 255, 255, 1)", border:"1px solid #CBD5E1",fontSize: "14px",
                                       fontWeight: 400}}
                                       value={this.state.streetName}
                                       data-test-id="txtInputEmail"
                                      onChange={this.handleInputChange}
                                    placeholder="Street Name"
                                    />
                                </Box>
                            </InputGroup>
                             <Grid container spacing={1}>
                                 <Grid item xs={12} sm={6}>
                                     <Typography style={webStyle.fieldTitle}>City *</Typography>
                                     <InputGroup>
                               <Box style={{position:"relative"}}>
                                 <input type="text" name="city" 
                                  style={{...StyleSheet1.InputText1, backgroundColor:"rgba(252, 253, 254, 1)", border:"1px solid #CBD5E1",fontSize: "14px",
                                       fontWeight: 400}}
                                       value={this.state.city}
                                       data-test-id="txtInputEmail"
                                      onChange={this.handleInputChange}
                                    placeholder="City"
                                    />
                                </Box>
                            </InputGroup>
                                 </Grid>
                                 <Grid item xs={12} sm={6}>
                                     <Typography style={webStyle.fieldTitle}>Post code *</Typography>
                                     <InputGroup>
                               <Box style={{position:"relative"}}>
                                 <input type="text" name="postCode" 
                                  style={{...StyleSheet1.InputText1, backgroundColor:"rgba(252, 253, 254, 1)", border:"1px solid #CBD5E1",fontSize: "14px",
                                       fontWeight: 400}}
                                       value={this.state.postCode}
                                       data-test-id="txtInputEmail"
                                      onChange={this.handleInputChange}
                                    placeholder="Post code"
                                    />
                                </Box>
                            </InputGroup>
                                 </Grid>
                             </Grid>
                             <Grid container spacing={1}>
                            
                                 <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.fieldTitle}>State *</Typography>
                      <CustomdropdownSelect
                        style={{
                          height: "56px",
                          color: this.state.state ? "#000000" : "#94A3B8",
                          backgroundColor: this.state.state ? "#FFFFFF" : "#FCFDFE",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                          textAlign:"justify"
                        }}
                        placeholder="Enter state"
                        variant="outlined"
                        name="state"
                        data-test-id="txtInputState"
                        value={this.state.state}
                        onChange={this.handleStateChange}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              maxHeight: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select state</MenuItem>
                        {  
                        states.length === 0 ?
                          <MenuItem  value={'other'}>
                              <Typography>
                                Other
                              </Typography>
                            </MenuItem> :
                          states.map((state, index) => (
                            <MenuItem key={index} value={state.isoCode}>
                              {state.name}
                            </MenuItem>
                          ))}
                      </CustomdropdownSelect>
                                 </Grid>
                              
                                 <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.fieldTitle}>Country *</Typography>
                      <CustomdropdownSelect
                        style={{
                          height: "56px",
                          color: this.state.country ? "#000000" : "#94A3B8",
                          backgroundColor: this.state.country ? "#FFFFFF" : "#FCFDFE",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                          textAlign:"justify"
                        }}
                        value={this.state.country}
                        onChange={(event) => this.handleCountryChange(event)}
                        placeholder="Select Country"
                        variant="outlined"
                        name="country"
                        data-test-id="txtInputCountry"
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              height: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select country</MenuItem>
                        {Country.getAllCountries().map((country, index) => (
                          <MenuItem key={index} value={country.isoCode}>
                            {country.name}
                          </MenuItem>
                        ))}

                      </CustomdropdownSelect>
                                 </Grid>

                             </Grid>
        </form>
                         </Box>
                             :   <Box style={{ ...webStyle.gridBox, border: 'none' }}>
                                    <Typography style={webStyle.uploadedText}>Project Information</Typography>
                                    <Typography style={webStyle.fieldTitle}>Project Name *</Typography>
                                    <Box style={webStyle.boxLayout}>{this.state.projectName}</Box>
                                    <Typography style={{ ...webStyle.uploadedText, marginTop: '24px' }}>Address</Typography>
                                    <Typography style={webStyle.fieldTitle}>Street Name *</Typography>
                                    <Box style={webStyle.boxLayout}>{this.state.streetName} </Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.fieldTitle}>City *</Typography>
                                            <Box style={webStyle.boxLayout}>{this.state.city} </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.fieldTitle}>Post code *</Typography>
                                            <Box style={webStyle.boxLayout}>{this.state.postCode} </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.fieldTitle}>State *</Typography>
                                            <CustomdropdownSelect
                                        style={{
                                          height: "48px",
                                          borderRadius: "8px",
                                          fontFamily: "Poppins, sans-serif",
                                          width: "100%",
                                          textAlign:"justify",
                                          backgroundColor:  "#FFFFFF",
                                          color:"rgb(148, 163, 184)",
                                          
                                        }}
                        placeholder="Enter state"
                        name="state"
                        data-test-id="txtInputState"
                        variant="outlined"
                        value={this.state.state}
                        onChange={this.handleStateChange}
                        displayEmpty
                        disabled
                        MenuProps={{
                          anchorOrigin: {
                            horizontal: "left",
                            vertical: "bottom",
                          },
                          transformOrigin: {
                            horizontal: "left",
                            vertical: "top",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              width: "20px",
                              right: "500px",
                              position: "fixed",
                              maxHeight: "300px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select state</MenuItem>
                        {  
                        states.length === 0 ?
                          <MenuItem  value={'other'}>
                              <Typography>
                                Other
                              </Typography>
                            </MenuItem> :
                                 states.map((state, index) => (
                            <MenuItem key={index} value={state.isoCode}>  {state.name} </MenuItem>
                          ))
                          }
                      </CustomdropdownSelect>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.fieldTitle}>Country *</Typography>
                                            <CustomdropdownSelect
                        style={{
                          textAlign:"justify",
                          height: "48px",
                          backgroundColor: "#FFFFFF" ,
                          fontFamily: "Poppins, sans-serif",
                          borderRadius: "8px",
                          color:  "rgb(148, 163, 184)" ,
                          width: "100%",
                        }}
                        value={this.state.country}
                        onChange={this.handleCountryChange.bind(this)}
                        variant="outlined"
                        placeholder="Select Country"
                        data-test-id="txtInputCountry"
                        name="country"
                        displayEmpty
                        disabled
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              height: "300px",
                              width: "20px",
                              position: "fixed",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select country</MenuItem>
                        {Country.getAllCountries().map((country, index) => (
                          <MenuItem key={index} value={country.isoCode}>  {country.name}</MenuItem>
                        ))}

                      </CustomdropdownSelect>
                                        </Grid>
                                    </Grid>
                                </Box>
                            
                          }

                            </Grid>
                        </Grid>
                    </StyledBox>
                </MyProfileHeader>
            </ThemeProvider>
        );
    }
}
const InputGroup = styled("div")({
    marginBottom: "15px",
    width: "100%",
  })
  const StyleSheet1 = {
    InputText1: {
        display: "block",
        padding: "10px 8px 10px 8px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        width: "100%",
        height: "56px",
        marginTop: "4px",
        fontFamily: "Poppins",
        outline: "none"
      },
  }
 
// Customisable Area End
