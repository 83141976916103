import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Task, ViewMode } from "gantt-task-react";
import { Alert } from "react-native";
import { NavigationStackProp } from 'react-navigation-stack';
import moment from "moment";
import { TaskType } from "gantt-task-react/dist/types/public-types";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

interface APIData {
  attributes: {
      project_start_date: string,
      project_end_date: string,
      task_name: TaskType,
      percentage: number
  },
  id: string,
  type: TaskType
}
interface TaskList {
  id: string,
  type: string,
  attributes: {
    id: number,
    account_id: string,
    name:string,
}        
}

interface RoomList {
  id: string,
  type:string,
  attributes: {
    id: number,
    room_id: number,
    bx_block_profile_project_id: number,
    room_name:string
  }
}
interface PriorityList {
  id:string,
  Priority_name:string,
  PriorityBackgroundColor: string,
  PriorityColor: string, 
}
// Customizable Area End

export const configJSON = require("./config");
export const ganttData = require("./constants.json");


export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ganttChartView: ViewMode;
  ganttChartTasks: Task[];
  ganttChartTasksMobile: Task[];
  isChecked: boolean;
  ganttChartColumnWidth: number;
  minGrid: string;
  maxGrid: string;
  activeTab:any;
  listView:string;
  events:any;
  initialDate:any;
  monthName:any;
  Quarter1:any;
  Quarter2:any;
  Quarter3:any;
  Quarter4:any;
  cuurentQuarter:any;
  updatedProfile:{status:boolean,value:string};
  isDropdownOpen:boolean;
  anchorEl:any;
  selectedText:string;
  showAll:boolean;
  taskListValues:any;
  showComment:boolean;
  commentListAll:any;
  attachmentList:any;
  showAttachments:boolean;
  isTaskListDetailOpen:boolean;
  selectedTaskListID:any;
  currentRoomIDValue:any;
  commenttext:any;
  anchorElStatus:any;
  selectedStatus :string;
  statuses:string[];
  anchorElPriority:any;
  selectedPriority:any;
  priorities:string[];
  toggleID:any;
  commentPopUpState:any;
  anchorElPriorityUpload:any;
  commentDeletevalue:any;
  roomList:RoomList[];
  isRoomSelected:string[];
  taskList:TaskList[];
  isTaskListSelectedFilter:string[],
  isPriorityList:PriorityList[];
  isPrioritySelected:[],
  isRoomOpen:boolean;
  roomTaskList:any;
  isRoomClicked:boolean;
  uploadFilePopUp:boolean;
  fileMetadata:any;
  file:any;
  errorMessage:any;
  fileSizeError:any;
  fileUrl:any;
  projectID:any;
  allTaskLists:any;
  taskLists:any;
  selectedRoomWidth:any;
  selectedRoomHeight:any;
  selectedRoomLength:any;
  selectedRoomStartDate:any;
  selectedRoomEndDate:any;
  selectedRoomTasklist:any;
  loader:boolean;
  selectedTasklistName:string;
  projectDetails:any;
  projectId:any;
  currentDate: any;
  lineHeight: string;
  screenWidth: any;
  selectedRoomName:any;
  selectedTasklistStatus:string;
  selectedTasklistPriority:string;
  selectedRoomID:any;
  isAssignMemberModalOpen:boolean;
  isRoomTeamSeleted:boolean;
  memberError:string;
  selectedUsers:any;
  users:any;
  accumulatedRoomData:any;
  isCommentAttatchment:boolean;
  comment_attachmentList:any;
  addedMembers:any;
  getAllMembers:any;
  searchText:any;
  selectedMembers:any;
  searchFieldError:any;
  customTextEntries:any;
  showSuggestions:any;
  statusError:any;
  openConfirmDeleteModal:boolean;
  openError:boolean;
  isSuccess:boolean;
  // Customizable Area End
}

interface SS {
  id: string
  // Customizable Area Start
  name: string;
  progress: number;
  start: Date;
  end: Date;
  // Customizable Area End
}

export default class GanttChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  taskListsValueId:string=''
  getAllCommentListValueId:string=''
  getAttachmentListValue:string=''
  gettaskListIdValue:string=''
  getTaskListCallId:string=''
  getProjectMembersCallId:string=''
  getRoomListCallId:string=''
  getProjectTaskListCallId:string='';
  gettasklistID:string='';
  taskListRequests: any;
  getRoomDetailId:any;
  getHitPostCommentApis:any;
  uploadFileID:string="";
  apiProjectDetailsId:any;
  getUploadDeleteAPIs:any;
  changeTaskListStatusCallId:any;
  changeTaskListPriorityCallId:any;
  apiGetMembersListCallId:string='';
  apiAddMemberCallId:any;
  postAssignMemberCallId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ganttChartView: ViewMode.Day,
      ganttChartTasks: ganttData,
      ganttChartTasksMobile: ganttData,
      isChecked: true,
      ganttChartColumnWidth: 80,
      minGrid: "2023-01-01",
      maxGrid: "2023-03-31",
      activeTab:3,
      listView:"Room View",
      monthName:[{name:"Jan",id:"01"},{name:"Feb",id:"02"},{name:"March",id:"03"},{name:"April",id:"04"},{name:"May",id:"05"},{name:"Jun",id:"06"},{name:"July",id:"07"},{name:"Aug",id:"08"},{name:"Sept",id:"09"},{name:"Oct",id:10},{name:"Nov",id:11},{name:"Dec",id:12}],
      initialDate:new Date(),
      cuurentQuarter:"January",
      Quarter1:[
          {start:"1",name:"Jan"
          },
          {name:"Jan",
            start:"8"
          },
          { start:"15",
            name:"Jan"
          },
          {
            name:"Jan",
            start:"22"
          },
          {
            name:"Feb",
            start:"1"
          },
          {start:"8",
            name:"Feb"
          },
          {
            name:"Feb",start:"15"
          },
          {
            name:"Feb",
            start:"22"
          },
          {
            start:"1",name:"Mar"
          },
          {
            name:"Mar",
            start:"8"
          },
          {
            start:"15",  name:"Mar"          
          },
          {
            name:"Mar",start:"22"
          }
        ],
      Quarter2:[{name:"April",start:"1"},
          {
            name:"April",start:"8"
          },
          {
            name:"April", start:"15"
          },
          { name:"April", start:"22"
          },
          {
            name:"May",
            start:"1"
          },
          {name:"May", start:"8"
          },
          {
            name:"May",start:"15"
          },
          {
            name:"May",
            start:"22"
          },
          {name:"June", start:"1"
          },
          {
            name:"June",
            start:"8"
          },
          {
            name:"June",
            start:"15"
          },
          {
            name:"June",
            start:"22"
          }
        ],
      Quarter3:[
          {
            name:"July",
            start:"1"
          },{
            name:"July",
            start:"8"},
          {name:"July",start:"15"},
          {
            name:"July",
            start:"22"
          },
          {
            name:"Aug",
            start:"1"
          },
          {
            name:"Aug",
            start:"8"
          },
          {
            name:"Aug",
            start:"15"
          },
          {  name:"Aug",  start:"22"},
          { name:"Sept", start:"1"},
          {
            name:"Sept",
            start:"8"
          },
          {name:"Sept",start:"15"},
          {
            name:"Sept",
            start:"22"
          }
        ],
      Quarter4:[
          { name:"Oct",start:"1"},
          { name:"Oct", start:"8"},
          {name:"Oct", start:"15"},
          {
            name:"Oct",
            start:"22"
          },
          {
            name:"Nov",
            start:"1"
          },
          {name:"Nov", start:"8"},
          {name:"Nov", start:"15"},
          {name:"Nov", start:"22"},
          {
            name:"Dec",
            start:"1"
          },
          {name:"Dec", start:"8"},
          {name:"Dec", start:"15"
          },
          {
            name:"Dec",
            start:"22"
          }    
      ],
      events:[ ],
      updatedProfile:{status:true,value:""},
      isDropdownOpen:false,
      anchorEl:null,
      selectedText:'Room View',
      showAll:false,
      taskListValues:[
        {
          id: 1,
          attributes: {
            task_list_name: "Foundation Work",
            specifications: [
              {
                specification_name: "Concrete Mix",
                Quantity: 10,
                unit_total: 50.75,
                labour_cost: 20.5,
                material_cost: 30.25,
                total_cost: 100.00,
              },
            ],
          },
        },
        {
          id: 2,
          attributes: {
            task_list_name: "Bricklaying",
            specifications: [
              {
                specification_name: "Red Bricks",
                Quantity: 500,
                unit_total: 1.5,
                labour_cost: 0.75,
                material_cost: 0.75,
                total_cost: 750.00,
              },
            ],
          },
        },
        {
          id: 3,
          attributes: {
            task_list_name: "Plastering",
            specifications: [
              {
                specification_name: "Cement Plaster",
                Quantity: 20,
                unit_total: 10.0,
                labour_cost: 5.0,
                material_cost: 5.0,
                total_cost: 200.00,
              },
            ],
          },
        },
      ],
      showComment:false,
      commentListAll:[],
      attachmentList:[ {
        id: 1,
        attributes: {
          content_type: "PDF Document",
          file_name: "Project_Plan.pdf",
          file_url: "/Project_Plan.pdf",
        },
      },
      {
        id: 2,
        attributes: {
          content_type: "Excel Spreadsheet",
          file_name: "Budget_Estimate.xlsx",
          file_url: "Budget_Estimate.xlsx",
        },
      },],
      showAttachments:false,
      isTaskListDetailOpen:false,
      selectedTaskListID:'',
      currentRoomIDValue:'',
      commenttext:'',
      anchorElStatus:'',
      selectedStatus:"To Do",
      statuses:["To Do", "In Progress", "Completed", "Blocked", "Archived"],
      anchorElPriority:'',
      selectedPriority:'Mid',
      priorities: ["Low", "Mid", "High"],
      toggleID:'',
      commentPopUpState:false,
      anchorElPriorityUpload:null,
      commentDeletevalue:'',
      roomList:[],
      isRoomSelected:[],
      uploadFilePopUp:false,
      taskList:[],
      isTaskListSelectedFilter:[],
      isPriorityList: [
        {
          id: "1",
          Priority_name: "Low",
          PriorityBackgroundColor: "#DBEAFE",
          PriorityColor: "#2563EB",     
        },
        {
          id: "2",
          Priority_name: "Mid",
          PriorityBackgroundColor: "#FEF3C7", 
          PriorityColor: "#D97706",          
        },
        {
          id: "3",
          Priority_name: "High",
          PriorityBackgroundColor: "#FEE2E2", 
          PriorityColor: "#DC2626",        
        }
      ],
      isPrioritySelected:[],
      isRoomOpen:false,
      roomTaskList:['Tasklist 1: Install Steel Beams ','Tasklist 1: Install Steel Beams ','Tasklist 1: Install Steel Beams ','Tasklist 1: Install Steel Beams '],
      isRoomClicked:false,
      fileMetadata:'',
      file:'',
      errorMessage:'',
      fileSizeError:'',
      fileUrl:'',
      projectID:'',
      allTaskLists:[],
      taskLists:[],
      selectedRoomWidth:'',
      selectedRoomHeight:'',
      selectedRoomLength:'',
      selectedRoomStartDate:'',
      selectedRoomEndDate:'',
      selectedRoomTasklist:[],
      loader:false,
      selectedTasklistName:'',
      projectDetails:'',
      projectId:'',
      currentDate: new Date(),
      lineHeight: "100vh",
      screenWidth: window.innerWidth,
      selectedRoomName:'',
      selectedTasklistStatus:'',
      selectedTasklistPriority:'',
      selectedRoomID:'',
      isAssignMemberModalOpen:false,
      isRoomTeamSeleted:false,
      memberError:'',
      selectedUsers:'',
      users:'',
      accumulatedRoomData:[],
      isCommentAttatchment:false,
      comment_attachmentList:[],
      addedMembers:[],
      getAllMembers:[],
      searchText:'',
      selectedMembers:[],
      searchFieldError:'',
      customTextEntries:[],
      showSuggestions:true,
      statusError:'',
      openConfirmDeleteModal:false,
      openError:false,
      isSuccess:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    window.addEventListener("resize", this.updateScreenWidth);
    this.getWaterFallData();
    this.getProjectId()
    this.getProjectDetails()
    this.getRoomListApi()
    this.getProjectsMembers()
    this.handleGetAllMemberList()
  }
  async componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }
  updateScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id == getName(MessageEnum.RestAPIResponceMessage)) {     
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );   
      console.log('sdfsdfsdfdfsdfsd',this.apiGetMembersListCallId,'-----',apiRequestCallId)
      if(apiRequestCallId==this.apiGetMembersListCallId)
      {
        this.getMembersListResponse(responseJson);
        console.log('dsfioeopirpoweirpoweirpwoeri',responseJson)
      }if(apiRequestCallId==this.apiAddMemberCallId){
        this.addMemberResponse(responseJson);
      }
      if(apiRequestCallId==this.changeTaskListPriorityCallId){
        console.log('ertretert',responseJson)
        if(responseJson.error){
          this.setState({statusError:responseJson.error,openError:true,isSuccess:false})
          this.timmer()
        }
        this.handleSelectedTaskList(this.state.selectedTaskListID)
      }
      if(responseJson?.uploaded_files?.length>0 || responseJson?.uploaded_attachments?.length>0){
        this.setState({ loader: false,isCommentAttatchment:false });
        this.setState({openError:true,statusError:'File uploaded sucessfully',isSuccess:true})
        this.timmer()
            this.handleSuccedMessage();
      }
      if(responseJson?.message=='file deleted sucessfully!'){
        this.setState({openError:true,statusError:'File deleted sucessfully',isSuccess:true})
        this.timmer()
        this.closeDeleteModal()
       this.handleAttachmentList()
      }
      if(responseJson?.members?.length>0 && responseJson?.teams){
        console.log('tyuytutu',responseJson)
        this.handleGetProjectMembersResponse(responseJson)
      }
      if(apiRequestCallId==this.postAssignMemberCallId){
        this.addMemberResponse(responseJson)
      }
      if(apiRequestCallId==this.changeTaskListStatusCallId){
        if(responseJson.message=='Successfully updated task lists.') {
          this.setState({selectedTasklistStatus:responseJson.updated_task_lists[0].status})
        }
        if(responseJson.error=='No task lists were updated.'){
          this.setState({statusError:`Some task lists can't be completed as not all tasks are complete`,openError:true,isSuccess:false})
          this.timmer()
        }
        this.setState({anchorElStatus:null})
        this.handleSelectedTaskList(this.state.selectedTaskListID)
      }
      if (apiRequestCallId != null) {
        this.setState({ganttChartTasks: this.responseFormatter(responseJson?.data, 'web')})
        this.setState({ganttChartTasksMobile: this.responseFormatter(responseJson?.data, 'mob')})
      }
      
    if (apiRequestCallId == this.getRoomListCallId) {
      const roomData = responseJson.data.data;
      const totalPages = responseJson.total_page;
      const currentPage = responseJson.current_page;
  
      const accumulatedData: any = [...this.state.accumulatedRoomData, ...roomData];
  
      const roomCountMap: Record<string, number> = {};
  
      const updatedData = accumulatedData.map((room: any) => {
          const baseName = room.attributes.room_name || "Room";
          
          if (roomCountMap[baseName] === undefined) {
              roomCountMap[baseName] = 0; 
          } else {
              roomCountMap[baseName] += 1;
              room.attributes.room_name = `${baseName} ${roomCountMap[baseName]}`;
          }
  
          return room;
      });
  
      if (currentPage < totalPages) {
          this.getRoomListApi(currentPage + 1, updatedData);
      } else {
          this.setState({ events: updatedData, accumulatedRoomData: [] });
          this.getAllTaskListsCall();
      }
  }
  
     
       if (apiRequestCallId === this.getRoomDetailId) {
        const taskListData = responseJson.data.map((item: { attributes: { id: any; task_list_name: any;status:any;priority:any ,task_list_members:any}; }) => ({
            id: item?.attributes?.id,
            task_list_name: item?.attributes?.task_list_name,
            priority: item?.attributes?.priority,
            status: item?.attributes?.status,
            task_list_members:item?.attributes?.task_list_members
        }));
    
        this.setState({ selectedRoomTasklist: taskListData });
    }
      this.handleAPIResponse(apiRequestCallId,responseJson)
      if(apiRequestCallId==this.taskListsValueId){
        this.renderTaskListResponse(responseJson)
      }
      if(apiRequestCallId==this.getAllCommentListValueId){
        this.handleAllComments(responseJson)
      }
      if(apiRequestCallId==this.getAttachmentListValue){
        this.handleAttachmentListStore(responseJson)
      }
      if(apiRequestCallId==this.getHitPostCommentApis){
        this.setState({
          commenttext:""
        })  
        this.handleCommentListAPIs()
      }
      if(apiRequestCallId==this.apiProjectDetailsId){
        this.handleProjectDetailsResponse(responseJson)
      }
      if(apiRequestCallId==this.gettaskListIdValue){
        this.handleSelectedTaskList(this.state.selectedTaskListID)
      }
      
      if(apiRequestCallId==this.getProjectMembersCallId){
        console.log('dsfsdfsdfsd',responseJson)
        this.handleGetProjectMembersResponse(responseJson)
      }
      this.receive1(message);
    }
    // Customizable Area End
  }
  
  responseFormatter = (data: [], platform: string) => {
    let formatedData = [];
    if (data.length > 0) {
      formatedData = data?.map((item: APIData) => ({
          start: new Date(item?.attributes?.project_start_date),
          end: new Date(item?.attributes?.project_end_date),
          name: item?.attributes?.task_name,
          id: item?.id,
          progress: platform === "web" ? item?.attributes?.percentage : item?.attributes?.percentage / 100,
          type: item?.type,
          project: "ProjectSample"
      }))
      const startDates = formatedData.map(response => response.start);
      const endDates = formatedData.map(response => response.end);
      const dates = [...startDates, ...endDates];
  
      // Convert date strings to moment objects
      const momentDates = dates.map(dateString => moment(dateString));
  
      // Find the earliest and latest dates
      const startDate = moment.min(momentDates).subtract(3, 'days');
      const endDate = moment.max(momentDates).add(5, 'days');
  
      this.setState({minGrid: startDate.format('YYYY-MM-DD')});
      this.setState({maxGrid: endDate.format('YYYY-MM-DD')});
    } else {
      formatedData = ganttData;
    }
    
    return formatedData;
  }

getVerticalLinePosition() {
  const { activeTab, currentDate, initialDate, screenWidth } = this.state;
  let dayMultiplier =220, dateMultiplier=20, weekMultiplier=205, monthMultiplier=205;
  
    dayMultiplier = 176;
    dateMultiplier = 36;
    weekMultiplier = 86;
    monthMultiplier = 105
 
  if (activeTab === 0) { 
    const startOfWeek = new Date(initialDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    if (currentDate >= startOfWeek && currentDate <= endOfWeek) {
      const dayIndex = currentDate.getDay();
      return 230 + (dayIndex * dayMultiplier);
    }
  } 
  
  else if (activeTab === 1) { 
    if (initialDate.getFullYear() === currentDate.getFullYear()) {
    if (initialDate.getMonth() === currentDate.getMonth()) {
      const date = currentDate.getDate()-1;
      return 230 + (date*dateMultiplier);
    }
  }
  } 
  
  else if (activeTab === 2) {
    const quarterStartMonth = Math.floor(initialDate.getMonth() / 3) * 3;
    const quarterStartDate = new Date(initialDate.getFullYear(), quarterStartMonth, 1);
    const currentDateObj = new Date(currentDate);
    const weekNumber = Math.ceil((currentDateObj.getTime() - quarterStartDate.getTime()) / (7 * 24 * 60 * 60 * 1000));
    
    if (weekNumber >= 1 && weekNumber <= 13) {
      return 230 + ((weekNumber-1) * weekMultiplier)
    }
  } 
  
  else if (activeTab === 3) {
    if (initialDate.getFullYear() === currentDate.getFullYear()) {
      const month = currentDate.getMonth();
      return  230 +( month*monthMultiplier)
    }
  }

  return "-10px"; 
}

receive1=(message:any)=>{
  console.log('sfdsdfsdfsd')
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
     if(responseJson?.members?.length>0 && !responseJson.teams){
        this.getMembersListResponse(responseJson);
      }
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetMembersListCallId) {
        console.log('retyuuiuouiouio',responseJson)
        this.getMembersListResponse(responseJson);
       
      }
    }

   
  }
}
  getWaterFallData(): boolean {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGanttChartWaterfallData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  
  onGanttChartPress = (text: SS) => {
      let message = `${moment(text?.start).format("YYYY-MM-DD")} - ${moment(text.end).format("YYYY-MM-DD")}\nProgress: ${text.progress * 100}%`;
    Alert.alert(text.name, message);
  }

  navigateToDetails = {
    onPress: () => {this.props.navigation.navigate("GanttChartDetails")}
  }

  handleChange = (event:any,newValue:any) => {
    this.setState({
      activeTab:newValue
    });
  };

  handleChangeSelect = (event:any) => {
    this.setState({
      listView:event,
      anchorEl:null,
      isDropdownOpen:false
    });
  };

  handleNextWeek =()=>{
    if(this.state.activeTab === 0){
      const NextWeek = moment(this.state.initialDate).add(1,'w').toDate()
      this.setState({
        initialDate:NextWeek
      })
    }
    if (this.state.activeTab === 1){
      const NextWeek = moment(this.state.initialDate).add(1,'months').toDate()
      this.setState({
        initialDate:NextWeek
      })
    }
    if (this.state.activeTab === 2){
      if(this.state.initialDate.getMonth()){
        const NextWeek = moment(this.state.initialDate).add(3,'months').toDate()
        this.setState({
          initialDate:NextWeek
        })
      }
    }
    if(this.state.activeTab === 3){
      const YearCalendar = moment(this.state.initialDate).add(1,'years').toDate()
      this.setState({
        initialDate:YearCalendar
      })
    }
  }

  handlePreviousWeek = () => {
    if(this.state.activeTab === 0){
      const previousWeek = moment(this.state.initialDate).subtract(1,'w').toDate()
    this.setState({
      initialDate:previousWeek
    })
    }
    if(this.state.activeTab === 1){
      const previousWeek = moment(this.state.initialDate).subtract(1,'months').toDate()
      this.setState({
        initialDate:previousWeek
      })
    }
    if (this.state.activeTab === 2){
      if(this.state.initialDate.getMonth()){
        const NextWeek = moment(this.state.initialDate).subtract(3,'months').toDate()
        this.setState({
          initialDate:NextWeek
        })
      }
    }
    if(this.state.activeTab === 3){
      const YearCalendar = moment(this.state.initialDate).subtract(1,'years').toDate()
      this.setState({
        initialDate:YearCalendar
      })
    }
   
  };

  handleQuarterWeeks =()=>{
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const monthStarting = months[this.state.initialDate.getMonth()]

    return monthStarting
  }

  toggleDropdown = (event: any) => {
    this.setState({
        isDropdownOpen: !this.state.isDropdownOpen,
        anchorEl: event.currentTarget,
    });
  };
  handleTaskListDetailCard = (id:any,roomId:any) =>{
    this.setState((prev) => ({
      isTaskListDetailOpen: !prev.isTaskListDetailOpen,
      isRoomClicked:false,
      isRoomOpen:false,
      selectedTaskListID:id,
      currentRoomIDValue:roomId,
      selectedRoomID:roomId
    }));
    this.getAllTaskListsCall()
    this.handleSelectedTaskList(id)
    this.handleAttachmentList()
    this.handleCommentListAPIs()
  }
  handleAttachmentListStore = (value: any) => {
    const dataStore = value.data?.data || [];
  
    const attachmentList = dataStore.filter((item: any) => !item.attributes.is_attachment);
    const comment_attachmentList = dataStore.filter((item: any) => item.attributes.is_attachment);
  
    this.setState({
      attachmentList,
      comment_attachmentList
    });

    console.log('sretreterter',comment_attachmentList)
  };
  
  handleAttachmentList=async()=>{
    const token = await getStorageData("token");
    const header = {
       "Content-Type": configJSON.validationApiContentType,  token: token,
    };
    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage) );
    this.getAttachmentListValue = requestMessage.messageId;
    requestMessage.addData(   getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.attachmentListValues}/${this.state.selectedTaskListID}`);
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
         `${configJSON.validationApiMethodType}`);  
    runEngine.sendMessage(requestMessage.id, requestMessage
    );
  }
  handleAllComments=(value:any)=>{
    const outPutValue  =value.data?.data;
    this.setState({
     commentListAll:outPutValue ? outPutValue : []
    })
 }
  handleCommentListAPIs=async()=>{
    const token = await getStorageData("token");
    const header = { "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllCommentListValueId = requestMessage.messageId;
    
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentListAPIs}${this.state.selectedTaskListID}`);
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.validationApiMethodType}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage
    );
  }
  renderTaskListResponse=(value:any)=>{
    const responseValue = value.data?.data;
    this.setState({taskListValues:responseValue ? responseValue : []
    })
  }
  handleSelectedTaskList=async(idValue:any)=>{
    const token =  await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    this.taskListsValueId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.kanboardTaskListAPIs}/${idValue}`);
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),  `${configJSON.validationApiMethodType}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleMenuClickDetail = (event:any) => {
    this.setState({
      anchorElStatus:event.currentTarget
    })
  };

getStatusBackgroundColorDetail = (status:string) => {
  switch (status) {
    case "To Do":
      return "#3B82F6";
    case "In Progress":
      return "#FBBF24";
    case "Completed":
      return "#34D399";
    case "Blocked":
      return "#F87171";
    case "Archived":
      return "#64748B";
    default:
      return "transparent";
  }
};
handleClose = () => {
  this.setState({
    anchorElStatus:null,
    anchorElPriority:null
  })
};
handleMenuItemClickDetail = (status:string) => {
  this.setState({
    anchorElStatus:null,
    selectedTasklistStatus:status
  })
};
toggleShowAllTasks = () => {
  this.setState(prevState => ({
    showAll: !prevState.showAll,
    showAttachments:false,  showComment:false
  }));
}

toggleShowComments=()=>{
  this.setState(prevState => ({
    showAll:false, showAttachments:false,
    showComment: !prevState.showComment,
  }));
}

toggleShowAttachment=()=>{
  this.setState((prevState)=>({
    showAll:false, showComment:false, showAttachments:!prevState.showAttachments
  }))
}
formatDateResponse = (dateString:any) => {
  const date = new Date(dateString);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  return `${formattedDate} ${formattedTime}`;
};
entersend=(e:any)=>{
  {
    if (e.key === "Enter" && this.state.commenttext.trim().length > 0) {
      this.postCommentAPIs();
    }
  }
}
postCommentAPIs=async()=>{
  const token = await getStorageData("token");
  const payload ={
    "project_tasklist_id":this.state.selectedTaskListID,
    "tasklist_comment":{
      "comment":`${this.state.commenttext}` }
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType, token: token,
  };

  const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getHitPostCommentApis = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.commentsPostAPis}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage), `${configJSON.httpPostMethod}`
  );

  requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
   
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
  
}

  handlePriorityClick = (event:any) => {
    this.setState({
      anchorElPriority:event.currentTarget
    })
  };
  getPriorityBackgroundColor = (priority:string) => {
    switch (priority) {
      case "Low":
        case "low":
        return "#DBEAFE";
      case "Mid" :
        case "mid" :
      case "medium":
        return "#FEF3C7";
      case "High":
        case "high":
        return "#FEE2E2";
      default:
        return "transparent";
    }
  };
  
  getPriorityColorStatus = (priority:string) => {
    switch (priority) {
      case "Low":
        case "low":
        return "#2563EB";
      case "Mid":
        case "mid":
      case "medium":
        return "#D97706";
      case "High":
        case "high":
        return "#DC2626";
      default:
        return "transparent";
    }
  };
  toggleItem = (index:any) => {
    if(this.state.toggleID === index){
      this.setState({
        toggleID:''
      })
    }
    else{
      this.setState({ 
        toggleID:index
      });
    }
  };
 
 truncateText(text:any) {
  if (text.length > 25) {
    return text.slice(0, 25) + '...';
  }
  return text;
} 
handleUploadFileBtn=()=>{
  this.setState((prevState)=>{
    return {
      ...prevState,
      uploadFilePopUp:true
    }
  })
}
handlePopUpOpen=(e:any,val:any)=>{
  this.setState({ anchorElPriorityUpload:e.currentTarget, commentDeletevalue:val?.attributes?.blob_id,commentPopUpState:true })
}
downloadFile = (fileUrl:any) => {
  this.setState({anchorElPriorityUpload:null})
  const link = document.createElement("a");link.href = fileUrl;
  link.download = fileUrl.split("/").pop();  document.body.appendChild(link);
  link.click(); document.body.removeChild(link);
};
deleteFile=async ()=>{
  this.setState({ anchorElPriorityUpload:null
  })
  const token = await getStorageData("token");

  const header = { "Content-Type": configJSON.validationApiContentType, token: token,
  };

  const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUploadDeleteAPIs = requestMessage.messageId;
  
  requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteCommentApis}${this.state.commentDeletevalue}`);


  requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpsDeleteMethod}`
  );

  runEngine.sendMessage(requestMessage.id, requestMessage
  );

}

handleCheckStatus =async(id:any)=>{
  const token = await getStorageData("token");
  const header = {"Content-Type": configJSON.validationApiContentType, token: token,};

  const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
  );

  this.gettaskListIdValue = requestMessage.messageId;
  
  requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.kanbanBoardPutAPIs}/${id}/mark_task_complete`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );


  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), `${configJSON.httpsPutMethod}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );

}
handleTypeComment =(value:any)=>{
  this.setState({
    commenttext:value
  })
}
getRoomListApi = async (page = 1, accumulatedData = []) => {
  const token = await getStorageData("token")
  const header = {
    "Content-Type": "application/json",
    token,
  };
  const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
  this.getRoomListCallId = requestMessage.messageId;
  requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.GetRoomListEndPoint}/${this.state.projectId}?page=${page}`);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({ accumulatedRoomData: accumulatedData });

};
handleAPIResponse = (apiRequestCallId: any, responseJson: { data: any[]; }) => {
  if (this.taskListRequests && this.taskListRequests.includes(apiRequestCallId)) {

    const newTaskLists = responseJson.data.reduce((acc, taskList) => {
      const { room_id, room_details, task_list_name, tasks_count, completed_tasks_count,id,priority,status,task_list_members } = taskList.attributes;
      const { name, start_date, end_date } = room_details;
      const roomID = room_details.id
      let roomEntry = acc.find((entry: { room_id: any; }) => entry.room_id === room_id);

      if (!roomEntry) {
        roomEntry = {
          room_id,
          roomID,
          room: name,
          start_date,
          end_date,
          taskList: [],
        };
        acc.push(roomEntry);
      }

      roomEntry.taskList.push({
        id,
        task_list_name,
        tasks_count,
        completed_tasks_count,
        start_date,
        end_date,
        priority,
        status,
        task_list_members
      });

      return acc;
    }, []);

    this.setState((prevState) => ({
      taskLists: [...prevState.taskLists, ...newTaskLists],
    }));
    this.taskListRequests = this.taskListRequests.filter((id: any) => id !== apiRequestCallId);
  }
};
openConfirmDelete = () => {
  this.setState({openConfirmDeleteModal: true});
};

closeDeleteModal = () => {
  this.setState({openConfirmDeleteModal: false});
}
getAllTaskLists = async (roomId:any) => {
  const token122 = await getStorageData("token");
  const header = {
    "Content-Type": "application/json",
    token: token122,
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (!this.taskListRequests) {
      this.taskListRequests = [];
    }
    
    this.taskListRequests.push(requestMessage.messageId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/project_rooms/${roomId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

};
getAllTaskListsCall=()=>{
  console.log('yyutyutyututuyt')
  const roomData = this.state.events;

  if (!roomData || roomData.length === 0) {
    return;
  }
  for (let room of roomData) {
    let roomId = room.attributes.id;
    this.getAllTaskLists(roomId)
  }
}
getRoomDetail=async (roomId:any)=>{
  const token122 = await getStorageData("token");
  const header = {
    "Content-Type": "application/json",
    token: token122,
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getRoomDetailId=requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/project_rooms/${roomId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseUploadDialog=()=>{
    this.setState((prevState)=>{
      return {
        ...prevState,
        uploadFilePopUp:false,
        file:null,
        fileMetadata:null
      }
    })
  }
  handleRemoveUploadedFile = (fileName: string) => {
    this.setState((prevState) => ({
      fileMetadata: prevState.fileMetadata.filter((file:any) => file.name !== fileName),
      file: prevState.file.filter((file:any) => file.name !== fileName),
    }));
  };
  

  handleUploadFile = async () => {
    this.setState({loader:true})
    const token = await getStorageData("token")
    const header = { token: token,
    };

    let formData = new FormData();
    if(this.state.file?.length > 0){
      this.state.file?.forEach((val:any)=>{
        formData.append("files[]",val)
      })
    }
    if(this.state.isCommentAttatchment){ formData.append("is_attachment",(this.state.isCommentAttatchment).toString()) }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadFileID = requestMessage.messageId;

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/projects/upload_files/${this.state.selectedTaskListID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), header 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), `PUT`
    );

   runEngine.sendMessage(requestMessage.id, requestMessage);
      
};
  handleSuccedMessage=()=>{
    this.setState({
      loader:false,
      uploadFilePopUp:false,
      file:null,
      fileMetadata:null
    })
    this.handleAttachmentList()
  }

  onDrop = (acceptedFiles: File[]) => {
    this.setState((prevState) => {
      const updatedFiles = [...(prevState.file || [])];
      const updatedMetadata = [...(prevState.fileMetadata || [])];
      acceptedFiles.forEach((file) => {
        const fileSizeInMB = file.size / (1024 * 1024);const isFileTooLarge = fileSizeInMB > 10;
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg"]; const isInvalidType = !allowedTypes.includes(file.type);
        const transformedFile = {
          name: file.name, size: `${fileSizeInMB.toFixed(2)} MB`, errorMessage: isInvalidType
            ? "Only PDF, PNG, and JPG files are allowed."
            : isFileTooLarge
            ? "File size must not exceed 10MB."
            : null,
        };
        if (!isInvalidType && !isFileTooLarge) {
          // Only read valid files
          const reader = new FileReader();
          reader.onload = () => {
            this.setState((innerState) => ({
              fileUrl: reader.result as string,
              fileMetadata: [...innerState.fileMetadata, transformedFile],
              file: [...innerState.file, file],
            }));
          };
          reader.readAsDataURL(file);
        } else {updatedMetadata.push(transformedFile);}
      });
  
      return {
        fileMetadata: updatedMetadata,  file: updatedFiles,
      };
    });
  };
  
  
  handleProjectDetailsResponse = (responseJson:any) => {
    this.setState({projectDetails: responseJson.data.attributes}) 
  }
  getProjectId = () => {
    const currentPath = window.location.pathname;
    const path =currentPath.split('/')[3]
    this.setState({projectId: path})
  }
  getProjectDetails = async () => {
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token1,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProjectDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.projectDetialsGetAPiEndPoint}/${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSubMenuClick = async(status:string) => {
    let statusValue
    if(status === "To Do"){
        statusValue="to_do" }else if(status == "In Progress"){
        statusValue="in_progress" }else if(status == "Completed"){
      statusValue= "complete" }else if(status == "Blocked"){
      statusValue="deleted"} else if(status=='Archived'){
        statusValue='archived'
      }
      
      const token = await getStorageData("token");
      const header = { "Content-Type": configJSON.validationApiContentType,
        token: token,};
        const body = {
          "task_list_ids": Array.isArray(this.state.selectedTaskListID) 
            ? this.state.selectedTaskListID 
            : [this.state.selectedTaskListID],
          "status": statusValue
        };
        

      const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );

      this.changeTaskListStatusCallId = requestMessage.messageId;
      
      requestMessage.addData( 
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_tasks/projects/${this.state.projectId}/update_kanban_task_list_status`
      );
      

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"  // Or "PUT" / "POST" depending on API requirements
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage
      );
   
  };
  handlePriorityUpdate = async(priority:string) => {
    let statusValue
    if(priority === "Low"){
        statusValue="low"
    }else if(priority == "High"){
        statusValue="high"
    }else if(priority == "Mid"){
      statusValue= "medium"
    }
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,};

      const body = {
        "priority": statusValue
    }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changeTaskListPriorityCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_tasks/projects/${this.state.selectedTaskListID}/update_priority`
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `${configJSON.httpPatchMethod}`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage
      );
   
      this.setState({
        anchorElPriority:null,
        selectedTasklistPriority:priority
      })
  };

  handleAddMemberModal = () =>{
    this.setState((prev) => ({
      isAssignMemberModalOpen: !prev.isAssignMemberModalOpen,
    }));
    this.setState({selectedMembers:[],searchFieldError:''})
  }

  handleGetProjectMembersResponse = (responseJson:any) =>{
    this.setState({getAllMembers:responseJson.members})
  }
  getProjectsMembers = async () => {
    console.log('dffdfdf')
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectMembersCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/project_teams_and_members/project_teams_and_members?project_id=${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetAllMemberList=async ()=>{
    let token =await getStorageData("token")
    const header = {
        "Content-Type": 'application/json',
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetMembersListCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/project_teams_and_members/all_members?project_id=${this.state.projectId}`
      );
      requestMessage.addData(

        getName(MessageEnum.RestAPIRequestHeaderMessage),

        JSON.stringify(header)

      );

      

      requestMessage.addData(

        getName(MessageEnum.RestAPIRequestMethodMessage),

        'GET'

      );

      runEngine.sendMessage(requestMessage.id, requestMessage);



  }

  getMembersListResponse=(responseJson:any)=>{

    // this.setState({

    //   getAllMembers:responseJson.members

    // })

  }

  handleAssignMember = async (memArr:any) => {
    const data = this.state.selectedMembers.map((item:any) => item.value);
    const ids = this.state.selectedTaskListID;
    const assignee_ids = memArr.map((member: any) => member.id);
    
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    let body;

  if (this.state.isRoomTeamSeleted) {
    body = {
      assignee_ids: assignee_ids,
      task_ids: this.state.selectedRoomTasklist.map((task: any) => task.id), 
      room_ids: this.state.selectedRoomID,
      project_id: this.state.projectId,
      assignee_names:this.state.customTextEntries
    };
  } else {
    body = {
      assignee_ids:assignee_ids,
      task_ids: [this.state.selectedTaskListID],
      room_ids: this.state.selectedRoomID,
      project_id: this.state.projectId,
      assignee_names:this.state.customTextEntries
    };
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAssignMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PostAddAssignEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PATCH`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  addMemberResponse=(responseJson:any)=>{
    const error = responseJson?.error
  
    if(error){
      this.setState({searchFieldError:"Member already added"}) 
      this.setState({searchText:'',selectedMembers:[]})
    }else{  

      if(responseJson.message){
        this.setState({openError:true,statusError:'Member(s) successfully assigned',isSuccess:true})
        this.timmer()
        this.setState({searchText:'',searchFieldError:'',selectedMembers:[],isAssignMemberModalOpen:false})
      }
   
    }
    this.handleSelectedTaskList(this.state.selectedTaskListID)
  }
  clearSearch = () => {

    this.setState({ searchText: "", selectedMembers:[],searchFieldError:'' });

  };
timmer=()=>{
  setTimeout(() => {
    this.setState({ statusError: '',openError:false,isSuccess:false });
  }, 3000);
}
  handleAddText = () => {
    const { searchText, customTextEntries } = this.state;
    if (searchText.trim() !== "") {
      this.setState({
        customTextEntries: [...customTextEntries, searchText.trim()],
        searchText: "", // Clear input field
        showSuggestions: false,
      });
    }
  };
  removeCustomText = (index: number) => {
    this.setState((prevState) => ({
      customTextEntries: prevState.customTextEntries.filter((_:any, i:any) => i !== index),
    }));
  };
  


  toggleSelectMember = (member: any) => {
    this.setState((prevState: any) => {
      const isSelected = prevState.selectedMembers.some((m: any) => m.id === member.id);
      const isAdded = prevState.addedMembers?.some((m: any) => m.id === member.id);
  
      if (isAdded) {
        return {
          addedMembers: prevState.addedMembers.filter((m: any) => m.id !== member.id),
          selectedMembers: prevState.selectedMembers, 
        };
      } else {
        return {
          selectedMembers: isSelected
            ? prevState.selectedMembers.filter((m: any) => m.id !== member.id)
            : [...prevState.selectedMembers, member],
          addedMembers: prevState.addedMembers, // Ensure addedMembers is always included
        };
      }
    });
  };
  
  
  
  

  removeChipMember = (id: number) => {

    this.setState((prevState) => ({

      selectedMembers: prevState.selectedMembers.filter((m:any) => m.id !== id),

      searchText: "",

      searchFieldError:''

    }));

  };

  handleKeyDownMember = (event:any) => {

    const { searchText, selectedMembers, getAllMembers } = this.state;



    if (event.key === "Backspace" && searchText === "" && selectedMembers.length > 0) {

      const updatedSelectedMembers = [...selectedMembers];

      updatedSelectedMembers.pop();

      this.setState({ selectedMembers: updatedSelectedMembers });

    }



    if (event.key === "Enter") {

      const filteredMembers = getAllMembers.filter((member:any) =>

        member.first_name.toLowerCase().includes(searchText.toLowerCase())

      );



      if (filteredMembers.length > 0) {

        this.toggleSelectMember(filteredMembers[0]);

        this.setState({ searchText: "" });

      }

    }

  };

  getMemberPlaceholder = () => {

    const { selectedMembers } = this.state;

    return selectedMembers.length === 0 

      ? "Search by name or email..."

      : "";

  };

  handleAddButtonClick = () => {

    const { selectedMembers } = this.state;

    if (selectedMembers.length === 0 && this.state.customTextEntries.length === 0)
{
      this.setState({isAssignMemberModalOpen:false})

    }

    else{

      this.handleAssignMember(selectedMembers);

    }

};

getFilteredMember = (filteredMembers: any, selectedMembers: any) => {
  return filteredMembers; 
};


handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

  this.setState({ searchText: event.target.value ,showSuggestions: true,});

};


handleAddMember=async (memberArr:any)=>{
  let token =await getStorageData("token")
  const ids = memberArr.map((item:any) => item.id);
 
  let numberArray = ids.map(Number);
  const header = {
    "Content-Type": 'application/json',
    token: token
  };
  const body ={
    project_id: this.state.projectId,
    member_ids: numberArray
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiAddMemberCallId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_profile/project_teams_and_members/add_members`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
  // Customizable Area End
}
