export const threeDotIcon = require("../assets/button_icon.png");
export const unCheckIcon = require("../assets/checkbox_.png");
export const checkedIcon = require("../assets/checkedbox.png");
export const paidIcon = require("../assets/paid.png");
export const unPaidIcon = require("../assets/unpaid.png");
export const upComingIcon = require("../assets/upcoming.png");
export const filterIcon = require("../assets/filterIcon.png");
export const cross = require("../assets/cross.png");
export const calenderIcon = require("../assets/calenderIcon.png");
export const dowloadImg = require("../assets/dowloadImg.png");
export const emptyStateImg = require("../assets/emptyStateImg.png");
export const newFileImg = require("../assets/newFileImg.png");
export const success_icon_toster = require("../assets/success_icon_toster.png")


