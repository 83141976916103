import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { PDFDocumentProxy } = require("pdfjs-dist");
const pdfjsLib = require("pdfjs-dist");
if (!pdfjsLib.GlobalWorkerOptions.workerSrc) {
  const pdfjsVersion = require('pdfjs-dist/package.json').version;
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
}

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
interface ProjectProps {
  clientName: string;
  projectName: string;
  room: string;
  amount: number;
  dueDate: string;
  status: "UPCOMING" | "PAID" | "UNPAID"; 
}
interface InvoiceData {
  data: {
    data: [];
  };
  meta: MetaData | undefined;
}
interface Project {
  attributes: {
    invoice_number: any;
    project_name: string;
  };
};
interface Invoice {
  id: string;
  type: string;
  attributes: InvoiceAttributes;
}
interface InvoiceAttributes {
  total_amount: number;
  due_date: string;
  payment_status: "upcoming" | "paid" | "unpaid";
  project_name: string;
  client_name: string;
  rooms: Room[];
}
interface Room {
  id: number;
  room_name: string;
}
interface MetaData {
  message: string;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}
interface InvoiceSummary {
  data: {
    total_paid_amount: number;
    total_unpaid_amount: number;
    total_upcoming_amount: number;
    paid_invoices_count: number;
    unpaid_invoices_count: number;
    upcoming_invoices_count: number;
    total_invoices_count: number;
  };
  meta: {
    message: string;
  };
}

export interface ApiCallInterface {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Object;
}
export interface FileData{
  file_name: string;
  content_type: string;
  url: File|null;
  size:number;
  progress:number;
  uploadStatus:boolean;
  error:boolean;
};
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  reRenderCom: any;
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  invoiceData: Project[];
  anchorEl:  HTMLButtonElement | null ;
  openDownloadModal: boolean;
  openCreateInvoiceModal: boolean;
  anchorDatePicker: any;
  filterModalOpen: boolean;
  fromDateAnchorEl: HTMLElement | null;
  toDateAnchorEl:  HTMLElement | null;
  fromDate: Date | null;
  toDate: Date | null;
  invoiceSummary: InvoiceSummary;
  Invoice_Summarydata: {};
  totalMembers:number;
  currentPage:number;
  rowsPerPage:number;
  prevPage:number;
  ProjectID:number;
  ProjectName:string;
  searchValue: string;
  originalProjectList:Project[];
  searchInputText:string;
  invoiceNameCI : string;
  amountCI : string;
  amountError : string;
  projectIdCI : string;
  dueDateErrorCI : string;
  anchorDueDate: any;
  dueDateCI : string;
  statusCI : string;
  statusError : string;
  amountErrorBlank : string;
  isClientChecked : boolean;
  invoiceNameLimitError:string;
  isInvoiceOverviewOpen : boolean;
  invoiceValue : string;
  dueDate : string;
  clientName :string;
  clientEmail : string;
  clientNumber : string;
  clientAddress : string;
  invoiceNameTitle : string;
  invoicePaymentStatus : string;
  addDocsModal: boolean;
  uploadedFiles:FileData[];
  fileMetadata: any;
  isInvoiceOverviewDoc : boolean;
  invoiceNumber : string;
  invoiceType : string;
  invoiceDocument : any;
  uploadFileUrl: string;
  pdfDoc: any;
  totalPages: number;
  pageNumber: number;
  zoom: number;
  currentPagepdf: number;
  fileName: string;
  clickedFileName : string;
  attachmentId : string;
  pdfURL : string;
  reRenderCom : any;
  isDeleteModalOpen : boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class ProjectInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  DueDate = ["Today", "This Month", "This Week", "Next 30 days"]
  getOverAllProjectApiCallId: string = "";
  getOverAllInvoiceDataApiCallId: string = "";
  postCreateInvoiceApiCallId : string = "";
  postDocumentsApiCallID : string = "";
  postInvoiceOverviewDocCallID : string ="";
  putPaymentStatusChangeApiCallID : string = "";
  canvasContainerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  pageRendering: boolean = false;
  pageNum: number = 1;
  deleteDocumentApiCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      openCreateInvoiceModal: false,
      invoice: "",
      invoiceError: "",
      fromDateAnchorEl: null,
      toDateAnchorEl: null,
      invoicePdf: "",
      anchorEl: null,
      anchorDatePicker: null,
      openDownloadModal: false,
      filterModalOpen: false,
      toDate: null,
      fromDate: null,
      currentPage: 1,
      rowsPerPage: 10,
      totalMembers: 0,
      prevPage: 0,
      invoiceSummary: {
        data: {
          total_paid_amount: 0,
          total_unpaid_amount: 0,
          total_upcoming_amount: 0,
          paid_invoices_count: 0,
          unpaid_invoices_count: 0,
          upcoming_invoices_count: 0,
          total_invoices_count: 0
        },
        meta: {
          message: ""
        }
      },
      Invoice_Summarydata: {},
      invoiceData: [],
  ProjectID:0,
  ProjectName:"",
  searchValue: "",
  originalProjectList: [],
  searchInputText:'',
  invoiceNameCI: "",
  amountCI : "",
  amountError : "",
  projectIdCI : "",
  dueDateErrorCI : "",
  anchorDueDate: null,
  dueDateCI: '',
  statusCI: "",
  statusError : "",
  amountErrorBlank : "",
  isClientChecked : false,
  invoiceNameLimitError : "",
  isInvoiceOverviewOpen : false,
  invoiceValue : "",
  dueDate : "",
  clientName :"",
  clientEmail : "",
  clientNumber : "",
  clientAddress : "",
  invoiceNameTitle : "",
  invoicePaymentStatus : "",
  addDocsModal: false,
  uploadedFiles: [],
  fileMetadata: null,
  isInvoiceOverviewDoc: false,
  invoiceNumber : "",
  invoiceType : "",
  invoiceDocument : [],
  uploadFileUrl: "",
  pdfDoc: null,
  totalPages: 0,
  pageNumber: 1,
  zoom: 1,
  currentPagepdf: 1,
  fileName: "",
  clickedFileName : "",
  attachmentId : "",
  pdfURL : "",
  reRenderCom : 0,
  isDeleteModalOpen : false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.canvasRef = React.createRef();
    this.canvasContainerRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getOverAllProjectApiCallId) {
          this.overAllInvoiceSummary(responseJson1)
    }
    if (apiRequestCallId === this.postDocumentsApiCallID) {
      if(responseJson1.meta.message==="Invoice created Successfully..."){
        toast.success("New invoice has been created successfully.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
          style: {
            width: "auto", 
            maxWidth: "unset",
            whiteSpace: "nowrap",
          },
        });
    this.setState({addDocsModal:false})
      }
      
}
      if (apiRequestCallId === this.getOverAllInvoiceDataApiCallId) {
        this.setState({
          ProjectName: responseJson1.meta.project_name, clientName: responseJson1.meta.client_details.name + " " + responseJson1.meta.client_details.last_name,
          clientNumber: responseJson1.meta.client_details.country_code + " " + responseJson1.meta.client_details.phone, clientEmail: responseJson1.meta.client_details.email,
          clientAddress: (responseJson1.meta.client_details.address || responseJson1.meta.client_details.address2) + ", "+ responseJson1.meta.client_details.city + ", "+ responseJson1.meta.client_details.country
        })
        this.overAllInvoiceDataResponse(responseJson1)
        this.getMatchingInvoice(responseJson1)
      }
      if (apiRequestCallId === this.postCreateInvoiceApiCallId) {
        if (Array.isArray(responseJson1?.errors?.invoice_number) && responseJson1?.errors?.invoice_number[0] === "can't be blank") {
            this.setState({
              invoiceError: "Invoice Name can not be blank"
            });
        }
        if (Array.isArray(responseJson1?.errors?.invoice_number) && responseJson1?.errors?.invoice_number[0] === "must be unique within the account") {
          this.setState({
            invoiceError: "Invoice Name must be unique within the account"
          });
        }
        if (Array.isArray(responseJson1?.errors?.due_date) && responseJson1?.errors?.due_date[0] === "can't be blank") {
          this.setState({
            dueDateErrorCI : "Due Date can not be blank"
          });
        }
        if (responseJson1?.meta?.message === "Invoice created Successfully...") {
          toast.success("New invoice has been created successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
            style: {
              width: "auto", 
              maxWidth: "unset",
              whiteSpace: "nowrap",
            },
          });
          this.setState({ openCreateInvoiceModal: false, projectIdCI: "",  invoiceNameCI: "", amountCI: "", dueDateCI: '', statusCI: "" })
          this.getOverAllInvoiceData()
        }
        
      }
      if (apiRequestCallId === this.postInvoiceOverviewDocCallID) {
        if (responseJson1.message === "Files uploaded successfully") {
          this.handleIncreaseCount();
          toast.success("New file (s) uploaded successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
            style: {
              width: "auto", 
              maxWidth: "unset",
              whiteSpace: "nowrap",
            },
          });
          this.setState({ addDocsModal: false , isInvoiceOverviewDoc : false})
          this.getOverAllInvoiceData()
        }
      }

      if(apiRequestCallId === this.putPaymentStatusChangeApiCallID){
        if(responseJson1.meta.message === "Invoice payment status updated successfully."){
          toast.success("Invoice payment status updated successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
            style: {
              width: "auto", 
              maxWidth: "unset",
              whiteSpace: "nowrap",
            },
          });
          this.getOverAllInvoiceData()
          this.getOverAllProjectSummary()
        }
      }
      if(apiRequestCallId === this.deleteDocumentApiCallId){
        if(responseJson1.message === "Invoice File deleted Successfully..."){
          this.handleDeleteModal()
          this.setState({attachmentId : ""})
          this.getOverAllInvoiceData()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
this.getOverAllProjectSummary();
this.getOverAllInvoiceData();
  }
  async componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.reRenderCom !== this.state.reRenderCom) {
      this.getOverAllProjectSummary();
      this.getOverAllInvoiceData();
  }
  }
  getProjectId = () => {
    const url = window.location.pathname;
    const parts = url.split("/");
    const projectId = Number(parts[parts.indexOf("project_id") + 1]); 

    return projectId;
  };
  overAllInvoiceSummary = (responseJson1: InvoiceSummary) => {
    this.setState({ invoiceSummary: responseJson1, Invoice_Summarydata: responseJson1.data });
  };
  overAllInvoiceDataResponse = (responseJson: InvoiceData) => {
   
    if (responseJson && responseJson.meta) {
      
      const totalRecords = responseJson?.meta?.total_count || 0;
      this.setState({ invoiceData: responseJson.data.data, totalMembers: totalRecords, originalProjectList: responseJson.data.data, });
    }
  };
 onChangeInvoice = (text: string) => {
    if (text === "") {
      this.setState({
        invoice: text,
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ invoice: text, invoiceError: "" });
    }

  };

  getPdfLink = () => {
    if (this.state.invoice === "" || this.state.invoice.length !== 6) {
      this.setState({
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.pdfApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.exampleAPiEndPoint + this.state.invoice
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event?.currentTarget })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  openDownloadModal = () => {
    this.setState({
      openDownloadModal: true, anchorEl: null
    })
  };

  openCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: true
    })
  };
  closeCreateInvoiceModal = () => {
    this.setState({
      openCreateInvoiceModal: false,
      projectIdCI: "",  invoiceNameCI: "", amountCI: "", dueDateCI: '', statusCI: "", invoiceError: "",  amountError: "", statusError: "", amountErrorBlank : "", invoiceNameLimitError : "", dueDateErrorCI :""
    })
  };
  closeDownloadModal = () => {
    this.setState({
      openDownloadModal: false
    })
  };
  handleCalendarClose = () => {
    this.setState({
      anchorDatePicker: null
    })
  };
  formatDate = (date: Date | null): string => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "short", 
      day: "numeric", 
      year: "numeric"
    });
  };

 

  handleOpenFilter = () => {
    this.setState({filterModalOpen: true});
  }

  checkStatusColor = (status: string) => {
    if (status == "PAID") {
      return "#059669"
    }
    if (status == "UPCOMING") {
      return "#D97706"
    }
    if (status == "UNPAID") {
      return "#DC2626"
    }
  };
  checkStatusBgColor = (status: string) => {
    if (status == "PAID") {
      return "#D1FAE5"
    }
    if (status == "UPCOMING") {
      return "#FEF3C7"
    }
    if (status == "UNPAID") {
      return "#FEE2E2"
    }
    
  };
  openFromDatePopUp = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({fromDateAnchorEl: event?.currentTarget})
  };

  openToDatePopUp = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({toDateAnchorEl: event?.currentTarget})
  };

  selectFromDate= (date: Date | null) => {
    this.setState({
      fromDate: date
    }, () => {
      this.closeFromDatePicker()
    })
  };

  selectToDate = (date: Date | null) => {
    this.setState({
      toDate: date
    }, () => {
      this.closeToDatePicker()
    })
  };

  closeFromDatePicker = () => {
    this.setState({ fromDateAnchorEl: null });
  };

  closeToDatePicker = () => {
    this.setState({ toDateAnchorEl: null });
  };
  
  clearDateRange = () => {
    this.setState({ fromDate: null, toDate: null });
  };

  getOverAllInvoiceData = async () => {
    const getprojectId = this.getProjectId();
    this.getOverAllInvoiceDataApiCallId = await this.getOverAllProjectApiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `bx_block_invoice/project_room_invoices/${getprojectId}/project_invoices?per_page=${this.state.rowsPerPage}&page=${this.state.currentPage}`,
      method: "GET",
    });
  };

  getOverAllProjectSummary = async () => {
    const getprojectId = this.getProjectId();
    this.getOverAllProjectApiCallId = await this.getOverAllProjectApiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `bx_block_analytics/project_invoice_analytics/${getprojectId}`,
      method: "GET",
    });
  }
  
  getOverAllProjectApiCall = async (ApiCallData: ApiCallInterface) => {
    const {endPoint, body, contentType, method,  } = ApiCallData;
    const token = await getStorageData("token");
    const header = {
      'Content-Type': contentType,"token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
     method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     endPoint);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPaidProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.paid_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getUnpaidProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.unpaid_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getUpcomingProgress = (invoiceSummary: any) => {
    return (invoiceSummary?.upcoming_invoices_count / invoiceSummary?.total_invoices_count) * 100
  };
  getFormatedDueDate = (dateString: string): string => {
    if (!dateString) return "N/A"; 
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  setMembersPage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.fetchMembersData();
    });
  };
  
  generateMembersPageNumbers = (
    currentPage: number,
    totalPages: number,
    maxPageButtons: number
  ) => {
    if (totalPages <= maxPageButtons)
      return Array.from({ length: totalPages }, (_, i) => i + 1);

    if (currentPage <= 2) return [1, 2, "...", totalPages];
    if (currentPage >= totalPages - 1)
      return [1, "...", totalPages - 1, totalPages];

    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };
  
  fetchMembersData = () => {
    this.getOverAllInvoiceData()
  };

  handleNextMemberPage = () => {
    const { currentPage, rowsPerPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage, prevPage: currentPage }, () => {
        this.fetchMembersData();
      });
    }
  };

  handlePreviousMemberPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      this.setState({ currentPage: previousPage, prevPage: previousPage }, () => {
        this.fetchMembersData();
      });
    }
  };
  handleSearchInputChange = (value: string) => {
    this.setState({ searchInputText: value }, () => {
      this.filterProjectList();
    });
  };

filterProjectList = () => {
  const { searchInputText, originalProjectList } = this.state;
  if (!searchInputText) {
    this.setState({ invoiceData: originalProjectList });
    return;
  }

  const filteredList = originalProjectList.filter(
    (project) =>
      project.attributes.invoice_number.invoice_name
        ?.toLowerCase()
        .includes(searchInputText.toLowerCase())
  );
  this.setState({ invoiceData: filteredList });
};
clearSearch = () => {
  this.setState({searchInputText : ""})
  this.getOverAllInvoiceData()
}
handleInvoiceNameCI = (event: { target: any }) => {
  const value = event?.target?.value;
  if (value?.length <= 50) {
      this.setState({ invoiceError: "", invoiceNameCI: value , invoiceNameLimitError : ""});
  } else {
      this.setState({ invoiceNameLimitError: "Invoice name cannot exceed 50 characters" });
  }
};
  handleAmountCI = (event: { target: any }) => {
    const value = event.target.value;

    const amountRegex = /^(?:\d{1,9}|\d{1,9}\.\d{0,2})$/;

    if (value === "" || amountRegex.test(value)) {
        this.setState({ amountCI: value, amountError: "", amountErrorBlank: "" });
    } else {
        this.setState({ amountError: "Amount must be a number with up to 9 digits and 2 decimal places" });
    }
};
handleCheckboxClick = () => {
  this.setState({isClientChecked : !this.state.isClientChecked})
}
  openDueDateCalendar = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({dueDateErrorCI : "" , anchorDueDate: event?.currentTarget})
  };
  closeDueDateCalendar = () => {
    this.setState({ anchorDueDate: null });
  };

  handleSelectDueDateCI= (date: string) => {
    this.setState({
      dueDateCI: date
    }, () => {
      this.closeDueDateCalendar()
    })
  };
  handleStatusChange = (status : string) => {
    this.setState({ statusCI: status , statusError : ""});
  };
  handleCreateInvoice = () => {
    let hasError = false;

    if (this.state.amountCI === "") {
      this.setState({ amountErrorBlank: "Amount cannot be blank" });
      hasError = true;
    } else {
      this.setState({ amountErrorBlank: "" });
    }

    if (this.state.invoiceNameCI === "") {
      this.setState({ invoiceError: "Invoice Name can not be blank" });
      hasError = true;
    }

    if (this.state.dueDateCI === "") {
      this.setState({ dueDateErrorCI: "Due date can not be blank" });
      hasError = true;
    }

    if (this.state.statusCI === "") {
      this.setState({ statusError: "Select payment status" });
      hasError = true;
    }

    if (!hasError) {
      this.postCreateInvoice();
    }
  };
  apiCall = async (ApiCallData: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = ApiCallData;
    const token = await getStorageData("token");
    const header = {
      'Content-Type': "application/json",
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
     method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id,
       requestMessage);
       
    return requestMessage.messageId;
  };
  convertDate = (dateString : any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};
  postCreateInvoice = async () => {
    const getprojectId = this.getProjectId();
    this.postCreateInvoiceApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `/bx_block_invoice/project_room_invoices`,
      method: "POST",
      body : {
        "invoice":{
        "bx_block_profile_project_id": (getprojectId),
        "invoice_number": this.state.invoiceNameCI,
        "payment_status": this.state.statusCI.toLowerCase(),
        "total_amount": Number(this.state.amountCI),
        "due_date": this.convertDate(this.state.dueDateCI)
    }
      }
    });
  };

  handleInvoiceOverview = async (invoiceList : any) => {
    await setStorageData('invoiceListID', invoiceList.id)
    
    const date = new Date(invoiceList.attributes.due_date);

    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    this.setState({isInvoiceOverviewOpen : true, invoicePaymentStatus : invoiceList.attributes.payment_status ,
      invoiceNameTitle : invoiceList.attributes.invoice_number.invoice_name, 
      invoiceValue : invoiceList.attributes.total_amount, dueDate : formattedDate, 
      invoiceNumber : invoiceList.id, invoiceType : invoiceList.attributes.invoice_number.invoice_type,
      invoiceDocument : invoiceList.attributes.invoice_documents,
      pdfDoc: null, 
      clickedFileName: "", 
    },() => {
      this.handleIncreaseCount();
      this.autoLoadFirstInvoice();
    }
    )
  }

  handleBackClick = () => {
    this.setState({isInvoiceOverviewOpen : false, invoiceValue : "", dueDate : "", invoiceNameTitle : "", invoicePaymentStatus : ""})
    this.getOverAllInvoiceData()
    this.getOverAllProjectSummary()
  }
  handleAddDocModal = (value: boolean) => { this.setState({ addDocsModal: value, uploadedFiles: [], fileMetadata: null,openCreateInvoiceModal:false }) }
  validateUpload=()=>{
    if(this.state.uploadedFiles.length===0){
      return true;
    }
    return this.state.uploadedFiles.some((file) => file.error === true);
  };
  updatePdfFile = (acceptedFiles: File[] | File) => {
    const files = Array.isArray(acceptedFiles) ? acceptedFiles : [acceptedFiles];

    this.setState((prevState):any => {
      const updatedFiles = [...(prevState.uploadedFiles || [])];
      const updatedMetadata = [...(prevState.fileMetadata || [])];
      let newErrorMessage = ""; 

      files.forEach((file) => {

        const allowedFormats = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/svg+xml",
        ];

        const fileSizeInMB = file.size / (1024 * 1024);
        const isFileTooLarge = fileSizeInMB > 10;
        const isInvalidType = !allowedFormats.includes(file.type);

        const transformedFile = {
          name: file.name,
          size: `${fileSizeInMB.toFixed(2)} MB`,
          type: file.type,
          errorMessage: isInvalidType
            ? "File loading error"
            : isFileTooLarge
              ? "File size must not exceed 10MB."
              : null,
        };

        if (!isInvalidType && !isFileTooLarge) {
          const fileData = {
            file_name: file.name,
            content_type: file.type,
            url: file,
            size: file.size,
            progress: 0,
            uploadStatus: true,
            error: false,
          };

          updatedFiles.push(fileData);
          updatedMetadata.push(transformedFile);

          this.handleUploadProgress(file);
        } else {
          updatedMetadata.push(transformedFile);
          if (!newErrorMessage) newErrorMessage = "Only PDF, JPG, PNG files are supported"
        }
      });

      return {
        uploadedFiles: updatedFiles,
        fileMetadata: updatedMetadata,
        errorMessage: newErrorMessage,
      };
    });
  };
  handleUploadProgress = (file: File) => {
    const interval = setInterval(() => {
      this.setState((prevState) => {
        const updatedFiles = prevState.uploadedFiles.map((uploadedFile) => {
          if (uploadedFile.file_name === file.name) {
            const newProgress = Math.min(uploadedFile.progress + 10, 100);
            if (newProgress === 100) {
              clearInterval(interval);
              return {...uploadedFile, progress: newProgress,uploadStatus:false}
            }
            return { ...uploadedFile, progress: newProgress };
          }
          return uploadedFile;
        });
  
        return { uploadedFiles: updatedFiles };
      });
    }, 200);
  };
  handleRemoveUploadedFile = (fileName: string) => {
    this.setState((prevState) => ({
      fileMetadata: prevState?.fileMetadata?.filter((file: any) => file.name !== fileName),
      uploadedFiles: prevState?.uploadedFiles?.filter((file: any) => file.name !== fileName),
    }));
  };
  handleUploadApi= async()=>{
    
    const url = window.location.pathname;
    const parts = url.split("/");
    const projectId = parts[parts.indexOf("project_id") + 1]; 

    const token = await getStorageData("token");
    const formData = new FormData();

  this.state.uploadedFiles.forEach((fileDetail:FileData) => {
      
      formData.append(`invoice[bx_block_profile_project_id]`, projectId);
      formData.append(`invoice[invoice_number]`, this.state.invoiceNameCI);
      formData.append(`invoice[payment_status]`, this.state.statusCI.toLowerCase());
      formData.append(`invoice[total_amount]`,  (this.state.amountCI));
      formData.append(`invoice[due_date]`,  this.convertDate(this.state.dueDateCI));
         formData.append(`invoice[invoice_documents][][file_name]`, fileDetail.file_name);
      formData.append(`invoice[invoice_documents][][content_type]`, fileDetail.content_type);
      formData.append(`[invoice_documents][][file]`,fileDetail.url!);

  });

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postDocumentsApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_invoice/project_room_invoices"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInvoiceDoc = async () => {

    const token = await getStorageData("token");
    const formData = new FormData();
    this.state.uploadedFiles.forEach((fileDetail: FileData) => {

      formData.append(`invoice_number`, this.state.invoiceType);
      formData.append(`invoice_documents[]`, fileDetail.url!);

    });

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postInvoiceOverviewDocCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_invoice/project_room_invoices/${this.state.invoiceNumber}/upload_invoice_docs`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNewFileUpload = () => {
    this.handleAddDocModal(true)
    this.setState({ isInvoiceOverviewDoc: true })
  }
    
  statusChangePUTMethod = async() => {
    this.putPaymentStatusChangeApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `/bx_block_invoice/project_room_invoices/${this.state.invoiceNumber}/change_inv_status`,
      body: {
        "invoice_number": this.state.invoiceType,
         "status": this.state.invoicePaymentStatus
     }
    })
  }


  handlePaymentStatusChange = (event: { target: { value: any; }; }) => {
      this.setState({ invoicePaymentStatus: event.target.value },() => {
        this.statusChangePUTMethod()
      });
  }

  
  loadPDF = async (pdfUrl: string, fileName:string ,attachmentID: string, url : string) => {
    try {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
  
      this.setState(
        {
          pdfDoc: pdf,
          totalPages: pdf.numPages,
          clickedFileName: fileName,
          attachmentId : attachmentID,
          pdfURL : url
        },
        () => {
          this.renderAllPages();
        }
      );
    } catch (error) {
    }
  };

  renderAllPages = () => {
    const { pdfDoc, totalPages } = this.state;

    for (let num = 1; num <= totalPages; num++) {
      this.renderPage(num);
    }
  };

  renderPage = (num: number) => {
    
    if (this.pageRendering) return;
    this.pageRendering = true;
    const { zoom } = this.state;

    this.state.pdfDoc && this.state.pdfDoc.getPage(num).then((page: any) => {

      const canvas = this.canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: zoom });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      page.render({
        canvasContext: context!,
        viewport: viewport,
      }).promise.then(() => {
        this.pageRendering = false;
        this.setState({ currentPagepdf: num });
      });
    });
  };

  handlePrevPage = () => {
    const { currentPagepdf } = this.state;
    if (currentPagepdf > 1) {
      const prevPage = currentPagepdf - 1;
      this.setState({ pageNumber: prevPage }, () => {
        this.renderPage(prevPage);
      });
    }
  };
  NextPage = () => {
    const { currentPagepdf, totalPages } = this.state;
    if (currentPagepdf < totalPages) {
      const nextPage = currentPagepdf + 1;
      this.setState({ pageNumber: nextPage }, () => {
        this.renderPage(nextPage);
      });
    }
  };
  handleNextPage = () => {
    if (this.state.pageNumber < this.state.totalPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      this.renderPage(this.state.pageNumber);
    }
  };

  handlePreviousPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 })
      this.renderPage(this.state.pageNumber);
    }
  };

  handleZoomIn = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom + 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };

  handleZoomOut = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom - 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };

  handleDownload = async ( ) => {
  const fileUrl=  this.state.pdfURL
  const fileName = this.state.clickedFileName
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
    }
  }

  
  
  autoLoadFirstInvoice = () => {
    if (this.state.invoiceDocument.length > 0) {
      const firstInvoice = this.state.invoiceDocument[0];
  
      // Ensure the first invoice loads only if none is selected
      if (!this.state.clickedFileName || !this.state.pdfDoc) {
        this.loadPDF(firstInvoice.url, firstInvoice.filename, firstInvoice.attachmentID, firstInvoice.url);
      }
    }
  };


  
  handleIncreaseCount = () => {
    this.setState((prevState) => ({
      reRenderCom: prevState.reRenderCom + 1,
    }));

  };

   getMatchingInvoice = (invoiceData: any): any | null => {
    const invoiceListID = localStorage.getItem("invoiceListID");
  
    if (!invoiceListID) {
      return null;
    }
  
    const invoices = invoiceData?.data?.data || [];
  
    const matchingInvoice = invoices.find((invoice: any) => invoice.id === invoiceListID);
    this.setState({invoiceDocument : matchingInvoice.attributes.invoice_documents});
    return matchingInvoice || null;
  };

  handleDeleteDocument = async () => {
    this.deleteDocumentApiCallId = await this.apiCall({
      contentType: configJSON.getContentType,
      endPoint: `/bx_block_invoice/project_room_invoices/${this.state.invoiceNumber}/delete_invoice_file?invoice_number=${this.state.invoiceType}&attachment_id=${this.state.attachmentId}`,
      method: "DELETE",
    });
  }

  handleDeleteModal = () => {
    this.setState((prev) => ({
      isDeleteModalOpen: !prev.isDeleteModalOpen,
    }));
  };
  
  
  // Customizable Area End
}
