// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { SelectChangeEvent } from '@mui/material';
import { ReactNode } from "react";
const navigation = require("react-navigation");

export interface GalleryMedias {
  url?: string,
  blob_id?: number,
  filename?: string,
  gallery_id?: string | number,
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface FolderItemsData {
  id?: number,
  type?: string,
  attributes: { folder_name: string }
}

export interface DataItems {
  id: string,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder: {
      data: Array<FolderItemsData>
    }
  }
}

interface FileMenu {
  id?: number | string,
  file?: string,
  blob_id?: number,
  fileAndFolderName?: string,
}

export interface AllDocumentsResponse {
  data: Array<DataItems>;
}

export interface AllDocumentData {
  gallery_id?: string,
  filename?: string,
  blob_id?: string
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface MapData {
  id: number
  url: string,
  blob_id: number,
  filename: string,
  gallery_id: number,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder_name: string,
    gallery: {
      id: number
    }
  }
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}

interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}

interface TokenAlert {
  token: string
}

// For Mobile
export interface Galleryobjectuniqueid {
  gallery_type: string;
  account_id: number;
}
export interface Innerobjectoffolder {
  folder_name: string;
  folder_type: string;
  gallery: Galleryobjectuniqueid;
  folder_medias: Array<GalleryMedia>
}
export interface Foldermedia {
  type: string;
  attributes: Innerobjectoffolder
}
export interface FolderInterface {
  data: Array<Foldermedia>
}
export interface GalleryMedia {
  url: string;
  blob_id: number;
  filename: string;
}
export interface Innerobject {
  gallery_type: string;
  gallery_medias: Array<GalleryMedia>
  folder: FolderInterface;
}
export interface Versionitems {
  data: Array<ObjectMedia>;
}
export interface ObjectMedia {
  type: string;
  attributes: Innerobject;
}
export interface ApiCallInterface {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Object;
}

export interface Itemmedia {
  concat(data: Itemmedia[]): Itemmedia[];
  url: string,
  blob_id: number,
  filename: string
}

export interface DocumentlistItemapi {
  data: Array<GetAllDocumentlistapi>;
}

export interface GetAllDocumentlistapi {
  type: string;
  attributes: Objectattributes;
}

export interface Objectattributes {
  folder: ObjectOffolder;
  gallery_medias: Itemmedia;
}

export interface ObjectOffolder {
  data: Itemmedia[];
}

interface GetAllDocumentResponseJson {
  data: Array<Versionitems>;
  message: string
}

interface Errorobject {
  errors: string;
}

interface Tokenexpire {
  errors: Array<Tokenalert>
}
export interface Tokenalert {
  token: string
}

export interface Itemmedia {
  url: string;
  blob_id: number;
  filename: string;
  id: string,
  type: string,
  attributes: Folderobject
}
export interface Folderobject {
  folder_name: string;
  folder_type: string;
  gallery: Uniqeidnumberobject;
  folder_medias: Array<Fileinfolder>;
  cover_photo: null;
}
export interface Fileinfolder {
  url: string;
  blob_id: number;
  filename: string;
}

export interface Uniqeidnumberobject {
  gallery_type: string,
  account_id: number
}

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
}
export interface FileData{
  file_name: string;
  content_type: string;
  url: File|null;
  size:number;
  progress:number;
  uploadStatus:boolean;
  error:boolean;
};
interface S {
  toast:{
    messageToggle:boolean;
    toastMessage:string;
    status:string;
  };
  selectedDocumentId: number | null ;
  deletedDoctId: number;
  fileMetadata: any;
  errorMessage: string;
  documentsData:{
    id:number;
    filename:string;
    url:string;
    file_size:string;
    upload_date:string;
    user_name:string;
    profile_pic:string;
    content_type:string;
  }[];
  filteredDocuments:{
    id:number;
    filename:string;
    url:string;
    file_size:string;
    upload_date:string;
    user_name:string;
    profile_pic:string;
    content_type:string;
  }[];
  projectId:string;
  menuToggle:boolean;
  selectedValue:string;
  selectedSortDate: string;
  anchorEl: HTMLElement | null;
  selectedValues:number[];
  openDeleteModal: boolean;
  searchInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  loader: boolean;
  fileMenu: FileMenu | null;
  addDocsModal: boolean;
  renameModal: FileMenu | boolean | null;
  deleteModal: null | FileMenu;
  uploadedFiles:FileData[];
  folderName: string;
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  allDocuments: Array<FolderItemsData | GalleryMedias>,
  folderId: number;
  galleryId: number | string;
  blobId: number;

  // For Mobile

  modalDots: boolean;
  folderModal: boolean;
  folderNameNative: string;
  cancel: boolean;
  create: boolean;
  rename: boolean;
  delete: boolean;
  modalRename: boolean;
  fileRenameText: string;
  deleteModalnative: boolean;
  fileDeleteText: string;
  documentList: Array<Itemmedia>;
  blobIdNumber: number;
  floderId: string;
  documentId: string;
  isFolder: boolean;
  isDelete: boolean;
  isloading: boolean;
  yesNoModal: boolean;
  yesNoModalFile: boolean;
  responseFailureData: boolean;
  sectionID:number;

}

interface SS {
  id: string;
}

export default class DocumentationController extends BlockComponent<
  Props,
  S,
  SS
> {
  postDocumentsApiCallID:string="";
  getDocumentsListApiCallID:string ="";
  addFolderApiCallId: string = "";
  renameFileApiCallId: string = "";
  deleteFileApiCallId: string = "";
  allDocumentsApiCallId: string = "";
  renameFolderApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesAndFoldersApiCallId: string = "";
  deleteDocumentApiCallId: string = "";
  getsectionsCallId: string = "";
  // For Mobile

  getAllDocumentsApiCallId: string = "";
  createDocumentApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";
  getAddFolderApiCallId: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  willFocusScreen!: string

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      toast:{
        messageToggle:false,
        toastMessage:"",
        status:"",
      },
      selectedDocumentId: null,
      deletedDoctId: 0,
      fileMetadata: null,
      errorMessage: "",
      documentsData:[],
      openDeleteModal: false,
      filteredDocuments:[],
      projectId:"",
      menuToggle:false,
      selectedValue:"",
      selectedSortDate: "Recent to old",
      anchorEl: null,
      selectedValues:[],
      searchInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteModal: null,
      allDocuments: [],
      addDocsModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      renameModal: null,
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      fileMenu: null,
      blobId: 0,

      // For Mobile

      modalDots: false,
      folderModal: false,
      cancel: false,
      create: false,
      rename: false,
      delete: false,
      folderNameNative: '',
      modalRename: false,
      documentList: [],
      documentId: '512',
      blobIdNumber: 0,
      fileRenameText: '',
      deleteModalnative: false,
      fileDeleteText: '',
      floderId: '',
      isFolder: false,
      isDelete: false,
      isloading: false,
      yesNoModal: false,
      yesNoModalFile: false,
      responseFailureData: false,
      sectionID:0
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.getDocumentsListApiCallID){
        this.setState({loader:false})
        this.handleDocumentsDataResponse(message)
      }
      if(apiRequestCallId===this.postDocumentsApiCallID){
        this.handlePostDocumentsApiCallID(message)
      }
      if (apiRequestCallId === this.deleteDocumentApiCallId) {
        this.getAllDocumentList();
    }
    if (apiRequestCallId === this.getsectionsCallId) {
      this.setState({sectionID:responseJson.data[0].id})
      
     }
}
  }
  handleDocumentsDataResponse=(message:Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.error || responseJson.message){
      this.setState({documentsData:[], filteredDocuments: []});
      return;
    }
    else if (responseJson.length > 0) {
      let finalResponse = responseJson;
      if (this.state.searchInputValue != "") {
        finalResponse = responseJson.filter((document: { filename: string }) =>
          document.filename.toLowerCase().includes(this.state.searchInputValue))
      }
      this.setState({ documentsData: responseJson, filteredDocuments: finalResponse });
    }
  };
  handlePostDocumentsApiCallID=(message:Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.error){
      this.setState({toast:{messageToggle:true, toastMessage:"", status:"failed"}},()=>{
        this.closeMessageBox()
      });
      this.handleAddDocModal(false);
    }
    else if(responseJson.message){
      this.setState({
        toast: {
          messageToggle: true, toastMessage: "New file (s) uploaded successfully.", status: "success",
        }, uploadedFiles: [], fileMetadata: null
      }, () => {
        this.closeMessageBox()
      });
      this.handleAddDocModal(false);
      this.getAllDocumentList()
    }
  }

  async componentDidMount(): Promise<void> {
    const path=window.location.pathname;
    this.setState({projectId:path.split("/")[3]},()=>{
      this.getAllDocumentList()
      this.getSectionsCallId()
    });
  }
  
  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({toast:{messageToggle:false, toastMessage:"", status:""}})
    },2000);
  }
  handleToggleTabs=(status:boolean)=>{
    this.setState({enableField:status})
  };
  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchValue = event.target.value.trim().toLowerCase();
    this.setState({ searchInputValue: searchValue });
    const filteredDocuments = this.state.documentsData.filter((document) =>
      document.filename.toLowerCase().includes(searchValue)
    );
    this.setState({ filteredDocuments });
  };
  handleSelectUser = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const selectedValue = event.target.value as string ;
    this.setState({ selectedValue }, () => {
      this.getAllDocumentList()
    });
  };
  handleDateSort = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const selectedSortDate = event.target.value as string;
    this.setState({ selectedSortDate }, () => {
      this.getAllDocumentList()
    });
  };
  handleMenuModal = (value: FileMenu | null) => { this.setState({ fileMenu: value }) }

  handleAddDocModal = (value: boolean) => { this.setState({ addDocsModal: value, uploadedFiles: [], fileMetadata: null }) }

  handleRenameModal = (value: FileMenu | null) => {
      this.setState({ renameModal: value, renameValue: value?.fileAndFolderName ?? "" })
  }

  updatePdfFile = (acceptedFiles: File[] | File) => {
    const files = Array.isArray(acceptedFiles) ? acceptedFiles : [acceptedFiles];

    this.setState((prevState) => {
      const updatedFiles = [...(prevState.uploadedFiles || [])];
      const updatedMetadata = [...(prevState.fileMetadata || [])];
      let newErrorMessage = ""; 

      files.forEach((file) => {

        const allowedFormats = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/svg+xml",
        ];

        const fileSizeInMB = file.size / (1024 * 1024);
        const isFileTooLarge = fileSizeInMB > 10;
        const isInvalidType = !allowedFormats.includes(file.type);

        const transformedFile = {
          name: file.name,
          size: `${fileSizeInMB.toFixed(2)} MB`,
          type: file.type,
          errorMessage: isInvalidType
            ? "File loading error"
            : isFileTooLarge
              ? "File size must not exceed 10MB."
              : null,
        };

        if (!isInvalidType && !isFileTooLarge) {
          const fileData = {
            file_name: file.name,
            content_type: file.type,
            url: file,
            size: file.size,
            progress: 0,
            uploadStatus: true,
            error: false,
          };

          updatedFiles.push(fileData);
          updatedMetadata.push(transformedFile);

          this.handleUploadProgress(file);
        } else {
          updatedMetadata.push(transformedFile);
          if (!newErrorMessage) newErrorMessage = "Only PDF, JPG, PNG files are supported"
        }
      });

      return {
        uploadedFiles: updatedFiles,
        fileMetadata: updatedMetadata,
        errorMessage: newErrorMessage,
      };
    });
  };

  handleRemoveUploadedFile = (fileName: string) => {
    this.setState((prevState) => ({
      fileMetadata: prevState.fileMetadata.filter((file: any) => file.name !== fileName),
      uploadedFiles: prevState.uploadedFiles.filter((file: any) => file.name !== fileName),
    }));
  };
  
  handleOverSizedFiles=(file:File)=>{
    this.setState((prevState)=>{return{uploadedFiles:[...prevState.uploadedFiles,{
      file_name: file.name,
      content_type: file.type,
      url:null,
      size: file.size,
      progress:100,
      uploadStatus:false,
      error:true
    }]}})
  };
  checkFileExits=(file:File)=>{
   const exits=this.state.uploadedFiles.some(item=>item.file_name===file.name);
   return exits;
  };
  handleUploadProgress = (file: File) => {
    const interval = setInterval(() => {
      this.setState((prevState) => {
        const updatedFiles = prevState.uploadedFiles.map((uploadedFile) => {
          if (uploadedFile.file_name === file.name) {
            const newProgress = Math.min(uploadedFile.progress + 10, 100);
            if (newProgress === 100) {
              clearInterval(interval);
              return {...uploadedFile, progress: newProgress,uploadStatus:false}
            }
            return { ...uploadedFile, progress: newProgress };
          }
          return uploadedFile;
        });
  
        return { uploadedFiles: updatedFiles };
      });
    }, 200);
  };
  fileSizeText=(file:FileData)=>{
   if(file.error){
    return "The file is too large to upload."
   }else{
    return this.renderUploadedFileSize(file.size);
   }
  };
  renderUploadedFileSize(bytes: number){
    if (bytes < 1024) {
      return `${bytes} B`;
    } else if (bytes < 1024 * 1024) {
      const kb = bytes / 1024;
      return `${kb.toFixed(2)} KB`;
    } else {
      const mb = bytes / (1024 * 1024);
      return `${mb.toFixed(2)} MB`;
    }
  };
  validateUpload=()=>{
    if(this.state.uploadedFiles.length===0){
      return true;
    }
    return this.state.uploadedFiles.some((file) => file.error === true);
  };
  handleRemoveFile=(index:number)=>{
    let updatedFiles=[...this.state.uploadedFiles];
    updatedFiles.splice(index,1);
    this.setState({uploadedFiles:updatedFiles})
  }
  handleUploadApi=()=>{
    const token=localStorage.getItem("token");
    const formData = new FormData();
  formData.append("project_id", this.state.projectId.toString());

  this.state.uploadedFiles.forEach((fileDetail:FileData) => {
      formData.append(`files[][section_id]`, (this.state.sectionID).toString());

      formData.append(`files[][file_name]`, fileDetail.file_name);
      formData.append(`files[][content_type]`, fileDetail.content_type);
      formData.append(`files[][file]`,fileDetail.url!);
  });
    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postDocumentsApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/projects/upload_files"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteModal = (value: FileMenu | null) => {
    this.setState({ deleteModal: value })
    this.handleMenuModal(value);
  }

  handleFolderName = (value: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ folderName: value.target?.value })
  }

  handleRename = (value: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ renameValue: value.target?.value })
  }

  handleFolderNavigate = (gallery_id?: number | string, folderId?: number) => {
    if (gallery_id && folderId) {
      this.setState({ folderId: folderId, galleryId: gallery_id })
      this.props.navigation.navigate("DocumentationFolder", { galleryId: gallery_id, folderId: folderId })
    }
  }

  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(event.target.files!)
    let formData = new FormData();
    for (const file of files) {
      formData.append("gallery_medias[]", file)
    }
    this.setState({ loader: true })
    this.uploadFilesAndFoldersApiCallId = await this.apiCall({
      method: 'PATCH',
      endPoint:`/bx_block_documentation/gallery_documents/${this.state.galleryId}`,
      body: formData,
      type: "formData"
    })
  }

  getAllDocuments = async () => {
    this.setState({ loader: true })
    this.allDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/gallery_documents/account_gallery?gallery_type=documents`,
    })
  }

  renameFileAndFolder = async (value: FileMenu | null) => {
    if (value) {     
      this.setState({ loader: true })
      if (value.file === "file") {
        let bodyData = {
          data: {
            attributes: {
              blob_id: value.blob_id,
              filename: this.state.renameValue
            }
          }
        }
        this.renameFileApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'PATCH',
          endPoint:`bx_block_documentation/gallery_documents/${value.id}/update_file_name`,
          body: bodyData,
        })
      }
      else {
        let bodyData = {
          data: {
            attributes: {
              gallery_id: value.id,
              folder_name: this.state.renameValue
            }
          }
        }
        this.renameFolderApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'PATCH',
          endPoint:`bx_block_documentation/folder_documents/${value.blob_id}/update_folder_name`,
          body: bodyData,
        })
      }
      this.setState({ renameModal: false, fileMenu: null })
      this.getAllDocuments()
    }
  }

  addFolder = async () => {
    if (!this.state.folderName.trim()) {
      this.showAlert('Alert', 'Please Enter Folder Name');
      return
    }
    if (typeof this.state.galleryId === "string") {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: parseInt(this.state.galleryId),
            folder_name: this.state.folderName,
          }
        }
      }
      this.setState({ loader: true });
      this.addFolderApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'POST',
        endPoint: `bx_block_documentation/folder_documents`,
        body: bodyData,
      })
      this.getAllDocuments();
    }
  }

  deleteFolderAndFiles = async (value: FileMenu | null) => {
    if (value) {
      this.setState({ loader: true })
      if (value.file === "file") {
        let bodyData = {
          data: {
            attributes: {
              blob_id: value.blob_id,
            }
          }
        }
        this.deleteFileApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'DELETE',
          endPoint:`/bx_block_documentation/gallery_documents/${value.id}/destroy_media_file`,
          body: bodyData,
        })
      }
      else {
        let bodyData = {
          data: {
            attributes: {
              gallery_id: value.id,
            }
          }
        }
        this.deleteFolderApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'DELETE',
          endPoint:`bx_block_documentation/folder_documents/${value.blob_id}`,
          body: bodyData,
        })
      }
      this.setState({ deleteModal: null, fileMenu: null });
      this.getAllDocuments();
    }
  }

  folderNavigate =(element: GalleryMedias) => {
    if (element.attributes?.folder_name) {
      this.handleFolderNavigate(element.attributes.gallery?.id, element.id)
    }
  }

  //  For Mobile
  getAllSuccessFunctionCallBack = (apiRequestCallId: string, responseJson: GetAllDocumentResponseJson & DocumentlistItemapi) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunctionCallBack = (apiRequestCallId: string, responseJson: Tokenexpire & string & Errorobject) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBack(responseJson);
    }
  }

  //  For Web

  successCallBack = (apiRequestCallId: string, responseJson: string & AllDocumentsResponse & SuccessResponse) => {
    if (apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderSuccessCallback(responseJson);
    }
  }

  failureCallBack = (apiRequestCallId: string, responseJson: string & TokenExpire & ErrorResponse) => {
    if (apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsFailureCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderFailureCallback(responseJson);
    }
  }

  // API Success and Failure Callbacks for Web

  getAllDocumentsSuccesCallback = (response: AllDocumentsResponse) => {
    this.setState({ loader: false })
    const medias = response.data[0].attributes.gallery_medias.map((data: GalleryMedias): GalleryMedias | FolderItemsData => {
      return {
        ...data,
        gallery_id: parseInt(response.data[0].id)
      }
    })
    this.setState({ galleryId: response.data[0].id })
    this.setState({ allDocuments: medias.concat(response.data[0].attributes.folder.data) })
  }

  getAllDocumentsFailureCallback = (response: TokenExpire) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors[0].token)
  }

  uploadFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'Files uploaded successfully')
    this.getAllDocuments();
  }

  uploadFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  addFolderSuccessCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'Folder added successfully')
    this.getAllDocuments();
  }

  addFolderFailureCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', "This folder name has already been taken")
    this.getAllDocuments();
  }

  renameFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  renameFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  renameFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  renameFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  deleteFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  deleteFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }
  deleteFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  deleteFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  // API Success and Failure Callbacks for Mobile

  getAllDocListSuccesCallBack =  (responseJson: DocumentlistItemapi) => {
    this.setState({ isloading: false}
      ,  () => {
        this.setState({ documentList: responseJson.data[0].attributes.gallery_medias.concat(responseJson.data[0].attributes.folder.data) });
      }
    )
  };

  getAllDocListFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== errorResponseJson", responseJson);
  };

  createDocumentSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false })
    if (responseJson.data) {
      this.getAllDocumentList()
    }
  };

  createDocumentFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };
 
  deleteFileSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModalFile: false, deleteModalnative: false, blobIdNumber: 0, fileDeleteText: '', isloading: false })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  deleteFileFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
  };

  renameNewFileSuccesCallBack = async (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, blobIdNumber: 0, fileRenameText: '' })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  renameNewFileFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false, modalRename: false, })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  addNewFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ folderModal: false }, () => {
      this.getAllDocumentList()
    })
    this.setState({ isloading: false })
  };

  addNewFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  deleteFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModal: false, isloading: false, deleteModalnative: false, floderId: '', fileDeleteText: '' })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  deleteFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  renameFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, floderId: '', folderNameNative: '', })
    this.showAlert('Alert', responseJson?.message)
    this.getAllDocumentList()
  };

  renameFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false, modalRename: false, })
  };

  createFolderOpen = () => { this.setState({ addDocsModal: false, }, () => this.addFolder()) }  

  getAllDocumentList = async () => {
    let uploadDocumentOwner = "";
    let sortUploadDate = "";
    if (this.state.selectedValue == "Company Owner") {
      uploadDocumentOwner = "company_user"
    }
    if (this.state.selectedValue == "Client") {
      uploadDocumentOwner = "client"
    }
    if (this.state.selectedSortDate == "Recent to old") {
      sortUploadDate = "recent_to_old"
    }
    if (this.state.selectedSortDate == "Old to recent") {
      sortUploadDate = "old_to_recent"
    }
    const token=localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({loader:true});
    this.getDocumentsListApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/${this.state.projectId}/list_documents?role=${uploadDocumentOwner}&sort_order=${sortUploadDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createDocumentApiCell = async (urlresponse: string, fileName: string) => {
    this.setState({ isloading: true })
    let formData = new FormData();
    formData.append("gallery_medias[]",
      JSON.parse(JSON.stringify({
        uri: urlresponse,
        type: "sample/pdf",
        name: fileName
      }))
    )

    this.createDocumentApiCallId = await this.apiCall({
      contentType: "multipart/form-data",
      method: 'PATCH',
      endPoint:`/bx_block_documentation/gallery_documents/${this.state.documentId}`,
      body: formData,
      type: 'formData'
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`/bx_block_documentation/gallery_documents/${this.state.documentId}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    const apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber,
          filename: this.state.fileRenameText
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/gallery_documents/${this.state.documentId}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  addFolderApiCell = async () => {
    if (this.state.folderNameNative.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.folderNameNative
        }
      }
    }

    this.getAddFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentation/folder_documents`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.fileRenameText,
        }
      }
    }
    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}`,
      body: apidata,
      type: ""
    });
  }

  threeDotsTouchable = (item: Itemmedia) => item?.type !== 'folder' ? this.onRequestClose(item?.blob_id, item?.filename) : this.onFolderRenameandDelete(item.id, item.attributes.folder_name)

  onRequestClose = (Item_id: number, fileName: string) => this.setState({ blobIdNumber: Item_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: false, isDelete: false }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  onFolderRenameandDelete = (Items_id: string, fileName: string) => this.setState({ floderId: Items_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: true, isDelete: true }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  folderClickButton = () => this.setState({ folderModal: true, create: false, cancel: false, folderNameNative: '' })

  modalDotsTrue = () => this.setState({ modalDots: false })

  modalOverMOdal = () => this.setState({ modalDots: false, modalRename: true, })

  modalDeleteOverMOdal = () => this.setState({ modalDots: false, deleteModalnative: true, })

  navigateScreen =  (item: Itemmedia) => { this.props.navigation.navigate('DocumentationFolder', { item: item, documentId: this.state.documentId }) }

  changeTextFolderModal = (text: string) => { this.setState({ folderNameNative: text }) }

  RenameChangeFileModal = (text: string) => { this.setState({ fileRenameText: text }) }

  changeDeleteFileModal = (text: string) => { this.setState({ fileDeleteText: text }) }

  cancelFolderModal = () => this.setState({ folderModal: false, cancel: true, create: false })

  cancelDeleteFile = () => this.setState({ deleteModalnative: false, cancel: true, delete: false })

  cancelFolderRename = () => this.setState({ modalRename: false, cancel: true, rename: false })

  createFolderModal = () => { this.setState({ create: true, cancel: false, }, () => this.addFolderApiCell()) }

  responseRenameFile = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFileApiCell()) }

  responseRenameFolder = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFolderApiCell()) }

  responseDeleteFile = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModalFile: true }) }

  responseDeleteFolder = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModal: true }) }

  responseDeleteFolderYesNo = () => { this.DeleteFolderApiCell() }

  responseDeleteFolderYesNoFile = () => { this.DeleteFileApiCell() }

  uplaodCsvFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
      if (isResponse) {
        this.createDocumentApiCell(isResponse[0].uri, isResponse[0].name)
      }
    }
    catch (error) {
    }
  };

  yesNoModalFalse = () => this.setState({ yesNoModal: false, yesNoModalFile: false })
  handleOpenActiaonTab = (documentId: number, event:  React.MouseEvent<HTMLElement>) => {
    this.setState(prevState => ({
      selectedDocumentId: prevState.selectedDocumentId === documentId ? null : documentId,
    }));
    this.setState({ anchorEl: event.currentTarget})
  };

  handleCloseAction = () => {
    this.setState({ anchorEl: null})
  };

  handleDownload = async (documentContent: {url: string, filename: string}) => {
    const fileUrl=  documentContent.url
    const fileName = documentContent.filename
      try {
        const response = await fetch(fileUrl, {
          method: 'GET',
        });
  
    if (!response.ok) {
      throw new Error('Failed to fetch the file');
    }
  
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
  this.setState({ selectedDocumentId: null });
    }
    handleRemoveUploadedFile2 = () => {
      this.setState({
      }, () => {
        document.getElementById("hiddenFileInput")?.click();
      });
    };
    handleConfirmDelete = async () => {
      this.deleteDocumentApiCallId = await this.deleteTaskListApiCall({
           contentType: configJSON.getContentType,
           endPoint: `bx_block_profile/projects/${this.state.projectId}/delete_attachment?attachment_id=${this.state.deletedDoctId}`,
           method: "DELETE",
         });
     this.setState({openDeleteModal:false});
   }
   deleteTaskListApiCall = async (ApiCallData: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = ApiCallData;
    const token = await getStorageData("token");
    const header = {
      'Content-Type': contentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body &&  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteModal: false })
  }
  handleConfirmDeleteModal = (documentId:number) => {
    this.setState({ selectedDocumentId: null, openDeleteModal: true, deletedDoctId:  documentId});
  }
  getSectionsCallId = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getsectionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/sections`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End
