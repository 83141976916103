import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
export const configJSON = require("./config");
interface ProjectData {
  id:string;
  projectName:string,
  fileCount:string,
  name:string,
}

interface Meta {
  message: string;
}

interface DrawingDataList {
  data: ProjectData[];
  meta: Meta;
}

interface FileMetaData {
  name:string;
  size:string;
}

// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  DrawingDataList : DrawingDataList;
  toggle:string;
  visibleCount: number,
  token:string,
  loading:boolean,
  openUploadDialog:boolean,
  searchQuery: string;
  file: File | null; // File object or null
  fileUrl:string | null;
  fileMetadata: FileMetaData | null; // File metadata or null
  errorMessage:string | null;
  fileSizeError:boolean;
  loader:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
export default class DrawingScalingDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetProjectsListCallId: string | null = "";
  uploadFileID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   DrawingDataList:{
     data: [],
     meta: {
       message: ""
     }
   },
   toggle:'Draft',
   visibleCount: 8,
   token:'',
   loading:false,
   openUploadDialog:false,
   searchQuery: '',
   file: null,
   fileUrl:null,
   fileMetadata: null,
   errorMessage: null,
   fileSizeError:false,
   loader:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleScroll = this.handleScroll.bind(this);
    this.receive = this.receive.bind(this);
    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getProjectsListData(token);
    }
    // Customizable Area End
  

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiGetProjectsListCallId) {
        this.getProjectListResponse(responseJson);
      }
      if(apiRequestCallId==this.uploadFileID){
        if(responseJson.data){
          this.setState({loader:false})
          setStorageData('fileID',responseJson.data.id)
          this.navigateScaling()
        }
      }
    
    }
    
  }
  handleToggle=(dataName:any)=>{
    this.setState({toggle:dataName},()=>this.getProjectsListData(this.state.token))
   
  }
  loadMoreCards = () => {
    this.setState((prevState: any) => ({
      visibleCount: prevState.visibleCount + 4,
    }));
  };
  handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;
    if (bottom) {
      this.loadMoreCards();
    }
  };
  getProjectsListData = async (token:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const selectedTabState = this.state.toggle
    const searchValue = this.state.searchQuery
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllProjectsAPIEndPoint}?status=${selectedTabState}&search=${searchValue}&project_plan[]=1&project_plan[]=2&project_plan[]=3`
   
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProjectListResponse=(responseJson:DrawingDataList)=>{
   if(responseJson?.data){
    this.setState({
      DrawingDataList: {
        ...this.state.DrawingDataList, 
        data: responseJson.data,  
        meta : responseJson.meta 
      },
    });
    
  }else{
    this.setState({
      DrawingDataList: {
        ...this.state.DrawingDataList, 
        data: [], 
        meta : responseJson.meta   
       
      },
    })
  }

  }
  handleButtonScaling=()=>{
    this.setState({
      openUploadDialog:true
    }) 
    removeStorageData('fileObj')
  }
  handleCloseUploadDialog = () => {
    this.setState({ openUploadDialog: false });
  
    setTimeout(() => {
      this.setState({ file: null });
    }, 300);
  };
  handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ searchQuery: query },()=>this.getProjectsListData(this.state.token));
  };
 
  handleRemoveUploadedFile=()=>{
    this.setState({file:null})
  }
  handleRemoveUploadedFile2 = () => {
    this.setState({
      file: null,
      fileMetadata: null,
      errorMessage: null,
      fileSizeError: false,
    }, () => {
      document.getElementById("hiddenFileInput")?.click();
    });
  };
  
  handleUploadFile = async () => {
      this.setState({loader:true})
      const token = await getStorageData("token")
      const header = {
        token: token,
      };
  
      let formData = new FormData();
      if(this.state.file){
        formData.append("drawing_pdf", this.state.file); 
      }
  
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.uploadFileID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_drawing/drawings`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header 
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `POST`
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };
  

 navigateScaling=()=>{
  const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Scaling");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.file);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
 }

 navigateFileDashboard=(projectId:number)=>{
  console.log('sdsdsad',projectId)
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "FileDashboard");
  msg.addData(
    getName(MessageEnum.NavigationPropsMessage),
    {
      ...this.props, 
      projectId: projectId 
    }
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.file);
  msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(msg)
 }

  // Customizable Area End
}
