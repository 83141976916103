// Customisable Area Start
import React from "react";
import clsx from "clsx";
import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  Box,
  Button,
  createTheme,
  Dialog,
  Grid,
  styled,
  ThemeProvider,
  Typography,
  DialogActions
} from "@material-ui/core";
import { ArrowBackIos, PersonAddOutlined ,Clear} from "@material-ui/icons";
import { buildNewIcon, extendIcon, refurbishIcon, remodelIcon } from "./assets";
import Loader from "../../../components/src/Loader";
import AddAndViewMember from "./AddAndViewMember.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem", height:'100vh',
  textAlign: "center",

  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3.5rem",
    '@media (max-width: 700px)': {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    width:'353px',
    fontWeight: "600",
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
    '@media (max-width: 700px)': {
      flexDirection: "column", 
      alignItems: "flex-start"
    },
    
  },
  "& .buttonsBlock": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  "& .addMemberBtn": {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
  },
  "& .addMemberIcon": {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  "& .addMemberText": {
    fontWeight: 600,
    color: "#237182",
  },
  "& .saveDraftBtn": {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none",
    fontFamily:'Poppins',
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
  },
  "& .titleBox":{
      display:'flex',
      flexDirection:'column',
      gap:'12px',
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .planItem": {
    padding: "2rem 1.5rem",
    background: "#FFF",
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    height: "calc(100% - 4rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .planTitle": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      color: "#1E293B",
      margin: "1.5rem 0 1rem",
    },
    "& .planDesc": {
      color: "#475569",
      marginBottom: "1rem",
    },
  },
  "& .selectedScopeItem": {
    border: "1px solid #237182",
  },
  "& .selectScopeBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "none",
      color: "#64748B",
    },
  },
});
const scopes = [
  {
    id: 1,
    icon: buildNewIcon,
    title: "Build New",
    description: "Building a new construction and fitting out",
  },
  {
    id: 2,
    icon: extendIcon,
    title: "Extend",
    description: "Adding on to existing structure and fitting out",
  },
  {
    id: 3,
    icon: remodelIcon,
    title: "Remodel",
    description: "Changing the layout ofa structure or room and fitting out",
  },
  {
    id: 4,
    icon: refurbishIcon,
    title: "Refurbish",
    description:
      "Replacing the finishes and fittings in their existing locations",
  },
];
// Customizable Area End

export default class ProjectScope extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <ThemeProvider theme={theme}>
         <Loader loading={this.state.loading} />
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <StyledBox data-test-id = "styleBox">
            <Box className="pageTitle">
              <Box className="backButton" data-test-id="backBtn"               
              onClick={this.handleBackNavigation2}  
              >
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
               <Box className="titleBox">
               <Typography className="titleText">
                What are the scopes of your project?
              </Typography>
              <Typography className="subTitle">
              You can select more than one
            </Typography>
               </Box>
              <Box className="buttonsBlock">
              <Box style={{
                              display: "flex",
                              gap: "0.5rem",
                              cursor: "pointer",
                                   }}>
                  <Typography  onClick={this.handleOpenViewAndAddMemberDiload} style={{
                                    fontWeight: 600,
                                    color: "#237182",
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Poppins'
                                             }}>
                                    View & Add Member
                                 </Typography>
                </Box>
                <Button className="saveDraftBtn" onClick={this.handleOpenDialog}>Save Draft</Button>
                <Button
                  className="selectScopeBtn"
                  style={{ width: "auto" }}
                  disabled={this.state.multipleScopesSelected.length === 0}
                  data-test-id="next-btn"
                  onClick={()=>this.navigateToShells(this.state.projectId)}
                >
                  Next
                </Button>
              </Box>
            </Box>
           {this.state.scopeCategories.length > 1 ?
                       <Grid container spacing={2} xs={12}>
                       {this.state.scopeCategories.map((plan:any,index:Number) => (
                         <Grid item key={plan.id} sm={3} xs={12}>
                           <Box
                            className={clsx(
                              "planItem",
                              (plan.selected || plan.is_selected) && "selectedScopeItem"
                            )}
                             onClick={() => this.handleScopeClick(plan.id)}
                             data-test-id={`plan_${index}`}
                           >
                             <Box>
                               <img src={plan.icon} />
                               <Typography className="planTitle">
                                 {plan.title}
                               </Typography>
                               <Typography className="planDesc">
                                 {plan.description}
                               </Typography>
                             </Box>
                           </Box>
                         </Grid>
                       ))}
                     </Grid>:''}
          </StyledBox>
          <AddAndViewMember open={this.state.openAddMemberDialog} close={this.handleCloseViewAndAddMemberDiload}  projectId={this.state.projectId} planId={this.state.planId} navigation={undefined} id={""}/>
        </MyProfileHeader>
        <Dialog PaperProps={{
                      style: {
                        height: "232px",
                        width: '584px',
                        borderRadius: "16px",
                        padding: "40px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openDialog} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Do you want to save your draft before exiting?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                          marginTop: "32px",
                          gap: "16px",
                          padding: "0px"
                        }}>
                          <Button style={styleSheet.cancelButton}
                           onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button  data-test-id="savedraftbtn"  style={styleSheet.logoutButton2} 
                          onClick={() => this.selectPropertyTypesAndScopesApiCall1()}>
                            Save Draft
                          </Button></DialogActions>

                      </Box>
                      </Dialog>
                      {this.state.openDialogLeave && <Dialog PaperProps={{
                                                                  style: {
                                                                    height: "232px",
                                                                    width: '584px',
                                                                    borderRadius: "16px",
                                                                    padding: "40px 32px",
                                                                    color: "#FFFFFF",
                                                                    boxSizing:"border-box"
                                                                  }
                                                                }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                                                                  <Box sx={{ padding: 0 }}>
                                                                    <Box style={{
                                                                      display: "flex",
                                                                      justifyContent: "space-between",
                                                                    }}><Typography style={styleSheet.title} >Are you sure you want to leave without saving?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                                                                      color: "black",
                                                                      width: "20px",
                                                                    }}><Clear /></Typography></Box>
                                                                    <DialogActions style={{
                                                                        marginTop: "32px",
                                                                        gap: "16px",
                                                                        padding: "0px"
                                                                    }}>
                                                                      <Button style={{
                                                                          backgroundColor: "#E2E8F0",
                                                                          color: "#325962",
                                                                          fontSize: "16px",
                                                                          fontFamily: "Poppins",
                                                                          textTransform: "none",
                                                                          height: "56px",
                                                                          borderRadius: "8px",
                                                                          fontWeight:600,
                                                                      }}  onClick={()=>this.projectScopeBack()}>
                                                                      Go back without saving 
                                                                      </Button>
                                                                      <Button data-test-id="savedraftbtn"  style={styleSheet.logoutButton}  
                                                                      onClick={() => this.selectPropertyTypesAndScopesApiCall1()}
                                                                        >
                                                                        Save Changes
                                                                      </Button></DialogActions>

                                                                  </Box>
                                                     </Dialog>}
      </ThemeProvider>
    );
  }
}
const styleSheet = {
  stepperText: { fontFamily: 'Poppins', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px'},
   logoutButton: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "15px",
    fontWeight:600,
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "148px",
    padding: "10px 16px 10px 16px",
  } as const,
  logoutButton2: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "121px",
    padding: "10px 16px 10px 16px",
    fontWeight:600,
  } as const,
  cancelButton: {
    backgroundColor: "#E2E8F0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "96px",
    fontWeight:600,
  } as const,
  title: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0F172A",
    fontFamily: "Poppins",
    lineHeight: "32px",
    letterSpacing: "-0.005em"
  }
 }
// Customisable Area End
