import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";

interface ProjectDetails{
  projectName:string;
  clientFirstName:string;
  clientLastName:string;
  clinetEmail : string;
  clientStreetName:string;
  clientCityName: string;
  clientStateName: string | undefined;
  clientCountryName: string | undefined;
  clientPostCode : string;
  totalCost:string;
  adjustedCost:string;
  projectPlanType:any;
  paymentPlanTitle:string;
  payment_plan_data:any;
}
interface adjustCostdata{
    percentage_value: any;
    original_cost: number,
    adjustment_id: number,
    adjustment_type: string,
    adjustment_mode: string,
    discount_amount: number,
    adjusted_cost: number,
}
interface TaskAttributes {
  id: number;
  account_id: number | null;
  name: string;
  created_at: string;
  updated_at: string;
  tasks: Array<{
    id: number;
    title: string;
    description: string;
    status: string;
    priority: string | null;
    created_at: string;
    updated_at: string;
    task_list_id: number;
    assigned_to: string | null;
  }>;
}

interface TaskListDataItem {
  data: Array<{
    id: number;
    type: string;
    attributes: TaskAttributes;
  }>;
}
interface DropdownState {
  confirmedSelectedRooms: any;
  selectedRooms: string[];
  tempSelectedRooms: string[];
  searchText: string;
  isDropdownOpen: boolean;
}

interface Invoice {
  id: string;
  invoiceName: string;
  dueDate: string;
  invoicePrice: string;
  retainedAmount: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  projectId:string;
  planId:string;
  ProjectDetails :ProjectDetails;
  selectedTaskId:string | null;
  roomsAndTaskListsCard: any
  viewAllTasks:boolean;
  openDialog:boolean;
  taskId:string;
  openSelectPaymentDialog:boolean;
  selectPlanValue:string;
  selectPlanIdData:any;
  openMilestonesPaymentPlanDialog:boolean;
  openValuationsPaymentPlanDialog:boolean;
  PaymentOptionChangeValue:string;
  dropdownKeys: string[];
  dropdowns: Record<string, DropdownState>;
  invoices: any;
  Adjusted_Openmodel:boolean;
  Edit_AdjustmentOpenmodel:boolean;
  PaymentPlanDialog:string;
  EditAjustPaymentPlanDialog:string;
  EditAjustPaymentPlanModeDialog:string;
  AdjustAmout:string;
  RemoveAdjustmentOpenmodel:boolean;
  hasError: boolean,
  Discount_Amount:number,
  Total_new_cast:number,
  dummydata: string[];


  mileStoneData:any;
  rententionPercentageValue:any;
  totalCost:number;
  Adjust_cast:string;
  summaryadjustdata :adjustCostdata,
  openDialog1:boolean;
  originalcost:string;
  switchedValue:string;



  // Customizable Area End

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProjectByIdCallId:string = "";
  getprojectapi:string = "";
  getAdjustcostapi:string = "";
  apiGetTaskListCallId:string = "";
  apiGetTaskListByIdCallId:string = "";
  apiGetMileStoneId:string="";
  apiHitOnSelectPayload:string="";
  apihandleRoomCostList:string="";
  apihandleRoomCostPaymentTime:string="";
  apiSaveplanHit:string="";
  saveAdjustCostdataCallId:string="";
  deleteRoomTaskListApiCallId: string="";
  RemoveAdjustCostCallId:string="";
  apiStepStateChangeCalId="";
  getProjectdaterCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

     
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token:'',
      projectId:'',
      planId:'',
      ProjectDetails : {} as ProjectDetails,
      selectedTaskId:null,
      roomsAndTaskListsCard: [],
      viewAllTasks:false,
      openDialog:false,
      openDialog1:false,
      taskId:'',
      openSelectPaymentDialog:false,
      selectPlanValue:'',
      selectPlanIdData:'',
      openMilestonesPaymentPlanDialog:false,
      openValuationsPaymentPlanDialog:false,
      PaymentOptionChangeValue:'pay_before',
      rententionPercentageValue:'',
      dropdownKeys: [],
      dropdowns: {},
      invoices: [],
      Adjusted_Openmodel:false,
      Edit_AdjustmentOpenmodel:false,
      PaymentPlanDialog:"",
      EditAjustPaymentPlanDialog:"add_additional_cost",
      EditAjustPaymentPlanModeDialog:"fixed",
      AdjustAmout:"",
      RemoveAdjustmentOpenmodel:false,
      hasError: false,
      Discount_Amount:0.00,
      Total_new_cast:0.00,
      dummydata:[],
      summaryadjustdata : {} as adjustCostdata,




      mileStoneData:[],
      totalCost:0,
      Adjust_cast:"0.00",
      originalcost:"0.00",
      switchedValue:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetProjectByIdCallId) {
        this.getProjectByIdResponse(responseJson);
         setStorageData("project_status",responseJson.data.attributes.status)       
      }
      if (apiRequestCallId === this.getprojectapi) {
       this.setState({dummydata:responseJson})
       
      }
      if (apiRequestCallId === this.saveAdjustCostdataCallId) {
        if(responseJson.status==="success"){
          this.Adjusted_paymentEditModelclose()          
          this.getProjectDetailsById()
        }
        
       }
    }
    if(apiRequestCallId === this.apiGetMileStoneId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleMileStoneApiData(responseJson)
    }
    if(apiRequestCallId === this.apihandleRoomCostList){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleRoomListData(responseJson)
    }

    if (apiRequestCallId === this.getAdjustcostapi) {
      this.setState({summaryadjustdata:responseJson})
     }
     if (apiRequestCallId === this.RemoveAdjustCostCallId) {
      if(responseJson.status==="success"){
        this.getProjectDetailsById()
      }
     
     }
     if(apiRequestCallId==this.apiSaveplanHit){
      if(responseJson.errors || responseJson.error){
      toast.error('Project room id is already associated with another invoice.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
     }
     else {this.getProjectDetailsById()}
    }
   
    
  }
  this.receive1(message);
  this.receive2(message);
   // Customizable Area End
    }
  // Customizable Area Start
 
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListCallId) {
          this.getTaskListResponse(responseJson);
         
        }
        if (apiRequestCallId === this.deleteRoomTaskListApiCallId) {
            this.getTaskList();
        }
      }
      if (apiRequestCallId === this.apiStepStateChangeCalId) {
        this.props.navigation.navigate("ProjectPortfolio");
      }
  
     
    }

  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListByIdCallId) {
          this.getTaskListByIdResponse(responseJson); 
        }
      }
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apihandleRoomCostPaymentTime) {
          this.handleRoomListData(responseJson); 
          this.handleResponseListDropDown(responseJson)
        }
      }    
    }

  }
  async componentDidMount() {
    let token = await getStorageData("token");
    this.setState({ token: token });
    this.getIds();
    this.getProjectDetailsById();
    this.getTaskList();
    this.getMileStoneApisCall();
    this.updateProjectdate()
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleResponseListDropDown =(responseJson:any)=>{

    const apiResponse:any = responseJson?.data?.data?.map((item:any) => ({
      id: item.id,
      invoiceName: item.attributes.room_name,
      dueDate: item.attributes.due_date ? item.attributes.due_date : null, 
      invoicePrice: item.attributes.room_cost.room_cost.toString(), 
      retainedAmount: item.attributes.room_cost.retention_amount === 0 ? 'Auto-calculate' : item.attributes.room_cost.retention_amount.toString(),
    }));

    const dropdownKeys = apiResponse?.map((invoice:any) => invoice.id);
    const dropdowns = dropdownKeys.reduce((acc: Record<string, DropdownState>, key:any) => {
      acc[key] = {
        selectedRooms: [],
        tempSelectedRooms: [],
        searchText: '',
        isDropdownOpen: false,
        confirmedSelectedRooms:[]
      };
      return acc;
    }, {});

    this.setState({ dropdownKeys, dropdowns });
  }
  getIds() {
    let url = window.location.pathname;
    let parts = url.split("/");
  
    if (parts.length >= 3) {
      const planId = parts[2];
  
      if (parts.length >= 5 && parts[3] === "project_id") {
        const projectId = parts[4];
        this.setState({ planId: planId, projectId: projectId });
        return { planId, projectId };
      }
     
      this.setState({ planId: planId, projectId: '' });
      return { planId, projectId: null };
    }
    return { planId: null, projectId: null };
  }

async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedTaskId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedTaskId: null });
  }
};
handleOpenActiaonTab = (id: string) => {
    this.setState(prevState => ({
        selectedTaskId: prevState.selectedTaskId === id ? null : id
    }));
}
handleDeleteTaskList=(task_id:any)=>{
    this.setState({openDialog:true,taskId:task_id});
}
  handleEditTaskList = () => {
    this.backNavigation()
  };
getProjectDetailsById=()=>{
  const header = {
  "Content-Type": configJSON.formDataContent,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetProjectByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getMileStoneApisCall=()=>{
  const header = {
    "Content-Type": configJSON.formDataContent,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetMileStoneId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.mileStoneOptionsApi}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskList=()=>{
  const header = {
  "Content-Type": configJSON.getContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskRoomListApiEndPoint}/${this.state.projectId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskById=(taskId:string)=>{
  const header = {
  "Content-Type": configJSON.formDataContent,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskByIdAPIEndPoint}/${taskId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProjectByIdResponse=(responseJson:any)=>{
  const country = Country.getCountryByCode(responseJson.data.attributes.addressable.country);
  const countryName = country?.name
  const state = State.getStateByCodeAndCountry(responseJson.data.attributes.addressable.state, responseJson.data.attributes.addressable.country);
  const stateName = state?.name

  this.setState((prevState) => ({
    ProjectDetails: {
      ...prevState.ProjectDetails,
      projectName: responseJson.data.attributes.project_name,
      clientFirstName: responseJson.data.attributes.addressable.name,
      clientLastName: responseJson.data.attributes.addressable.last_name,
      clinetEmail: responseJson.data.attributes.addressable.email,
      clientStreetName: responseJson.data.attributes.addressable.address,
      clientCityName: responseJson.data.attributes.addressable.city,
      clientStateName: stateName,
      clientCountryName: countryName,
      clientPostCode: responseJson.data.attributes.addressable.post_code,
      totalCost: responseJson.data.attributes.total_cost,
      adjustedCost:responseJson.data.attributes.adjusted_cost,
      projectPlanType:responseJson.data.attributes.project_plan_id,
      paymentPlanTitle:responseJson.data.attributes.project_plan?.title || "N/A",
      payment_plan_data:responseJson.data.attributes.payment_plan_data
    },
    totalCost:responseJson.data.attributes.total_cost,
    Adjust_cast:responseJson.data.attributes.adjusted_cost,
    originalcost:responseJson.data.attributes.original_cost
  }));
  
}

handleMileStoneApiData=(data:any)=>{
  const value = data.data;
  this.setState({
    mileStoneData:value,
  })
}

getTaskListResponse = (responseJson: any) => {
  if (responseJson.errors?.[0]?.token) {
    alert("Token is expired, Please login !!!");
    this.props.navigation.navigate('EmailAccountLoginBlock');
  } else {
    this.setState({
      roomsAndTaskListsCard: responseJson.data.data,
    });
  }
};
getTaskListByIdResponse = (responseJson:any)=>{
  // console.warn(responseJson);
}
handelViewAll = ()=>{
  this.setState((prevState) => ({ viewAllTasks: !prevState.viewAllTasks }));
}
handleCloseDialog=()=>{
  this.setState({openDialog:false})
}


handleStepStateApi=()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiStepStateChangeCalId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_profile/projects/${this.state.projectId}/update_project_steps?step=20`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify("")
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  handleOpenDialog = () => {
    this.setState({ openDialog1: true })
  }
  handleCloseDialog1 = () => {
    this.setState({ openDialog1: false })

  }

handleConfirmDelete = async () => {
   this.deleteRoomTaskListApiCallId = await this.deleteTaskListApiCall({
        contentType: configJSON.getContentType,
        endPoint: `/bx_block_profile/project_rooms/${this.state.taskId}`,
        method: "DELETE",
      });
  this.setState({openDialog:false,taskId:''});
}

deleteTaskListApiCall = async (ApiCallData: any) => {
  const { contentType, method, endPoint, body } = ApiCallData;
  const token = await getStorageData("token");
  const header = {
    'Content-Type': contentType,
    "token": token
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  body &&  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
handleOpenSelectPaymentDialog=()=>{
  this.setState({openSelectPaymentDialog:true});
}
handleCloseSelectPaymentPlanDialog=()=>{
  this.setState({openSelectPaymentDialog:false});
}
handleRadioChange = (value:any,id:any) => {
  this.setState({
    selectPlanValue:value,
    selectPlanIdData:id
  });
};
handleCloseMilestonesPaymentPlanDialog=()=>{
  this.setState({openMilestonesPaymentPlanDialog:false,PaymentOptionChangeValue:'pay_before',selectPlanValue:''});
}
handleCloseValuationsPaymentPlanDialog=()=>{
  this.setState({openValuationsPaymentPlanDialog:false,PaymentOptionChangeValue:'pay_before',selectPlanValue:'',});

}
handleSubmitSelectPaymentPlan = (event:any) =>{
  event.preventDefault();
  if (this.state.selectPlanValue === 'Milestones') {
    this.setState({openSelectPaymentDialog:false,openMilestonesPaymentPlanDialog:true})
  } else if (this.state.selectPlanValue === 'Valuations') {
    this.setState({openSelectPaymentDialog:false,openValuationsPaymentPlanDialog:true})
  }
  this.handleHitPutApiForPlan()
  this.handleRoomCostListAPI()
}

handleSubmit = (values: any) =>{
  const responseValue = this.state.invoices?.map((val:any)=>{

    const IdValue = Number(val.id)

    const inputDate = val.attributes?.due_date;
    const [day, month, year] = inputDate.split("/");
    const fullYear = year.length === 2 ? `20${year}` : year;
    const formattedDate = `${day}/${month}/${fullYear}`;
    
    return{
        project_room_ids:[IdValue],
        retained_amount:  val.attributes?.room_cost.retention_amount ,
        due_date: formattedDate,
        total_amount: val.attributes?.room_cost.room_cost  
    }
  })

  const payload ={
    project_invoice:{
      retention_percentage:Number(this.state.rententionPercentageValue),
      payment_date_option:this.state.PaymentOptionChangeValue,
      invoices:responseValue
    }
  }

  const header = {
    "Content-Type": configJSON.formDataContent,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiSaveplanHit = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savePlanHitAPI}/${this.state.projectId}`
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  if(this.state.switchedValue=='Valuations'){
    this.setState({selectPlanIdData:2})
  }else this.setState({selectPlanIdData:1})
  this.handleHitPutApiForPlan()
  this.getProjectDetailsById()

  
  this.setState({
    openMilestonesPaymentPlanDialog:false,
    openValuationsPaymentPlanDialog:false,
    rententionPercentageValue:'',
    PaymentOptionChangeValue:'pay_before'
  })
}
handlePaymentOptionChange = (value: string) => {

  if (value === 'pay_after') {
    this.setState((pre) => {
      return {
        PaymentOptionChangeValue: 'pay_after',
        rententionPercentageValue: pre.rententionPercentageValue
      }
    });
  } else if (value === 'pay_before') {
    this.setState((pre) => {
      return {
        PaymentOptionChangeValue: 'pay_before',
        rententionPercentageValue: pre.rententionPercentageValue
      }
    })
  }
  this.handlePaymentOptionStatusApi(value, this.state.rententionPercentageValue);
}

handlePaymentOptionStatusApi =(value:any,retentionValue:any)=>{
  const header = {
    "Content-Type": configJSON.formDataContent,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apihandleRoomCostPaymentTime = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.roomCostListAPI}/${this.state.projectId}?due_date=${value}&retention=${retentionValue}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
 
  handleDropdownOpen = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          isDropdownOpen: true,
          tempSelectedRooms: prevState.dropdowns[key]?.tempSelectedRooms || [],
        },
      },
    }));
  };

  handleDropdownClose = (invoiceId: string) => {
    this.setState((prevState) => {
      const tempSelectedRooms = prevState.dropdowns[invoiceId]?.tempSelectedRooms || [];
  
      return {
        dropdowns: {
          ...prevState.dropdowns,
          [invoiceId]: {
            ...prevState.dropdowns[invoiceId],
            isDropdownOpen: false,
            searchText: '',
            confirmedSelectedRooms: tempSelectedRooms, // Store confirmed selections
          },
        },
      };
    });
  };
  

  handleCheckboxToggle = (invoiceId: string, roomId: string) => {
    this.setState((prevState) => {
      const selectedRooms = prevState.dropdowns[invoiceId]?.tempSelectedRooms || [];
      const newSelectedRooms = selectedRooms.includes(roomId)
        ? selectedRooms.filter((id) => id !== roomId)
        : [...selectedRooms, roomId];
  
      return {
        dropdowns: {
          ...prevState.dropdowns,
          [invoiceId]: {
            ...prevState.dropdowns[invoiceId],
            tempSelectedRooms: newSelectedRooms,
          },
        },
      };
    });
  };
  

  handleSearchChange = (key: string, searchText: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          searchText,
        },
      },
    }));
  };

  retentionPercentageData =(value:any)=>{
    if(this.state.rententionPercentageValue){
       const multiply = ((this.state.rententionPercentageValue)*value)/100
       return multiply.toFixed(2)
    }
    else{
      return 0
    }
  }

  amountPercentageData =(value:any)=>{
    if(value !== undefined && value !== null && value !== ""){
      const matchedObjects = this.state.invoices.length > 0 && this.state.invoices.filter((obj:any) =>
        value.includes(obj.id)
      );
      const totalRoomCost = matchedObjects.reduce((sum:any, item:any) => sum + parseFloat(item?.attributes?.room_cost?.room_cost), 0);
      return totalRoomCost.toFixed(2).toString()
    }
    else {
      return  "-"
    }
    
  }

  handleAdd = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          selectedRooms: [...prevState.dropdowns[key].tempSelectedRooms],
          isDropdownOpen: false,
          searchText: '',
        },
      },
    }));
  };

  handleCancel = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          tempSelectedRooms: [],
          isDropdownOpen: false,
          searchText: '',
          selectedRooms:[]
        },
      },
    }));
  };

  handletotalInvoiceValues =()=>{
    const invoices = Object.values(this.state.dropdowns).map((room: any) => {


      const matchedObjects = this.state.invoices.filter((obj:any) =>
        room.selectedRooms.includes(obj.id)
      );

     const  totalRoomCost =matchedObjects?.reduce((sum:any, item:any) => sum + item?.attributes.room_cost.room_cost, 0)
 
     return totalRoomCost
    
    });
  }

  handleSubmitValuation=()=>{
      const invoices = Object.values(this.state.dropdowns).map((room: any) => {


        const matchedObjects = this.state.invoices.filter((obj:any) =>
          room.selectedRooms.includes(obj.id)
        );

       const  totalRoomCost =matchedObjects?.reduce((sum:any, item:any) => sum + item?.attributes.room_cost.room_cost, 0)

     
        return {
          project_room_ids: room.selectedRooms,
          retained_amount:Number((this.state.rententionPercentageValue*totalRoomCost)/100),
          due_date:matchedObjects[0]&& matchedObjects[0].attributes.due_date,
          total_amount:Number(totalRoomCost.toFixed(2))
        };
      });
    
    
      const payload ={
        project_invoice:{
          retention_percentage:Number(this.state.rententionPercentageValue),
          payment_date_option:this.state.PaymentOptionChangeValue,
          invoices:invoices
        }
      }
  
      const header = {
        "Content-Type": configJSON.formDataContent,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiSaveplanHit = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.savePlanHitAPI}/${this.state.projectId}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(payload)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      this.getProjectDetailsById()

      this.setState({
        openMilestonesPaymentPlanDialog:false,
        openValuationsPaymentPlanDialog:false,
        rententionPercentageValue:'',
        PaymentOptionChangeValue:'pay_before'
      })


  }

  handleHitPutApiForPlan=()=>{
    const header = {
      "Content-Type": configJSON.formDataContent,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiHitOnSelectPayload = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nextBtnClickProjectId}/${this.state.projectId}?plan_id=${this.state.selectPlanIdData}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRoomCostListAPI=()=>{
    const header = {
      "Content-Type": configJSON.formDataContent,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apihandleRoomCostList = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.roomCostListAPI}/${this.state.projectId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    console.log(requestMessage,'requestMessage')
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRoomListData = (responseJson: any) => {
    const dataValue = responseJson.data.data;
    const roomCountMap = new Map<string, number>();
  
    const updatedData = dataValue.map((item: any) => {
      // Format due_date
      const dueDate = item.attributes.due_date ? new Date(item.attributes.due_date) : new Date();
      const day = ("0" + dueDate.getDate()).slice(-2);
      const month = ("0" + (dueDate.getMonth() + 1)).slice(-2);
      const year = dueDate.getFullYear().toString().slice(-2);
      const newDate = `${day}/${month}/${year}`;
  
      // Handle room name series
      const originalRoomName = item.attributes.room_name;
      let count = roomCountMap.get(originalRoomName) || 0;
  
      // First occurrence remains unchanged, subsequent ones get a number
      const newRoomName = count === 0 ? originalRoomName : `${originalRoomName} ${count}`;
  
      // Update the map count
      roomCountMap.set(originalRoomName, count + 1);
  
      return {
        ...item,
        attributes: {
          ...item.attributes,
          due_date: newDate,
          room_name: newRoomName, // Updated room name with correct series
        },
      };
    });
  
    this.setState({
      invoices: updatedData,
    });
  
  };
  
  
  

  handleSwitchPaymentPlan = (value:string) =>{
    this.handlePaymentOptionStatusApi(this.state.PaymentOptionChangeValue, this.state.rententionPercentageValue);
    if (value === 'Milestones') {
      this.setState({openValuationsPaymentPlanDialog:false,openMilestonesPaymentPlanDialog:true, switchedValue:value})
    } else if (value === 'Valuations') {
      this.setState({openMilestonesPaymentPlanDialog:false,openValuationsPaymentPlanDialog:true,switchedValue:value})
    } 
  }
  editPlan=()=>{
    this.setState({openValuationsPaymentPlanDialog:true,switchedValue:'Valuations'})
    this.handleRoomCostListAPI();
  }

  backNavigation = ()=>{
    const pathname = window.location.pathname
    this.backBtnQuoteOverviewClicked()
    const { planID , projectID }= this.extractIdsFromUrl(pathname)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${planID}/project_id/${projectID}`);
    this.send(message);
  }


  extractIdsFromUrl = (url:string)=>{
    const paths = url.split("/")
    const planID = paths[2]
    const projectID = paths[4]
    return {planID,projectID}
  }

  backBtnQuoteOverviewClicked = async ()=>{
    await setStorageData("backBtnQuoteOverviewClick",JSON.stringify(true))
  }
  AdjustpaymentOpenmodel(){
    this.setState({Adjusted_Openmodel:true})
  
  }
  Adjusted_paymentModelclose=()=>{
    this.setState({Adjusted_Openmodel:false})
    this.RemoveAdjustOpenmodel()
    
  }
  SummaryModelclose=()=>{
    this.setState({Adjusted_Openmodel:false})
  }
  AdjustpaymentConfirm(){
    this.setState({Edit_AdjustmentOpenmodel:true})
    this.setState({Adjusted_Openmodel:false})
  
  }
  AdjustpaymenEdittOpenmodel(){
    this.setState({
      EditAjustPaymentPlanDialog:"add_additional_cost",
      EditAjustPaymentPlanModeDialog:"fixed",
    })
    if(this.state.Adjust_cast === "+£0.00"){
      this.setState({Edit_AdjustmentOpenmodel:true})
    }else{
      this.getAdjustmentcost()
      this.AdjustpaymentOpenmodel()

    }
  
  }
  Adjusted_paymentEditModelclose=()=>{
    this.setState({Edit_AdjustmentOpenmodel:false,
      EditAjustPaymentPlanDialog:"",
      EditAjustPaymentPlanModeDialog:"",
      AdjustAmout:"",
          hasError: false, 
          Discount_Amount: 0,
          Total_new_cast: 0,
    })
  }
  AdjustpaymentEditConfirm(){
  this.saveAdjustcoastData()
  this.getProjectDetailsById()
  }
  EdithandlePaymentOptionChange=(value:string)=>{
    this.setState({
      Discount_Amount: 0,
            Total_new_cast: 0,
    })
    if(value==='add_discount'){
      this.setState({EditAjustPaymentPlanDialog:'add_discount',hasError:false});
    }else if(value==='add_additional_cost'){
      this.setState({EditAjustPaymentPlanDialog:'add_additional_cost',hasError:false});
    }
  }
SelectPaymentOptionModeChange=(value:string)=>{
  this.setState({
    Discount_Amount: 0,
          Total_new_cast: 0,
  })
    if(value==='percentage'){
      this.setState({EditAjustPaymentPlanModeDialog:'percentage',AdjustAmout:"",hasError:false});
    }else if(value==='fixed'){
      this.setState({EditAjustPaymentPlanModeDialog:'fixed',AdjustAmout:"",hasError:false});
    }
  }
  calculatePercentage(value: any | number, percentage: number) {
    return (value * percentage) / 100;

}
handleAdjustCost = (value: string) => {
  if (/^\d{0,9}(\.\d{0,2})?$/.test(value)) {
    this.AdjustAmoutInputChange(value);
  }
};
AdjustAmoutInputChange = (value: string) => {
  let originValue = this.state.EditAjustPaymentPlanModeDialog === "percentage" ? 99 : this.state.totalCost;
  const numericValue = parseFloat(value) || 0;
  if (isNaN(numericValue)) {
    this.setState({ AdjustAmout: value, hasError: true });
    return;
  }
  originValue = Number(originValue);
  let totalNewCast = 0;
if(this.state.EditAjustPaymentPlanDialog === "add_discount"){
  if (this.state.EditAjustPaymentPlanModeDialog === "percentage") {
    const percentageValue = this.calculatePercentage(numericValue, this.state.totalCost);
    totalNewCast = this.state.totalCost - percentageValue;
    this.setState({
      AdjustAmout: value,
      Discount_Amount: percentageValue,
      Total_new_cast: totalNewCast,
    });
    
    if(percentageValue===0){
      this.setState({
        AdjustAmout: value,
        Discount_Amount: percentageValue,
        Total_new_cast: 0,
      });
    }
  } else {
    totalNewCast = originValue - numericValue;
    this.setState({
      AdjustAmout: value,
      Discount_Amount: numericValue,
      Total_new_cast: totalNewCast,
    });
  }
}else{
  if (this.state.EditAjustPaymentPlanModeDialog === "percentage") {
    let percentageValue = this.calculatePercentage(numericValue, this.state.totalCost);
    percentageValue= Number(percentageValue)
let data =Number(this.state.totalCost)
    totalNewCast = data + percentageValue;
    this.setState({
      AdjustAmout: value,
      Discount_Amount: percentageValue,
      Total_new_cast: totalNewCast,
    });
  } else {
    totalNewCast =  Number(originValue) + numericValue;    
    this.setState({
      AdjustAmout: value,
      Discount_Amount: numericValue,
      Total_new_cast: totalNewCast,
    });
  }
}
 

  this.setState({
    hasError: numericValue > originValue || numericValue <= 0,
  });
  if(value===""){
    this.setState({
      hasError: false,
    });
  }
};


  RemoveAdjustOpenmodel(){
    this.setState({RemoveAdjustmentOpenmodel:true})

  }
  REmoveAdjustedModelclose=()=>{
    this.setState({RemoveAdjustmentOpenmodel:false})
  }
  RemoveAdjustConfirm(){
    this.RemoveAdjustCost()
    this.REmoveAdjustedModelclose()
   
  }
  GenerateQuote = ()=>{
    
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectProposalgeneration"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), "");
    this.send(message);
  }
  getprojectapiid=()=>{
    const header = {
    "Content-Type": configJSON.formDataContent,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getprojectapi = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveAdjustcoastData = async () => {
      const project_id = await getStorageData("project_id");
             const header = {
              "Content-Type":"application/json",
              token: this.state.token,
            };
      const body = {
          "adjustment_type": this.state.EditAjustPaymentPlanDialog,
          "adjustment_mode": this.state.EditAjustPaymentPlanModeDialog,
          "discount_amount": this.state.AdjustAmout
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.saveAdjustCostdataCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_centralized_billings/project_cost_adjustments/${project_id}/adjust_cost`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      
    
  };
  
  formatCost = (amount: any) => {
    if (typeof amount !== "number") {
      amount = parseFloat(amount);
      if (isNaN(amount)) return "Invalid number";
    }
    return amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getAdjustmentcost=()=>{
    const header = {
    "Content-Type": configJSON.formDataContent,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAdjustcostapi = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getAdjustcostByIdAPIEndPoint}/${this.state.projectId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  RemoveAdjustCost  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.RemoveAdjustCostCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_centralized_billings/project_cost_adjustments/${this.state.projectId}/reset`

    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateProjectdate = async () => {
    
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectdaterCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/${this.state.projectId}/update_start_end_date`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}