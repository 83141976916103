import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Paper,
  Grid,
  TextField,
  Modal,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AddCircleOutline, Delete, Edit } from '@material-ui/icons';
import { calendar } from "./assets";
import { frameIcon } from "../../projectportfolio/src/assets";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { CardActions, CardContent, MenuItem, Box, Select } from "@mui/material";
import { MoreVert, DateRange, PlaceOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import MyProfileHeader from "../../../components/src/MyProfileHeader";

// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
  configJSON,
  ProposalType
} from "./Proposalgeneration2Controller";


export default class Proposalgeneration2 extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderIndividualProposalCard = (proposalItem: ProposalType) => {
    return (
      <Paper elevation={3} style={styles.individualContainer}>
        <div style={styles.header}>
          <Typography variant="h6" style={styles.titleText}>
            {proposalItem.attributes.title}
          </Typography>
          <Typography variant="body1" style={styles.priceText as React.CSSProperties}>
            {proposalItem.attributes.price}
          </Typography>
        </div>
        <Typography variant="body2" style={styles.creatorText}>
          By {proposalItem.attributes.name_of_proposal_creator} |{' '}
          {proposalItem.attributes.date_of_proposal}
        </Typography>
        <Typography variant="body2" style={styles.descText}>
          {proposalItem.attributes.description}
        </Typography>
        <div style={styles.flexCenter as React.CSSProperties}>
          <Button
            variant="outlined"
            color="primary"
            data-testid='edit-btn'
            startIcon={<Edit />}
            onClick={this.editProposals.bind(this, proposalItem)}
            style={styles.editButton}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            data-testid='view-btn'
            color="secondary"
            onClick={this.shareModalFun.bind(this, proposalItem)}

            style={styles.viewButton}
          >
            View
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Delete />}
            onClick={this.openDeletePopup.bind(this, proposalItem.id)}

            style={styles.deleteButton}
          >
            Delete
          </Button>
        </div>
      </Paper>
    );
  };
  customIcon = (props: any) => {
    return <KeyboardArrowUpIcon {...props} style={{ color: "#237182" }} />;
  };

  // Customizable Area End

  render() {
    return (

      // Customizable Area Start
      <>
      <MyProfileHeader navigation={this.props.navigation}>
        <Box style={{ backgroundColor: '#F0F5F7' }}>
          <Box
            style={{
              height: '55px',
              borderRadius: '8px',
              borderBottom: '1px solid #E2E8F0',
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              gap: '14px',
              margin: '25px 25px 10px 25px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <CustomTextField
          variant="outlined"
          placeholder="Search..."
          size="small"
           value={this.state.searchInputText} 
           onChange={(e) => this.handleSearchInputChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ color: "#64748B" }} />
              </InputAdornment>
            ),
          }}
        />
        
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                Sort by:
              </Typography>
              <Select
                value={this.state.sortOption}
                onChange={this.handleChange}
                displayEmpty
                style={{
                  marginLeft: "5px",
                  color: "#237182",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                variant="standard"
                disableUnderline
                IconComponent={this.customIcon}
              >
                <MenuItem value="Earliest Deadline First">Earliest Deadline First</MenuItem>
                <MenuItem value="Latest Deadline First">Latest Deadline First</MenuItem>
                <MenuItem value="Highest Cost First">Highest Cost First</MenuItem>
                <MenuItem value="Lowest Cost First">Lowest Cost First</MenuItem>
              </Select>
            </div>
          </Box>
            {
              ((!this.state.sortedAndFilteredProjects || this.state.sortedAndFilteredProjects.length === 0))
                ? (
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#F0F5F7" }}>
                    <img src={frameIcon} alt="" width='457.46px' height='350px' />
                    <Box style={webStyle.chatGroupNotFound}>
                      <p style={webStyle.noMessage}>
                        No Results Found
                      </p>
                      <p style={webStyle.noMessageSub}>
                        We couldn't find what you searched for. Try searching again.
                      </p>
                    </Box>
                  </Box>
                ) :
                                        <Grid container spacing={2} style={{ margin: '0px 20px' }}>
           {this.state.sortedAndFilteredProjects.map((project: any) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
      <ProjectCard>
        <CardContent
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
            padding: 0,
          }}
        >
          <Box
          onClick={() => this.handleReviewNavigation(project)}
          >
            <TextTruncate>{project.attributes.project_data.name}</TextTruncate>

            <Box style={{ display: "flex", alignItems: "center", gap: "4px", paddingTop: '8px' }}>
              <CurrencyPoundIcon style={webStyle.icons} />
              <Typography style={webStyle.subText}>
                {Number(project.attributes.project_data.total_cost).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Box>
            <Box >
              <BoxContainer>
                {project.attributes.project_data.status == "Accepted" &&
                  <Button style={{
                    marginTop:"10px",
                    padding: '2px 8px',
                    borderRadius: '40px',
                    color: '#059669',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    lineHeight: '16px',
                    backgroundColor: '#D1FAE5',
                  }}>Approved</Button>
                }

                {project.attributes.project_data.status == "Rejected" &&
                  <Button style={{
                    marginTop:"10px",
                    padding: '2px 8px',
                    borderRadius: '40px',
                    color: '#DC2626',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    lineHeight: '16px',
                    backgroundColor: '#FEE2E2',
                  }}>Rejected</Button>
                }

                {project.attributes.project_data.status == "Pending" &&
                  <Button style={{
                    marginTop:"10px",
                    padding: '2px 8px',
                    borderRadius: '40px',
                    color: '#D97706',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    lineHeight: '16px',
                    backgroundColor: '#FEF3C7',
                  }}>Pending</Button>
                }


              </BoxContainer>
            </Box>
            <Box style={webStyle.displayGap}>
              <DateRange style={webStyle.icons} />
              <Typography style={webStyle.subText}>
                {this.formatDateRange(project.attributes.project_data.start_dat)}{' - '}
                {this.formatDateRange(project.attributes.project_data.end_date)}
              </Typography>
            </Box>
            <Box style={webStyle.displayGap}>
              <PlaceOutlined style={webStyle.icons} />
              <Typography style={webStyle.subText}>
              {project.attributes.project_data.address.address + " " +project.attributes.project_data.address.city}, {project.attributes.project_data.address.country_name}
              </Typography>
            </Box>
            <Box style={webStyle.displayGap}>
              <PersonOutlineOutlined style={webStyle.icons} />
              <Typography style={webStyle.subText}>
                <span>
                  {project.attributes.sender_details.first_name + ' ' + project.attributes.sender_details.last_name}
                </span>
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions
          disableSpacing
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
            alignItems: "flex-end",
          }}
        >
          <MoreOption
            style={{ padding: "0px" }}>
            <MoreVert style={{ width: "28px", height: "28px", color: "#475569" }} />
          </MoreOption>
        </CardActions>
      </ProjectCard>
    </Grid>

  ))
           }
            </Grid> 

            }
        </Box>
        </MyProfileHeader>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomDatePicker = styled(DatePicker)({
  borderRadius: '5px',
  background: '#FFFFFF',
  '& input[type="text"]': {
    borderBottom: 'none',
    padding: 28,
    color: '#000000',
    cursor: 'pointer',
  },
  '& input::placeholder': {
    color: '#594f4f !important',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 10,
  },
  '& img': {
    cursor: 'pointer',
  },
})
const styles = {
  individualContainer: {
    border: '1px solid #F1EBEB',
    marginBottom: '20px',
    borderRadius: '8px',
    padding: '12px',
  },
  scrollableContainer: {
    overflowY: 'auto',
  },
  labelStyles: {
    color: '#000000',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '16px',
    color: '#000000',
  },
  priceText: {
    fontSize: '16px',
    color: '#00A36C',
    textAlign: 'right',
  },
  creatorText: {
    fontSize: '12px',
    marginBottom: '5px',
    color: '#000000',
    width: '80%',
  },
  descText: {
    fontSize: '12px',
    marginBottom: '5px',
    color: 'grey',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  editButton: {
    padding: '4px 18px',
    border: '1px solid #00A36C',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#00A36C',
    cursor: "pointer"
  },
  viewButton: {
    padding: '4px 18px',
    border: '1px solid #15bdff',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#15bdff',
    cursor: "pointer"
  },
  deleteButton: {
    padding: '4px 18px',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#FF0000',
  },
  title: {
    fontSize: '20px',
    marginTop: '26px',
    color: '#000000',
    marginBottom: '26px',
    cursor: "pointer"
  },
  createTitle: {
    fontSize: '30px',
    marginVertical: '10px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '60px',
  },
  propTitle: {
    fontSize: '30px',
    marginVertical: '10px',
    color: '#000000',
    textAlign: 'center',
    paddingTop: '10px',
  },
  textInputdesStyles: {
    backgroundColor: '#FFFFFF'
  },
  datePickerStyle: {
    height: "20px",
    background: "#FFFFFF"
  },
  modalContainer: {
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalContainer1: {
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    maxWidth: '600px',
    borderRadius: '10px',
    boxShadow: '0px 0px 2px 1px #5b5454',
  },
  cancelButton: {
    padding: '4px 18px',
    border: '1px solid #EE5353',
    borderRadius: '8px',
    margin: '7px 8px',
    backgroundColor: 'EE5353',
    color: '#000000',
  },
  saveButton: {
    padding: '4px 18px',
    border: '1px solid #00A36C',
    borderRadius: '8px',
    margin: '7px 8px',
    backgroundColor: '#00A36C',
    color: '#000000',
  },
  modal: {
    display: "flex",
    overflowY: 'auto',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#FFFFFF',
  },
  deleteConfirmText: {
    textAlign: "center",
    marginBottom: "20px",
  },
  cancelButton1: {
    padding: '4px 18px',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#FF0000',
  },
  redAlert: {
    fontSize: 12,
    paddingVertical: 4,
    color: 'red'
  },
};


const ProjectCard = styled(Box)({
  background: '#fff',
  borderRadius: '8px',
  padding: '16px',
  boxShadow: '0px 2px 8px 0px #00000014',
  position: 'relative',
  display: "flex",
  cursor: "pointer",
  borderTop: "4px solid #F59E0B",
  height: '180px',
  gap: '10px',
});

const TextTruncate = styled(Typography)({
  color: '#0F172A',
  fontSize: '16px',
  fontWeight: 600,
  fontFamliy: 'Poppins',
  textAlign: 'left',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  maxHeight: '3rem',
})

const webStyles = {
  renameModal: {
    margin: "auto",
    height: "fit-content",
    padding: "2.5rem 2rem",
    width: "40vw",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  leave_chat_sub_heading: {
    fontFamily: "poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1E293B",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontFamily: "poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A"
  },
}
const webStyle = {
  displayGap: {
    display: 'flex',
    gap: '4px',
    marginTop: '8px'
  },
  projectTitle: {
    color: '#0F172A',
    fontSize: '16px',
    fontWeight: 600,
    fontFamliy: 'Poppins',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    maxHeight: '3rem',
  },
  chatGroupNotFound: {
    width: '#1E293B',
    height: '76px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '8px'
  },

  noMessage: {
    textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    margin: 0,
    padding: 0,
    color: '#1E293B',
    width: '457.46px'
  },
  noMessageSub: {
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    margin: 0,
    padding: 0,
    textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
    width: '457.46px'

  },
  subText: {
    color: '#64748B',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400
  },
  moreIcon: {
    color: '#475569',
    position: 'absolute',
    top: 20,
    right: 5
  },
  icons: {
    color: '#64748B',
    width: '20px',
    height: '20px'
  },
  downloadText: {
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '8px'
  },
}

const MoreOption = styled(Box)({
  cursor: 'pointer',
  padding: "3px",
  "&:hover": {
    background: "#D3E4E9",
    borderRadius: "50%",

  },
})

const ProjectOption = styled(Box)({
  '& .MuiList-padding': {
    paddingTop: '3px',
    paddingBottom: '3px'
  },
  '& .MuiPaper-elevation8': {
    boxShadow: 'unset',
    border: '1px solid #E0EDF0'
  },
  "& .MuiButtonBase-root": {
    borderRadius: "8px",
    padding: "8px 16px",
    "&:hover": {
      background: "#E0EDF0",
      borderRadius: "8px",
      padding: "8px 16px",

    }
  }
})

const BoxContainer = styled(Box)({
  position: 'absolute',
  right: '25px',
  marginTop: '-30px',
  justify: 'space-between',
})
const CustomTextField = styled(TextField)({
  width: "300px",
  height: "44px",
  borderRadius: "8px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#d1d5db !important",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#d1d5db !important", 
  },

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#d1d5db !important",
  },
})
// Customizable Area End
