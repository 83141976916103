import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  TextField,
  Divider,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { backArrowIcon, cursorIcon, handIcon, lineIcon, rectangleIcon, lineMiniIcon } from "./assets";
import MessageBox from "../../../components/src/messageBox";
import Loader from "../../../components/src/Loader.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { DeleteOutline } from "@material-ui/icons";
// Customizable Area End

import ScalingController, {
  Props,
} from "./ScalingController.web";


export default class Scaling extends ScalingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, totalPages, zoom } = this.state;
    const { tool, pixels, realWorldDistance, unit, endpoint1, endpoint2 } = this.state;
    const boxStyle = (currentTool: string) => ({
      backgroundColor: tool === currentTool ? '#E0EDF0' : '#ffffff',
      padding: '10px',
      borderRadius: '8px',
      cursor: 'pointer',
      display: 'inline-block',
    });
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Loader loading={this.state.loader}/>
        {this.state.isError && <MessageBox data-test-id="toastMessage" message={this.state.errorMsg} status={this.state.status}/>}
          <MyProfileHeader navigation={this.props.navigation} tabName={'Scaling&Drawings'} />
          <Box sx={{ display: 'flex', bgcolor: '#FFFFFF', border: '1px solid #E2E8F0', justifyContent: 'space-between', padding: '8px 24px 8px 16px' }}>
            <Box style={{ display: 'flex', gap: '26px', alignItems: 'center' }}>
              <Box data-test-id="handleGoBack" onClick={this.handleGoBack}>
                <img src={backArrowIcon} alt='arrow' />
              </Box>
              <Box>
                <Typography style={{
                  color: '#0F172A',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}>{this.state.fileName}</Typography>
              </Box>

            </Box>
            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '-135px' }}>
              <Box data-test-id='selectTool' onClick={() => this.selectTool('arrow')} style={boxStyle('arrow')}>
                <img src={cursorIcon} alt='arrow' />
              </Box>
              <Box data-test-id='selectTool' onClick={() => this.selectTool('hand')} style={boxStyle('hand')}>
                <img src={handIcon} alt='arrow' />
              </Box>
              <Box data-test-id='selectTool' onClick={() => this.selectTool('line')} style={boxStyle('line')}>
                <img src={lineIcon} alt='arrow' />
              </Box>
              <Box data-test-id='selectTool' onClick={() => this.selectTool('rectangle')} style={boxStyle('rectangle')}>
                <img src={rectangleIcon} alt='arrow' />
              </Box>
              {this.state.tool =='arrow' && <Box style={{ marginLeft: '-20px' }}>
                <Typography style={{
                  color: '#0F172A',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}>Select a tool to get started</Typography>
              </Box>
              }
              {this.state.tool && <Box style={{ marginLeft: '-20px' }}>
                <Typography style={{
                  color: '#0F172A',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}></Typography>
              </Box>
              }

            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <CloseButton data-test-id='handelClose' onClick={this.handelClose}>
                Close File
              </CloseButton>

            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12} sm={8} md={9} lg={10}>
              <Box style={{ background: '#F0F5F7', padding: '24px 32px 82px 32px' }}>

                <Box sx={{ display: 'flex', width: '100%', bgcolor: '#FFFFFF', justifyContent: 'center', maxWidth: '1440px', overflow: 'hidden' }}>
                  <CustomScroll>
                    <div className="pdf-viewer" style={{cursor:this.state.tool=='hand'?'pointer':''}}>
                      <div>
                        <div className="canvas-container" data-test-id="mouseEvent"
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                          onMouseUp={this.handleMouseUp}
                          onMouseLeave={this.handleMouseUp}
                        >
                          <canvas ref={this.canvasRef}></canvas>
                        </div>
                      </div>

                    </div>

                    {this.state.isDeletePopupVisible && (
                      <div
                        style={{
                          position: "absolute",
                          left: `${this.state.deleteButtonPosition.x }px`,
                          top: `${this.state.deleteButtonPosition.y}px`,
                          background: "#fff",
                          color: "#DC2626",
                          padding: "10px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          border:'1px solid #CBD5E1',
                          fontSize:'14px',
                          fontWeight:400,
                          fontFamily:'Poppins'
                        }}
                        onClick={this.handleDeleteShape}
                      >
                        <span><DeleteOutline/></span>
                        <span>Delete</span>
                  
                      </div>
                    )}
                  </CustomScroll>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="pdf-controls">

                    <div className="toolbar">
                      <div className="activePage"> {currentPage}</div>
                      <div style={{marginTop:'3px'}}> / {totalPages}</div>
                    </div>
                    <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', marginLeft: '16px', marginRight: '16px' }} />

                    <button data-test-id="handleZoomOut" className="buttons" style={{ marginRight: '5px' }} onClick={this.handleZoomOut}>-</button>
                    <p className="activePage">{(zoom * 100).toFixed(0)}%</p>
                    <button data-test-id="handleZoomIn" className="buttons" onClick={this.handleZoomIn}>+</button>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Box style={{ background: '#fff', padding: '8px 16px' }}>
                <Typography style={{ color: '#1E293B', fontSize: '14px', fontWeight: 600, marginTop: '8px' }}>
                  Scaling Panel
                </Typography>
                <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
                <Box style={{ display: 'flex', gap: '4px', marginBottom: '16px' }}>
                  <Box>
                    <Typography className="toolTypo">
                      Tool: <strong></strong>
                    </Typography>
                  </Box>
                  {this.state.tool == 'line' &&
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <img src={lineMiniIcon} alt="img" />
                      </Box>
                      <Typography className="lineTypo">
                        Line
                      </Typography></>
                  }
                  {this.state.tool == 'rectangle' &&
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <img src={rectangleIcon} alt="img" />
                      </Box>
                      <Typography className="lineTypo">
                        Rectangle
                      </Typography>
                    </>}
                  {this.state.tool == 'arrow' &&
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <img src={cursorIcon} alt="img" />
                      </Box>
                      <Typography className="lineTypo">
                        Move
                      </Typography></>
                  }
                  {this.state.tool == 'hand' &&
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <img src={handIcon} alt="img" />
                      </Box>
                      <Typography className="lineTypo">
                        Move
                      </Typography></>
                  }

                </Box>
                <Typography style={{ color: '#1E293B', fontSize: '12px', fontWeight: 600 }}>Select Points on the Canvas</Typography>
                <Typography style={{ color: '#475569', fontSize: '12px', fontWeight: 400, marginTop: '4px', marginBottom: '16px' }}>
                  Click the first point on the PDF, then the second point to draw.</Typography>
                <Typography className='label'>Set Scale</Typography>
                <CustomInputLabel1>Pixels:</CustomInputLabel1>
                <CustomTextField
                  autoComplete='off'
                  fullWidth
                  variant="outlined"
                  name="Pixels"
                  data-test-id="pixels"
                  value={pixels}
                  onChange={(e) => this.handleInputChangePixels(e.target.value)}
                  placeholder="Pixels"
                  inputProps={{
                    style: {
                      fontFamily: "Poppins",
                    },
                  }}
                />
                <CustomInputLabel1>Real-World Distance:</CustomInputLabel1>
                <CustomTextField
                  autoComplete='off'
                  fullWidth
                  variant="outlined"
                  name="realWorldDistance"
                  data-test-id="realWorldDistance"
                  value={realWorldDistance}
                  onChange={(e) => this.handleInputChangeDis(e.target.value)}
                  placeholder="Real-World Distance"
                  inputProps={{
                    style: {
                      fontFamily: "Poppins",
                    },
                  }}
                />

                <CustomInputLabel1>Unit:</CustomInputLabel1>
                <CustomTextField
                  autoComplete='off'
                  fullWidth
                  variant="outlined"
                  name="unit"
                  data-test-id="unit"
                  value={unit}
                  onChange={this.handleInputChangeUnit}
                  placeholder="Unit"
                  inputProps={{
                    style: {
                      fontFamily: "Poppins",
                    },
                  }}

                />

                <>
                  <Box sx={{ marginTop: '8px' }}>
                    <CustomInputLabel2>Endpoint 1</CustomInputLabel2>
                    <Box className='inputGroup'>
                      <CustomTextField2
                        autoComplete='off'
                        fullWidth
                        variant="outlined"
                        name="endpoint1"
                        data-test-id="endpoint1"
                        value={endpoint1.x == 0 ? 0 : parseFloat(this.state.endpoint1.x as any).toFixed(2)}
                        placeholder="X"
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography className="startAdornmentTypo">X</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextField2
                        autoComplete='off'
                        fullWidth
                        variant="outlined"
                        name="endpoint1"
                        data-test-id="endpoint1"
                        value={endpoint1.y == 0 ? 0 : parseFloat(this.state.endpoint1.y as any).toFixed(2)}
                        placeholder="Y"
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography className="startAdornmentTypo">Y</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <CustomInputLabel2>Endpoint 2</CustomInputLabel2>
                    <Box className='inputGroup'>
                      <CustomTextField2
                        autoComplete='off'
                        fullWidth
                        variant="outlined"
                        name="endpoint2"
                        data-test-id="endpoint2"
                        value={endpoint2.x == 0 ? 0 : parseFloat(this.state.endpoint2.x as any).toFixed(2)}
                        placeholder="X"
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography className="startAdornmentTypo">X</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextField2
                        autoComplete='off'
                        fullWidth
                        variant="outlined"
                        name="endpoint2"
                        data-test-id="endpoint2"
                        value={endpoint2.y == 0 ? 0 : parseFloat(this.state.endpoint2.y as any).toFixed(2)}
                        placeholder="Y"
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography className="startAdornmentTypo">Y</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>


                  <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
                  {this.state.measuredLengthline  &&
                    <Typography className='lengthAndAreaTypo'>
                      Length: <span className='lengthAndAreaDataTypo'>{this.state.measuredLengthline}</span>
                    </Typography>
                  }
                  {this.state.measuredHeight  &&
                    <Typography className='lengthAndAreaTypo'>
                      Area: <span className='lengthAndAreaDataTypo'>{this.calculateArea()}</span>
                    </Typography>
                  }
                </>



              </Box>
            </Grid>
          </Grid>

        </MainWrapper>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomScroll= styled('div')({
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
})
const MainWrapper = styled(Box)(({ theme }) => ({
  '& .pdf-viewer': {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    height: '670px',
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'auto',
    overflowY:"hidden"
  },
  '& .pdf-controls': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    background: '#F8FAFC',
    boxShadow: '0px 8px 32px 0px #0000000F',
    padding: '0px 20px',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    height: '45px'
  },

  '& .canvas-container:': {
    height: '670px',
    overflow: 'auto',
    width: '100%',
    maxWidth: '1163px'
  },
  '& .toolBar': {
    color: '#0F172A',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily:'Poppins'
  },
  '& .activePage': {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    padding: '3px'
  },
  '& .buttons': {
    border: 'none',
    background: 'none',
    marginLeft: '4px',
    cursor: 'pointer'
  },
  "& .root": {
    display: 'flex',
    height: '100vh',
  },
  "& .sidebar": {
    width: 300,
    borderLeft: '1px solid #ccc',
    padding: theme.spacing(2),
    backgroundColor: '#f4f4f4',
  },
  "& .toolbar": {
    display: 'flex',
  },
  "& .canvas": {
    flex: 1,
    position: 'relative',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .pdfCanvas": {
    width: '90%',
    height: '90%',
    border: '1px solid #ccc',
  },
  '& .container': {
    backgroundColor: '#FFFFFF',
    width: 216,
    top: 124,
    left: 1224,
    padding: '8px 16px',
    gap: 16,
    border: '0px solid transparent',
    borderLeft: '1px solid #E2E8F0',
  },
  '& .sectionTitle': {
    color: '#1E293B',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    marginBottom: '8px',
  },
  '& .toolTypo': {
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .lineTypo': {
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  '& .label': {
    marginBottom: '8px',
    color: '#151818',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .inputGroup': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4px',
  },
  '& .input': {
    width: '45%',
  },
  '& .area': {
    fontWeight: 600,
    color: '#007BFF',
    textDecoration: 'underline',
  },
  '& .lengthAndAreaTypo': {
    color: '#1E293B',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .lengthAndAreaDataTypo': {
    color: '#237182',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .startAdornmentTypo': {
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
}));
const CloseButton = styled(Button)({
  backgroundColor: "#237182",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: '24px',
  height: "32px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "6px 10px 6px 10px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth: 0,
  '&:hover': {
    backgroundColor: '#237182',
  },
});
const CustomTextField = styled(TextField)({
  marginBottom: '8px',
  "& .MuiOutlinedInput-root": {
    height: '36px',
    borderRadius: '8px',
    borderColor: '#CBD5E1',
    backgroundColor: '#FFFFFF',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "0.5px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "0.5px solid #CBD5E1",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: '10px 8px',
    color: '#0F172A',
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '22px',
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    },
  },
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '8px',
    fontWeight: '400',
    lineHeight: '22',
    fontSize: '14px',
    color: '#475569',
  },
});
const CustomTextField2 = styled(TextField)({
  marginBottom: '8px',
  "& .MuiOutlinedInput-root": {
    height: '36px',
    borderRadius: '8px',
    borderColor: '#CBD5E1',
    backgroundColor: '#FFFFFF',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "0.5px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "0.5px solid #CBD5E1",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: '10px 0px',
    color: '#475569',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '400PX',
    lineHeight: '22px',
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    },
  },
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '8px',
    fontWeight: '400',
    lineHeight: '22',
    fontSize: '14px',
    color: '#0F172A',
  },
});

const CustomInputLabel1 = styled(InputLabel)({
  marginBottom: '8px',
  color: '#475569',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
});
const CustomInputLabel2 = styled(InputLabel)({
  marginBottom: '8px',
  color: '#151818',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
});
// Customizable Area End
